import React from "react";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {Shortcut} from "../commons/Shortcut";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {Placa} from "../Veiculo/PlacaMercosul";
import imgCheck from "../../media/icones/ok.png";
import imgTimes from "../../media/icones/eliminar.png";
import {ModalRealm} from "../commons/ModalRealm";
import {iconVehicle} from "../../utils/models/MVeiculo";

export class SelecionarVeiculo extends React.Component {

	state = {
		visible: true,
		selecionados: []
	};

	onClose = () => {
		this.setState({visible: false});
	};

	handleSelect = e => {
		let {selecionados} = this.state;
		if (selecionados.some(s => s.id === e.data.id)) {
			selecionados = selecionados.filter(s => s.id !== e.data.id);
		} else {
			selecionados.push(e.data);
		}
		this.setState({selecionados});
	};

	handleConfirmar = () => {
		if (!this.state.selecionados.some(v => v.tipo !== "CARRETA")) {
			ModalRealm.showInformacao("warn", ["Por favor, selecione ao menos um veículo automotor"]);
			return;
		}
		if (this.props.onModalClose) {
			this.props.onModalClose(this.state.selecionados);
		}
		this.onClose();
	};

	render() {
		return (
			<Dialog
				modal
				header="Selecionar Veículo"
				visible={this.state.visible}
				style={{width: "450px"}}
				onHide={this.onClose}
			>
				<DialogContent>
					<DataTable
						style={{cursor: "pointer"}}
						onRowClick={this.handleSelect}
						value={this.props.veiculos}
						selection={this.state.selecionados}
						rowClassName={v => (
							this.state.selecionados.some(s => s.id === v.id) ? {"bg-blue-400": true, "text-white": true} : {}
						)}
					>
						<Column
							style={{width: "4em", textAlign: "center"}}
							body={v => (
								<img
									alt=""
									style={{verticalAlign: "middle"}}
									src={this.state.selecionados.some(s => s.id === v.id) ? imgCheck : imgTimes}
								/>
							)}
						/>
						<Column
							style={{width: "7.5em", textAlign: "center"}}
							header="Placa"
							field="placa"
							body={v => <Placa veiculo={v}/>}
						/>
						<Column style={{width: "3em", textAlign: "center"}} header="Tipo" field="tipo" body={v => iconVehicle(v)}/>
						<Column header="Modelo" field="modelo"/>
						<Column header="Fabricante" body={v => v.fabricante?.nome}/>
					</DataTable>
				</DialogContent>
				<DialogFooter>
					<Action
						disabled={!this.state.selecionados.length}
						mode="post"
						label="OK"
						icon="fa-check"
						onClick={this.handleConfirmar}
					/>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}
