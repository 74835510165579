import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarEtiqueta} from "../components/Etiqueta/EditarEtiqueta";
import {MEtiqueta} from "../utils/models/MEtiqueta";
import {UserData} from "../utils/UserData";
import {MultiSelect} from "./MultiSelect";
import {httpGet} from "../utils/Request";

export class MultiSelectEtiqueta extends React.Component {

	state = {
		etiquetas: [],
		modelo: MEtiqueta.Modelo()
	};

	security = UserData.security("ETQ");

	service = {
		buscar: async e => await httpGet(`/etiquetas/${e.id}`),
		listar: async query => await httpGet(`/etiquetas/ac?${query}`)
	};

	onEdit = (etiqueta) => {
		if (this.security.edit) {
			console.log(this.service);
			this.service.buscar(etiqueta).then((entidade) => {
				ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={entidade} onModalClose={(etiqueta) => {
					let etiquetas = this.props.value;
					let index = etiquetas.map(e => e.id).indexOf(etiqueta.id);
					etiquetas[index] = etiqueta;
					this.props.onSelect({name: this.props.name, value: etiquetas});
				}} />);
			});
		}
	}

	onSelect = (event) => {
		let etiquetas = this.props.value || [];
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={{...MEtiqueta.Modelo(), descricao: event.value.descricao.replace(`Criar etiqueta `, "")}} onModalClose={(etiqueta) => {
				etiquetas.push(etiqueta);
				this.props.onSelect({name: this.props.name, value: etiquetas});
			}} />);
		} else {
			if (!etiquetas.map(e => e.id).includes(event.value.id)) {
				etiquetas.push(event.value);
			}
			this.props.onSelect({name: this.props.name, value: etiquetas});
		}
	}

	onChange = (event) => {
		if (this.props.onSelect) {
			this.props.onSelect(event);
		}
	}

	render() {
		return <MultiSelect label={this.props.label || "Etiquetas"} onChange={this.onChange} insertable={this.security.create && this.props.insertable} entidade="etiqueta" service={this.service} modelo={this.state.modelo} onEdit={this.onEdit} field="descricao" query="status:ATIVADO;descricao" {...this.props} onSelect={this.onSelect}/>;
	}

}
