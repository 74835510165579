import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import {GUI} from "../../utils/Constants";
import {MEtiqueta} from "../../utils/models/MEtiqueta";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {Combobox} from "../commons/Combobox";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {EditarEtiqueta} from "./EditarEtiqueta";
import {EtiquetaChip} from "./EtiquetaChip";
import {httpGet} from "../../utils/Request";
import {CheckButton} from "../commons/CheckButton";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconeEliminar from "../../media/icones/eliminar.png";

export class Etiqueta extends React.Component {

	state = {
		descricao: "",
		flags: null,
		firstResult: 0
	};

	etiquetaService = new EtiquetaService();
	security = UserData.security("ETQ", true);

	editarEtiqueta = (rowData) => {
		if (rowData && rowData.id) {
			this.etiquetaService.buscar(rowData).then((etiqueta) => {
				ModalRealm.showDialog(<EditarEtiqueta etiqueta={etiqueta} onModalClose={this.listarEtiquetas} />);
			});
		} else {
			ModalRealm.showDialog(<EditarEtiqueta etiqueta={null} onModalClose={this.listarEtiquetas} />);
		}
	}

	restaurarEtiqueta = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta etiqueta?", () => {
			if (rowData != null && rowData.id != null) {
				this.etiquetaService.buscar(rowData).then((etiqueta) => {
					etiqueta.status = "ATIVADO";
					this.etiquetaService.salvar(etiqueta).then(() => {
						this.listarEtiquetas();
					});
				});
			}
		});
	}

	eliminarEtiqueta = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta etiqueta?", () => this.etiquetaService.eliminar(rowData).then((result) => {
			if (result) this.listarEtiquetas(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta etiqueta: há outros registros dependentes dela no sistema."]);
		}));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column style={{width: "*"}} field="descricao" sortable header="Descrição" body={(etiqueta) => <EtiquetaChip etiqueta={etiqueta} />} />,
		<Column style={{width: "30em"}} header="Opções" body={(rowData) => rowData.flags.map((flag) => <div style={{color: "#000000", backgroundColor: "#EEEEEE"}} key={flag} className="ui-tzm-minchip">{MEtiqueta.Flags.filter(f => f.value === flag).map(f => f.label).join(",")}</div>)} />,
		<Column style={{width: "7em"}} header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Etiqueta" onClick={(event) => this.editarEtiqueta(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" && !rowData.flags?.includes("UNIDADE") ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Etiqueta" onClick={(event) => this.excluirEtiqueta(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={(event) => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Etiqueta" onClick={(event) => this.restaurarEtiqueta(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Etiqueta" onClick={(event) => this.eliminarEtiqueta(rowData)} /> : null}
				</div>
			);
		}} />
	];

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/etiquetas", entidade);
	}

	listarEtiquetas = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			httpGet(`/etiquetas/alt?${this.state.excluidos ? "" : "status=ATIVADO"}&descricao=${this.state.descricao}${this.state.flags && this.state.flags.length ? `&flags=${this.state.flags}` : ""}&page=0&size=200&sort=descricao`).then((etiquetas) => this.setState({
				etiquetas,
				firstResult: 0
			}));
		}, 50);
	}

	queryTimeout = null;

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	excluirEtiqueta = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta etiqueta?", () => this.etiquetaService.excluir(rowData).then(this.listarEtiquetas));
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Etiquetas">
					<PanelContent>
						<TextField grid={9} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
						<Combobox options={[{label: "Todas", value: null}, ...MEtiqueta.Flags]} grid={3} label="Opções" name="flags" value={this.state.flags} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarEtiquetas} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.etiquetas} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Etiqueta" icon="fa-plus" onClick={this.editarEtiqueta} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarEtiquetas}
					onCtrlN={() => window.location.pathname === "/etiquetas" && this.security.create && this.editarEtiqueta()}
					onCtrlE={() => window.location.pathname === "/etiquetas" && this.state.etiquetas.length === 1 && this.security.edit && this.editarEtiqueta(this.state.etiquetas[0])}
				/>
				<ModalRealm/>
			</div>
		);
	}

}
