import React from "react";

export class MLogAcesso {

	static Gatilhos = [
		{label: "Liberação", value: "LIBERACAO"},
		{label: "Bloqueio Ativo", value: "BLOQUEIO"},
		{label: "Bloqueio de Antidupla", value: "ANTIDUPLA"},
		{label: "Bloqueio por Credencial", value: "CREDENCIAL"},
		{label: "Bloqueio por Equipamento", value: "EQUIPAMENTO"},
		{label: "Bloqueio por Etiqueta", value: "ETIQUETA"},
		{label: "Bloqueio por Leitor", value: "LEITOR"},
		{label: "Bloqueio por Dispositivo", value: "DISPOSITIVO"},
		{label: "Bloqueio por Pessoa", value: "PESSOA"},
		{label: "Bloqueio por Horário", value: "HORARIO"},
		{label: "Bloqueio por Sentido Incorreto", value: "REPASSAGEM"},
		{label: "Bloqueio por Vaga Ocupada", value: "GARAGEM"},
		{label: "Bloqueio por Coletor", value: "URNA"},
		{label: "Bloqueio por Temperatura", value: "TEMPERATURA"},
		{label: "Bloqueio por Lotação", value: "LOTACAO"},
		{label: "Bloqueio por Ausência de EPIs", value: "PESSOA_SEM_EPI"}
	];

	static Respostas = [
		{label: "Nenhuma", value: "SEM_RESPOSTA"},
		{label: "Aceito", value: "ACEITO"},
		{label: "Bloqueio", value: "BLOQUEIO"},
		{label: "Liberação", value: "LIBERACAO"},
		{label: "Antidupla", value: "ANTIDUPLA"},
		{label: "Credencial", value: "CREDENCIAL"},
		{label: "Etiqueta", value: "ETIQUETA"},
		{label: "Equipamento", value: "EQUIPAMENTO"},
		{label: "Leitor", value: "LEITOR"},
		{label: "Dispositivo", value: "DISPOSITIVO"},
		{label: "Offline", value: "OFFLINE"},
		{label: "Horário", value: "HORARIO"},
		{label: "Repassagem", value: "REPASSAGEM"},
		{label: "Garagem", value: "GARAGEM"},
		{label: "Bloqueio por urna", value: "URNA"},
		{label: "Temperatura Irregular", value: "TEMPERATURA"},
		{label: "Lugar Lotado", value: "LOTACAO"},
		{label: "Pessoa sem EPIs", value: "PESSOA_SEM_EPI"}
	];

	static Atividades = [
		{label: "Requisição", value: "REQUISICAO"},
		{label: "Passagem", value: "PASSAGEM"},
		{label: "Alerta", value: "ALERTA"},
		{label: "Desconexão", value: "DESCONEXAO"},
		{label: "Arrombamento", value: "ARROMBAMENTO"},
		{label: "Presença Irregular", value: "PRESENCA"},
		{label: "Ajuste de Posição", value: "AJUSTE"}
	];

	static Eventos = [
		{label: "Emergência", value: "EMERGENCIA"},
		{label: "Tentativa de Carona", value: "ANTIDUPLA"},
		{label: "Porta Aberta", value: "PORTA_ABERTA"},
		{label: "Uso de Master", value: "MASTER"},
		{label: "Atendimento", value: "ATENDIMENTO"},
		{label: "Arrombamento", value: "ARROMBAMENTO"},
		{label: "Presença Irregular", value: "PRESENCA"},
		{label: "Credencial Desativada", value: "CREDENCIAL"},
		{label: "Posicão Incorreta", value: "POSICAO"},
		{label: "Modo de Treinamento", value: "TREINAMENTO"},
		{label: "Temperatura Irregular", value: "TEMPERATURA"},
		{label: "Acesso Bloqueado", value: "BLOQUEADO"},
		{label: "Acesso Fora de Horário", value: "FORA_DE_HORARIO"}
	];

	static MapRespostas = {
		"ACEITO": 		  "Registro Aceito",
		"BLOQUEIO": 	  "Bloqueio Ativo",
		"LIBERACAO": 	  "Liberação de Acesso",
		"PESSOA": 		  "Credencial fantasma",
		"CREDENCIAL": 	  "Credencial inválida",
		"URNA": 		  "Retenção por urna",
		"REPASSAGEM": 	  "Direção incorreta",
		"HORARIO":		  "Horário inválido",
		"EQUIPAMENTO":	  "Equipamento inválido",
		"LEITOR": 		  "Leitor inválido",
		"DISPOSITIVO":	  "Dispositivo inválido",
		"GARAGEM": 		  "Vaga ocupada",
		"OFFLINE": 		  "Registro offline",
		"ETIQUETA": 	  "Etiqueta de Acesso",
		"ANTIDUPLA":	  "Tentativa de Carona",
		"TEMPERATURA":	  "Temperatura Irregular",
		"LOTACAO":		  "Lugar Lotado",
		"PESSOA_SEM_EPI": "Pessoa Sem EPIs"
	};

	static MapAtuadores = {
		"RELAY1": 		"Relé 1",
		"RELAY2": 		"Relé 2",
		"SOLENOID1": 	"Lado 1",
		"SOLENOID2": 	"Lado 2",
		"SOLENOID3": 	"Auxiliar",
		"NENHUM": 		null
	};

	static MapAtividades = {
		"PASSAGEM": 	"Passagem",
		"REQUISICAO": 	"Requisição",
		"ALERTA": 		"Alerta",
		"CONEXAO": 		"Conexão",
		"DESCONEXAO": 	"Desconexão"
	};

	static RespostasOrdenadas = {
		"LIBERACAO": "Acesso permitido",
		"BLOQUEIO": "Acesso bloqueado porque a pessoa tem um bloqueio de segurança",
		"LOTACAO": "Acesso bloqueado porque o lugar está lotado",
		"TEMPERATURA": "Acesso bloqueado porque a pessoa está com a temperatura anormal",
		"SALDO": "Acesso bloqueado por falta de saldo na conta",
		"SALDO_LIMITE": "Acesso bloqueado por limite diário excedido na conta",
		"URNA": "Acesso bloqueado por tentativa de saída com credencial temporária",
		"GARAGEM": "Acesso bloqueado por falta de vaga na garagem",
		"ANTIDUPLA": "Acesso bloqueado por tentativa de carona",
		"HORARIO": "Acesso bloqueado por tentativa de acesso fora do horário da agenda",
		"ETIQUETA": "Acesso bloqueado por etiqueta de acesso ausente na agenda",
		"DOCUMENTO": "Acesso bloqueado porque a pessoa possui documentos vencidos ou não possui os documentos obrigatórios",
		"DESTINO": "Acesso bloqueado porque a pessoa não tem permissão de acesso ao lugar",
		"AGENDA": "Acesso bloqueado porque a pessoa não possui agenda disponível",
		"CREDENCIAL": "Acesso bloqueado porque a credencial não foi identificada",
		"PESSOA": "Acesso bloqueado porque a pessoa não foi identificada",
		"REPASSAGEM": "Acesso bloqueado por tentativa de dupla passagem no mesmo sentido",
		"EQUIPAMENTO": "Acesso bloqueado porque o equipamento não foi identificado",
		"LEITOR": "Acesso bloqueado porque o leitor do equipamento não foi identificado",
		"DISPOSITIVO": "Acesso bloqueado porque não foi possível acionar o dispositivo correto",
		"OFFLINE": "Registro de acesso recuperado de operação autônoma (equipamento offline)",
		"SEQUENCIA": "Sequência de autenticação",
		"SEQUENCIA_INCORRETA": "Acesso bloqueado por sequência de autenticação incorreta",
		"PESSOA_SEM_EPI": "Acesso bloqueado porque a pessoa não está usando os EPIs obrigatórios"
	};

	static EventosOrdenados = {
		"EMERGENCIA": "Acionou o modo de emergência",
		"ANTIDUPLA" : "Liberou acesso para uma carona",
		"PORTA_ABERTA" : "Deixou a porta aberta por tempo demais",
		"MASTER" : "Foi utilizada uma credencial mestre",
		"ATENDIMENTO" : "Deixou de atender ao mecanismo de vigilância",
		"ARROMBAMENTO" : "Houve uma abertura de porta sem autorização de acesso",
		"PRESENCA" : "Foi identificada presença fora do horário de sua agenda",
		"CREDENCIAL" : "Foi utilizada uma credencial que estava desativada",
		"POSICAO" : "O sistema corrigiu o posicionamento dessa pessoa",
		"TREINAMENTO" : "O modo de emergência/treinamento foi ativado",
		"TEMPERATURA" : "A pessoa está com temperatura anormal",
		"BLOQUEADO" : "Acesso bloqueado porque a pessoa possui um bloqueio de segurança",
		"BLOQUEADO_INTERVALO": "Tentativa de acesso de pessoa em intervalo",
		"BLOQUEADO_INTERJORNADA": "Tentativa de acesso de pessoa bloqueada por interjornada",
		"AUTORIZACAO": "Autorização de acesso manual gerada",
		"FORA_DE_HORARIO": "Acesso permitido fora de horário",
		"ALARME_TEMPERATURA": "Alarme de temperatura",
		"ALARME_FUMACA": "Alarme de fumaça",
		"PESSOA_SEM_EPI": "Pessoa sem EPIs"
	};

}

export const RespostasOrdinal = [
	"SEM_RESPOSTA",
	"ACEITO",
	"LIBERACAO",
	"ANTIDUPLA",
	"CREDENCIAL",
	"EQUIPAMENTO",
	"LEITOR",
	"DISPOSITIVO",
	"OFFLINE",
	"PESSOA",
	"HORARIO",
	"DESTINO",
	"AGENDA",
	"REPASSAGEM",
	"GARAGEM",
	"URNA",
	"BLOQUEIO",
	"ETIQUETA",
	"DOCUMENTO",
	"LOTACAO",
	"TEMPERATURA",
	"SALDO",
	"SALDO_LIMITE",
	"SEQUENCIA",
	"SEQUENCIA_INCORRETA",
	"PESSOA_SEM_EPI"
];

export const AtuadoresOrdinal = [
	"NENHUM",
	"SOLENOID1",
	"SOLENOID2",
	"SOLENOID3",
	"OUTPUT1",
	"OUTPUT2",
	"OUTPUT3",
	"OUTPUT4",
	"OUTPUT5",
	"OUTPUT6",
	"OUTPUT7",
	"OUTPUT8",
	"RELAY1",
	"RELAY2",
	"ORIGEM"
];

export const MapFlags = {
	"OFFLINE": <div key="OFFLINE" style={{background: "rgba(0, 0, 0, .5)"}} className="ui-tzm-minchip">Offline</div>,
	"PENDENTE": <div key="PENDENTE" style={{background: "rgba(0, 0, 0, .5)"}} className="ui-tzm-minchip">Pendente</div>,
	"TREINAMENTO": <div key="TREINAMENTO" style={{background: "repeating-linear-gradient(45deg, #ef6c00, #ef6c00 10px, #607d8b 10px, #607d8b 20px)"}} className="ui-tzm-minchip">Modo de Emergência</div>,
	"TARIFADO": <div key="TARIFADO" style={{background: "rgba(0, 0, 0, .5)"}} className="ui-tzm-minchip">Tarifado</div>,
	"COLETOR": <div key="COLETOR" style={{background: "rgba(0, 0, 0, .5)"}} className="ui-tzm-minchip">Coletor</div>,
	"AUTOMATICO": <div key="AUTOMATICO" style={{background: "rgba(0, 0, 0, .5)"}} className="ui-tzm-minchip">Automático</div>
};

export const diaSemana = {
	SUNDAY: "domingo",
	MONDAY: "segunda-feira",
	TUESDAY: "terça-feira",
	WEDNESDAY: "quarta-feira",
	THURSDAY: "quinta-feira",
	FRIDAY: "sexta-feira",
	SATURDAY: "sábado"
};

export const optionsDiasSemana = [
	{label: "Dom", value: "SUNDAY"},
	{label: "Seg", value: "MONDAY"},
	{label: "Ter", value: "TUESDAY"},
	{label: "Qua", value: "WEDNESDAY"},
	{label: "Qui", value: "THURSDAY"},
	{label: "Sex", value: "FRIDAY"},
	{label: "Sáb", value: "SATURDAY"}
];

export const optionsDiasSemanaExt = [
	{label: "Domingo", value: "SUNDAY"},
	{label: "Segunda", value: "MONDAY"},
	{label: "Terça", value: "TUESDAY"},
	{label: "Quarta", value: "WEDNESDAY"},
	{label: "Quinta", value: "THURSDAY"},
	{label: "Sexta", value: "FRIDAY"},
	{label: "Sábado", value: "SATURDAY"}
];

export const optionsModoFiltroDataHora = [
	{label: "por Período Completo", value: "PERIODO"},
	{label: "por Horários no Período", value: "HORARIO"}
];

export const optionsModoEtiqueta = [
	{label: "no Registro de Acesso", value: "log_acesso"},
	{label: "no Equipamento", value: "equipamento"},
	{label: "na Pessoa", value: "pessoa"}
];

export const optionsAcessosAtivos = [
	{label: "Todos", value: null},
	{label: "Com Acesso Ativo", value: true},
	{label: "Sem Acesso Ativo", value: false}
];
