import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {DataView} from "primereact/components/dataview/DataView";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeRemover from "../../media/icones/remover.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectPessoaJuridica} from "../../select/SelectPessoaJuridica";
import {AppletUtils} from "../../utils/AppletUtils";
import {GUI} from "../../utils/Constants";
import "../../utils/Format";
import {toDate, toTime} from "../../utils/Format";
import {MAviso} from "../../utils/models/MAviso";
import {MCredencial} from "../../utils/models/MCredencial";
import {isDocumentoEditavel, MDocumento} from "../../utils/models/MDocumento";
import {MEndereco} from "../../utils/models/MEndereco";
import {MEtiqueta} from "../../utils/models/MEtiqueta";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import {httpDelete, httpGet, httpPost} from "../../utils/Request";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {EditarAviso} from "../Aviso/EditarAviso";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {PanelContent} from "../commons/PanelContent";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {CriarSenha} from "../Credencial/CriarSenha";
import {EditarCredencial} from "../Credencial/EditarCredencial";
import {EditarEtiqueta} from "../Etiqueta/EditarEtiqueta";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {AvisoService} from "../servicos/AvisoService";
import {BloqueioService} from "../servicos/BloqueioService";
import {CredencialService} from "../servicos/CredencialService";
import {EnderecoService} from "../servicos/EnderecoService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {LocalidadeService} from "../servicos/LocalidadeService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {PessoaJuridicaService} from "../servicos/PessoaJuridicaService";
import {ConfirmarRevogacao} from "./ConfirmarRevogacao";
import {EditarDocumento} from "../Documento/EditarDocumento";
import {SuggestionBox} from "../commons/SuggestionBox";
import {byRegistroDesc, isFinalidadeIdentificacao, toSrc} from "../../utils/StringUtils";
import {CheckSquare} from "../commons/CheckSquare";
import iconeAcesso from "../../media/icones/acesso.png";
import iconePonto from "../../media/icones/ponto.png";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {MEquipamento} from "../../utils/models/MEquipamento";
import {Label} from "../commons/Label";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {WizardAgenda} from "../Agenda/WizardAgenda";
import {Foto as NewFoto} from "../Foto";
import {ManutencaoService} from "../servicos/ManutencaoService";
import {SelectGrupoTrabalho} from "../../select/SelectGrupoTrabalho";
import {FacialDialog} from "../commons/FacialDialog";
import {EditarPessoaJuridica} from "../PessoaJuridica/EditarPessoaJuridica";
import {byTipoOrdenacaoThenDescricao} from "../../utils/SortUtils";
import {SelectEtiqueta} from "../../select/SelectEtiqueta";
import iconeLock from "../../media/icones/pendente.png";

export const nomesDocumentos = {
	"cpf": "CPF",
	"rg": "RG",
	"nis": "NIS",
	"passaporte": "documento"
};

export class EditarPessoaFisica extends React.Component {

	constructor(props) {
		super(props);
		let pessoa = this.prepararPessoa(props.pessoa);
		let endereco = this.prepararEndereco(pessoa);
		this.state = {
			isSaving: false,
			pessoa,
			endereco,
			visible: true,
			activeIndex: 0,
			avisos: [],
			credenciais: [],
			scredenciais: [],
			params: Object.assign({}, props.params),
			_mod: false,
			_modFotos: false,
			lockSave: false,
			geridos: []
		};
		this.pessoaFisicaService = new PessoaFisicaService();
		this.pessoaJuridicaService = new PessoaJuridicaService();
		this.enderecoService = new EnderecoService();
		this.localidadeService = new LocalidadeService();
		this.etiquetaService = new EtiquetaService();
		this.bloqueioService = new BloqueioService();
		this.credencialService = new CredencialService();
		this.equipamentoService = new EquipamentoService();
		this.avisoService = new AvisoService();
		this.manutencaoService = new ManutencaoService();
		this.security = UserData.security("PES", true);
		this.securityDocumento = UserData.security("DOC");
		this.securityAgenda = UserData.security("SCH");
		this.securityBloqueio = UserData.security("BLK");
		this.securityCredencial = UserData.security("CRD");
		this.securityEmpresa = UserData.security("EMP");
	}

	componentDidMount() {
		if (this.props.activeTab != null) this.tabChange({index: this.props.activeTab});
		this.listarAvisos();
	}

	listarAvisos = () => {
		if (this.state.pessoa.id) {
			this.avisoService.listar(`pessoa=id:${this.state.pessoa.id}&search=status:ATIVADO`, {page: 0, size: 50}).then((avisos) => this.setState({avisos}));
		}
	}

	editarAviso = (aviso) => {
		const pessoa = this.state.pessoa;
		if (aviso != null && aviso.id != null) {
			this.avisoService.buscar(aviso).then((aviso) => {
				ModalRealm.showDialog(<EditarAviso locked key={Math.random()} aviso={aviso} onSave={this.listarAvisos} />);
			});
		} else {
			if (pessoa.id) {
				this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
					ModalRealm.showDialog(<EditarAviso locked key={Math.random()} aviso={{...MAviso.Modelo(), pessoa}} onSave={(aviso) => {
						const avisos = this.state.avisos;
						avisos.push(aviso);
						this.setState({avisos});
					}} />);
				});
			} else {
				ModalRealm.showDialog(<EditarAviso locked key={Math.random()} aviso={{...MAviso.Modelo(), pessoa}} onSave={(aviso) => {
					const avisos = this.state.avisos;
					avisos.push(aviso);
					this.setState({avisos});
				}} />);
			}
		}
	}

	excluirAviso = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este aviso?", () => this.avisoService.excluir(rowData).then(() => this.listarAvisos()));
	}

	prepararPessoa(pessoa) {
		if (pessoa == null) {
			pessoa = MPessoaFisica.Modelo();
		}
		return MPessoaFisica.cleanupBeforeEdit(pessoa);
	}

	prepararEndereco(pessoa) {
		let endereco;
		if (pessoa.enderecos != null) {
			endereco = pessoa.enderecos.filter((endereco) => endereco.tipo === "PRINCIPAL")[0];
		}
		if (endereco == null) {
			endereco = MEndereco.Modelo();
			if (pessoa.enderecos == null) pessoa.enderecos = [endereco]; else pessoa.enderecos.push(endereco);
		}
		return endereco;
	}

	salvarPessoa = () => {
		this.setState({isSaving: true})
		if (this.security.edit) {
			let pessoa = MPessoaFisica.cleanupBeforeSave(Object.assign({}, this.state.pessoa));
			let messages = MPessoaFisica.validarParaSalvar(pessoa, this.props.obrigatorios);
			if (messages.length) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				this.processarSalvarPessoa(pessoa);
			}
		}
	}

	processarSalvarPessoa = async (pessoa) => {
		pessoa.ultimaAtualizacao = moment().format("YYYY-MM-DDTHH:mm:ss");
		if (this.state.geridos?.length) {
			const pendentes = this.state.geridos.filter(g => !g.gestor || g._salvar);
			for (const pendente of pendentes) {
				await httpPost(`/pessoas-fisicas`, pendente);
			}
		}
		if (pessoa.enderecos?.length) {
			for (let i = 0; i < pessoa.enderecos.length; ++i) {
				pessoa.enderecos[i].localidade = await this.salvarLocalidade(pessoa.enderecos[i].localidade);
			}
		}
		this.postPessoa(pessoa);
	}

	salvarLocalidade = async localidade => {
		return await httpGet(`/localidades?search=nome:${localidade.nome};uf:${localidade.uf}`).then(async localidades => {
			return localidades?.length ? localidades[0] : await httpPost("/localidades", localidade);
		});
	}

	fecharSemSalvar = () => {
		if (this.state._mod) {
			ModalRealm.showConfirmacao("Tem certeza de que deseja descartar as alterações e fechar?", () => {
				MPessoaFisica.cleanupBeforeSave(this.state.pessoa);
				this.onClose();
			});
		} else {
			MPessoaFisica.cleanupBeforeSave(this.state.pessoa);
			this.onClose();
		}
	}

	onClose = () => {
		this.setState({visible: false});
	}

	handleChange = (event) => {
		let pessoa = this.state.pessoa;
		pessoa[event.name] = event.value;
		this.setState({pessoa, _mod: true});
	}

	handleState = (event) => {
		this.setState({[event.name]: event.value, _mod: true});
	}

	handleChangeEndereco = (event) => {
		let endereco = this.state.endereco;
		endereco[event.target.name] = event.target.value;
		this.setState({endereco, _mod: true});
	}

	handleChangeEnderecoLocalidade = (event) => {
		let endereco = this.state.endereco;
		endereco.localidade[event.target.name] = event.target.value;
		this.setState({endereco, _mod: true});
	}

	handleChangeEnderecoLocalidadeSuggestion = (event) => {
		let endereco = this.state.endereco;
		endereco.localidade[event.name] = event.value;
		this.setState({endereco, _mod: true});
	}

	tirarFotografia = () => {
		const {pessoa} = this.state;
		let width = Math.round(document.body.clientWidth * .3);
		let height = Math.round(width * .75);
		const fotos = pessoa.fotos ? [...pessoa.fotos] : [];
		ModalRealm.showDialog(<NewFoto fotos={fotos} width={width} height={height} onModalClose={(fotos, _modFotos) => {
			pessoa.fotos = fotos;
			this.setState({pessoa, _modFotos});
		}}/>);
	}

	fotoPessoal = () => {
		if (this.state.pessoa.fotos) {
			return toSrc(this.state.pessoa.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc)[0]);
		}
		return null;
	}

	buscarCredenciais = () => {
		if (this.state.pessoa.id != null) {
			this.credencialService.listar(`pessoa=id:${this.state.pessoa.id}`, {size: 100, page: 0}).then((credenciais) => {
				const documentos = [];
				if (credenciais && credenciais.length) {
					if (!Validator.isEmpty(this.state.pessoa.cpf) && credenciais.some((c) => c.status === "ATIVADO" && c.codigo === Number(this.state.pessoa.cpf.replace(/[^0-9]/g, "")))) documentos.push("CPF");
					if (!Validator.isEmpty(this.state.pessoa.rg) && credenciais.some((c) => c.status === "ATIVADO" && c.codigo === Number(this.state.pessoa.rg))) documentos.push("RG");
				}
				this.setState({documentos, credenciais});
			});
		}
	}

	buscarEndereco = () => {
		this.enderecoService.buscarCEP(this.state.endereco.cep).then((result) => {
			if (result.status === 200) {
				result.json().then((json) => {
					let endereco = this.state.endereco;
					endereco.cep = json.cep;
					endereco.logradouro = json.logradouro;
					endereco.bairro = json.bairro;
					this.localidadeService.listar(`search=nome:${json.localidade};uf:${json.uf}`, {size: 1, page: 0}).then((localidades) => {
						if (localidades.length > 0) {
							endereco.localidade = localidades[0];
						} else {
							endereco.localidade = {nome: json.localidade, uf: json.uf};
						}
						this.setState({endereco});
					});
				});
			}
		});
	}

	changeTipoEndereco = (event) => {
		let endereco = this.state.pessoa.enderecos.filter((endereco) => endereco.tipo === event.value)[0];
		if (endereco == null) {
			endereco = MEndereco.Modelo();
			endereco.tipo = event.value;
			let pessoa = this.state.pessoa;
			pessoa.enderecos.push(endereco);
			this.setState({pessoa, endereco});
		} else {
			this.setState({endereco});
		}
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {size: 10, page: 0}).then((etiquetas) => {
			if (etiquetas.length === 0) {
				this.setState({etiquetas: [{...MEtiqueta.Modelo(), id: -1, descricao: `Criar etiqueta ${event.query}`}]});
			} else {
				this.setState({etiquetas});
			}
		});
	}

	listarCredenciais = (event) => {
		let parameters = {query: event.query, page: 0, size: 10};
		if (this.state.params) {
			parameters = Object.assign(parameters, this.state.params);
		}
		this.credencialService.listarAlt(parameters).then((scredenciais) => this.setState({scredenciais}));
	}

	tabChange = (event) => {
		switch (event.index) {
			case 3: // Credenciais
				if (!this.state.credenciais.length) this.buscarCredenciais();
				break;
			case 5: // Acessos
				if (!this.state.criterioAcessoAcesso) this.buscarCriterioAcesso();
				break;
			case 6: // Equipamentos
				if (!this.state.equipamentos) {
					const query = "search=status:ATIVADO;familia!NENHUMA&controlador=id!null&sort=descricao";
					this.equipamentoService.listar(query, {page: 0, size: 1000}).then(equipamentos => this.setState({equipamentos}));
				}
				break;
			case 7: // Ponto
				if (!this.state.geridos?.length && this.state.pessoa.id) {
					httpGet(`/pessoas-fisicas?search=status:ATIVADO&gestor=id:${this.state.pessoa.id}&page=0&size=1000`).then(geridos => this.setState({geridos}));
				}
				break;
			default:
				break;
		}
		this.setState({activeIndex: event.index});
	}

	buscarCriterioAcesso = () => {
		if (this.state.pessoa.id) {
			this.pessoaFisicaService.detalharAcesso(this.state.pessoa.id).then(criterioAcesso => this.setState({criterioAcesso}));
		}
	}

	removerCredencial = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que remover esta credencial desta pessoa?", () => {
			let credenciais = this.state.credenciais;
			let index = this.state.credenciais.map(c => c.id).indexOf(rowData.id);
			credenciais[index].pessoa = null;
			credenciais[index].alterado = true;
			this.setState({credenciais});
		});
	}

	handleCpfChange = (event) => {
		const {pessoa} = this.state;
		pessoa.cpf = event.value;
		this.setState({pessoa}, () => {
			if (Validator.isCPF(this.state.pessoa.cpf)) {
				this.pessoaFisicaService.listar(`search=id!${this.state.pessoa.id || "0"};cpf:${this.state.pessoa.cpf.replace(/[^0-9]/g, "")}`, {page: 0, size: 1}).then((result) => {
					this.setState({_warnCpf: result?.length > 0});
				});
			}
		});
	}

	excluirCredencial = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que excluir esta credencial?", () => {
			let credenciais = this.state.credenciais;
			let index = this.state.credenciais.map(c => c.id).indexOf(rowData.id);
			credenciais[index].status = "EXCLUIDO";
			credenciais[index].alterado = true;
			this.setState({credenciais});
		});
	}

	editarCredencial = (rowData) => {
		this.credencialService.buscar(rowData).then((credencial) => {
			ModalRealm.showDialog(<EditarCredencial credencial={credencial} onModalClose={() => {
				this.buscarCredenciais();
			}} />);
		});
	}

	selecionarEtiquetas = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={{...MEtiqueta.Modelo(), descricao: event.value.descricao.replace("Criar etiqueta ", "")}} onModalClose={(etiqueta) => {
				let pessoa = this.state.pessoa;
				pessoa.etiquetas.push(etiqueta);
				this.setState({pessoa, _mod: true});
			}} />);
		}
	}

	detalhesCredenciais = [
		<Column style={{width: "*"}} field="descricao" header="Descrição" />,
		<Column style={{width: "8em"}} field="forma" header="Forma" body={c => MCredencial.MapFormas[c.forma]} />,
		<Column style={{width: "12em"}} header="Código" body={(rowData) => {
			switch (rowData.forma) {
				case "SENHA":
					return rowData.codigo.toString().substring(0, 3) + "****";
				default:
					return rowData.codigo;
			}
		}} />,
		<Column style={{width: "6em"}} header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.securityCredencial.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Credencial" onClick={(event) => this.editarCredencial(rowData)} /> : null}
					{this.securityCredencial.edit && !MCredencial.FormasIntransferiveis.includes(rowData.forma) ? <img alt="" className="ui-action-icon" src={iconeRemover} title="Desvincular Credencial" onClick={() => this.removerCredencial(rowData)} /> : null}
					{this.securityCredencial.remove && rowData.forma === "SENHA" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Credencial" onClick={() => this.excluirCredencial(rowData)} /> : null}
				</div>
			);
		}} />
	];

	adicionarCredencial = (event) => {
		if (event.value != null) {
			event.value.pessoa = this.state.pessoa;
			event.value.alterado = true;
			let credenciais = this.state.credenciais;
			if (credenciais == null) credenciais = [];
			if (!credenciais.some(c => c.id === event.value.id)) {
				event.value.pessoa = this.state.pessoa;
				credenciais.push(event.value);
				this.setState({credenciais, credencial: ""});
			} else {
				this.setState({credencial: ""});
			}
		}
	}

	editarEtiqueta = (rowData) => {
		this.etiquetaService.buscar(rowData).then((etiqueta) => {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={etiqueta} onModalClose={(etiqueta) => {
				let pessoa = this.state.pessoa;
				let index = pessoa.etiquetas.map(e => e.id).indexOf(etiqueta.id);
				pessoa.etiquetas[index] = etiqueta;
				this.setState({pessoa, _mod: true});
			}} />);
		});
	}

	removerEtiqueta = (rowData) => {
		let pessoa = this.state.pessoa;
		pessoa.etiquetas = pessoa.etiquetas.filter(e => e.id !== rowData.id);
		this.setState({pessoa, _mod: true});
	}

	openGallery = () => {
		ModalRealm.showDialog(<Galeria key={Math.random()} fotos={this.state.pessoa.fotos} onRemoverFoto={this.removerFoto} />);
	}

	removerFoto = (foto) => {
		const pessoa = this.state.pessoa;
		pessoa.fotos = pessoa.fotos.filter(f => f.id !== foto.id);
		this.setState({pessoa, _mod: true});
	}

	postPessoa = (pessoa) => {
		this.setState({lockSave: true});
		this.pessoaFisicaService.salvar(pessoa).then(pessoa => {
			this.setState({pessoa, lockSave: false}, () => {
				if (this.state.credenciais) {
					this.state.credenciais.filter(c => c.alterado).forEach((credencial) => {
						if (credencial.pessoa) credencial.pessoa = pessoa;
						this.credencialService.salvar(credencial);
					});
				}
				this.processarDocumentos(pessoa);
				if (this.state.avisos) {
					this.state.avisos.filter(a => !a.id).forEach((aviso) => {
						this.avisoService.salvar({...aviso, pessoa});
					});
				}
				if (this.props.onModalClose) {
					this.props.onModalClose(pessoa);
				}
				if ((this.props.manutencao == null || this.props.manutencao === true) && this.state._modFotos && this.securityCredencial.create) {

					if (this.props.equipamentos) {						
						if (this.props.equipamentos?.length) {
							ModalRealm.showConfirmacao("As fotos da pessoa foram alteradas. Deseja atualizá-las nos equipamentos de biometria facial?", () => {
								ModalRealm.showDialog(<FacialDialog visitante={false} pessoas={[pessoa]} equipamentos={this.props.equipamentos} onModalClose={this.onClose}/>);
							}, () => {
								this.onClose();
							});
						} else {
							this.onClose();
						}
					} else {
						httpGet("/equipamentos?search=familia:CONTROLID_FACE,CONTROLID_BLOCK_NEXT,INTELBRAS_FRT,HIKVISION_FRT;status:ATIVADO&controlador=id!null").then(equipamentos => {
							if (equipamentos?.length) {
								ModalRealm.showConfirmacao("As fotos da pessoa foram alteradas. Deseja atualizá-las nos equipamentos de biometria facial?", () => {
									ModalRealm.showDialog(<FacialDialog visitante={false} pessoas={[pessoa]} equipamentos={equipamentos} onModalClose={this.onClose}/>);
								}, () => {
									this.onClose();
								});
							} else {
								this.onClose();
							}
						});

					}

				} else {
					this.onClose();
				}
			});
		}).catch(() => this.setState({lockSave: false}));
	}

	desbloquearPessoa = () => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja desbloquear o acesso desta pessoa?", () => {
			this.bloqueioService.listar(`pessoa=id:${this.state.pessoa.id}`, {page: 0, size: 100}).then((bloqueios) => this.excluirBloqueios(bloqueios, 0));
		});
	}

	excluirBloqueios = (bloqueios, index) => {
		if (bloqueios.length > index) {
			this.bloqueioService.excluir(bloqueios[index]).then(() => this.excluirBloqueios(bloqueios, ++index));
		} else {
			this.buscarCriterioAcesso();
		}
	}

	criarAgenda = () => {
		this.pessoaFisicaService.buscar(this.state.pessoa).then(pessoa => {
			ModalRealm.showDialog(<WizardAgenda pessoas={[pessoa]} onSave={this.buscarCriterioAcesso}/>);
		});
	}

	cadastrarDigital = () => {
		AppletUtils.downloadFingerprint(this.state.pessoa);
	}

	criarSenha = () => {
		this.pessoaFisicaService.buscar(this.state.pessoa).then(pessoa => {
			ModalRealm.showDialog(<CriarSenha key={Math.random()} pessoa={pessoa} onModalClose={() => {
				this.buscarCriterioAcesso();
			}} />);
		});
	}

	detailsAviso = [
		<Column style={{width: "4em"}} key="status" header="Status" field="status" body={MAviso.SwitchStatus} />,
		<Column style={{width: "8em"}} key="tipo" header="Tipo" field="tipo" body={(aviso) => MAviso.MapTipos[aviso.tipo]} />,
		<Column sortable style={{width: "12em"}} key="inicio" header="Início" field="inicio" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{rowData.inicio ? toDate(rowData.inicio) + " " + toTime(rowData.inicio) : "Indeterminado"}
				</div>
			);
		}} />,
		<Column sortable style={{width: "12em"}} key="fim" header="Fim" field="fim" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{rowData.fim ? toDate(rowData.fim) + " " + toTime(rowData.fim) : "Indeterminado"}
				</div>
			);
		}} />,
		<Column style={{width: "12em", textAlign: "center"}} key="etiqueta" header="Etiqueta" body={(rowData) => {
			if (rowData.etiqueta != null) {
				return <EtiquetaChip etiqueta={rowData.etiqueta} />;
			} else {
				return null;
			}
		}} />,
		<Column header="Texto" style={{width: "*"}} field="texto" />,
		<Column style={{width: "7em"}} key="acoes" header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Aviso" onClick={() => this.editarAviso(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Aviso" onClick={() => this.excluirAviso(rowData)} /> : null}
				</div>
			);
		}} />
	];

	footerAvisos = <Button className="ui-tzm-pagleft-icon" title="Criar Aviso" icon="fa-plus" onClick={() => this.editarAviso(null)} />;

	opcoesDocumento = () => {
		const opcoes = [];
		if (!Validator.isEmpty(this.state.pessoa.cpf)) opcoes.push({label: "CPF", value: "CPF"});
		if (!Validator.isEmpty(this.state.pessoa.rg)) opcoes.push({label: "RG", value: "RG"});
		return opcoes;
	}

	processarDocumentos = (pessoa) => {
		const habilitados = this.state.documentos;
		if (habilitados) {
			if (pessoa.cpf && pessoa.cpf.length) this.switchDocumento(habilitados, pessoa, "CPF", Number(pessoa.cpf.replace(/[^0-9]/g, "")));
			if (pessoa.rg && pessoa.rg.length) this.switchDocumento(habilitados, pessoa, "RG", Number(pessoa.rg));
		}
	}

	handleDocumentos = (event) => {
		this.setState({documentos: event.value});
	}

	switchDocumento = (habilitados, pessoa, documento, valor) => {
		const credenciais = this.state.credenciais || [];
		if (habilitados.includes(documento)) {
			if (!credenciais.some((c) => c.codigo === valor && c.flags.includes("DOCUMENTO"))) {
				this.credencialService.listarAlt({codigo: valor, flags: "DOCUMENTO", page: 0, size: 1, excluidos: true}).then((result) => {
					if (result && result.length) {
						result[0].status = "ATIVADO";
						this.credencialService.salvar(result[0]).then(this.listarCredenciais);
					} else {
						this.credencialService.salvar({...MCredencial.Modelo(), pessoa, forma: "SENHA", descricao: `Senha ${documento} ${pessoa.id}`, codigo: valor, flags: ["DOCUMENTO"]});
					}
				});
			} else {
				const credencial = credenciais.filter((c) => c.codigo === valor && c.flags.includes("DOCUMENTO"))[0];
				if (credencial.status !== "ATIVADO") {
					credencial.status = "ATIVADO";
					this.credencialService.salvar(credencial);
				}
			}
		} else {
			const select = credenciais.filter((c) => c.codigo === valor && c.flags.includes("DOCUMENTO") && c.status === "ATIVADO");
			if (select && select.length) {
				this.credencialService.excluir(select[0]).then(this.listarCredenciais);
			}
		}
	}

	novaCredencial = () => {
		const pessoa = Object.assign({}, this.state.pessoa);
		pessoa.id = null;
		ModalRealm.showDialog(<EditarCredencial key={Math.random()} credencial={{...MCredencial.Modelo(), status: "ATIVADO", pessoa}} onModalClose={(credencial) => {
			credencial.pessoa = this.state.pessoa;
			credencial.alterado = true;
			const credenciais = this.state.credenciais;
			credenciais.push(credencial);
			this.setState({credenciais});
		}} />);
	}

	notifyEditAttempt = () => {
		ModalRealm.showInformacao("warn", ["Não é possível editar este tipo de documento nesta tela."]);
	}

	detailsDocumentos = [
		<Column style={{width: "*"}} key={0} header="Tipo" body={(documento) => documento.tipo?.descricao} />,
		<Column style={{width: "*"}} key={1} header="Número" field="numero" />,
		<Column style={{width: "10em"}} key={2} header="Validade" body={(documento) => {
			const expired = documento.validade && moment(documento.validade).add(1, "days").isBefore(moment());
			return documento.validade ? <div className={expired ? "ui-expired-doc" : ""}>{moment(documento.validade).format("DD/MM/YYYY")}</div> : "Indeterminada";
		}} />,
		<Column style={{width: "6em", textAlign: "center"}} key={3} header="Ações" body={(documento) => {
			return (
				<div>
					{this.securityDocumento?.edit && isDocumentoEditavel(documento) ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Documento" onClick={() => this.editarDocumento(documento)} /> : null}
					{this.securityDocumento?.remove && isDocumentoEditavel(documento) ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Documento" onClick={() => this.excluirDocumento(documento)} /> : null}
					{!isDocumentoEditavel(documento) ? <img onClick={this.notifyEditAttempt} className="ui-action-icon" title="Não é possível editar este documento nesta tela" src={iconeLock} alt=""/> : null}
				</div>
			);
		}} />
	];

	editarDocumento = (documento) => {
		if (!documento) {
			documento = MDocumento.Modelo();
		}
		if (!documento.id && !documento._key) {
			documento._key = Math.random();
		}
		documento._cpf = this.state.pessoa.cpf;
		documento._rg = this.state.pessoa.rg;
		documento._passaporte = this.state.pessoa.passaporte;
		ModalRealm.showDialog(<EditarDocumento modo="fisica" key={Math.random()} documento={documento} onModalClose={(documento) => {
			const pessoa = this.state.pessoa;
			if (!pessoa.documentos) {
				pessoa.documentos = [];
			} else {
				pessoa.documentos = pessoa.documentos.filter((d) => d.id !== documento.id || d._key !== documento._key);
			}
			if (!Validator.isEmpty(documento._cpf)) {
				pessoa.cpf = documento._cpf;
			}
			if (!Validator.isEmpty(documento._rg)) {
				pessoa.rg = documento._rg;
			}
			if (!Validator.isEmpty(documento._passaporte)) {
				pessoa.passaporte = documento._passaporte;
			}
			pessoa.documentos.push(documento);
			this.setState({pessoa, _mod: true});
		}} />);
	}

	excluirDocumento = (documento) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja remover este documento?", () => {
			const { pessoa } = this.state;
			if (pessoa.documentos) {
				pessoa.documentos = pessoa.documentos.filter(d => d.id !== documento.id || d._key !== documento._key);
			}
			this.setState({pessoa});
		});
	}

	handleChangeWithUpper = (event) => {
		const pessoa = this.state.pessoa;
		pessoa[event.name] = this.props.uppercase ? event.value.toUpperCase() : event.value;
		this.setState({pessoa, _mod: true});
	}

	handleParams = (event) => {
		const {params} = this.state;
		switch (event.name) {
			case "flags":
				params[event.name] = event.value.join(",");
				break;
			default:
				params[event.name] = event.value;
				break;
		}
		this.setState({params});
	}

	buscarPessoaDocumento = docName => {
		const {pessoa} = this.state;
		const docNum = pessoa[docName]?.replace(/\D/g, "");
		if (docNum?.length && !pessoa.id && !this.state.isSaving) {
			this.pessoaFisicaService.buscarPorDocumento(docName, docNum).then(pessoas => {
				if (pessoas.length && pessoas[0][docName] === docNum) {
					ModalRealm.showConfirmacao(`Encontramos uma pessoa já cadastrada com esse ${nomesDocumentos[docName]}. Gostaria de utilizar esse cadastro?`, () => {
						const pessoa = pessoas[0];
						pessoa.status = "ATIVADO";
						pessoa.cpf = pessoa.cpf?.formatCpf();
						this.setState({pessoa});
					});
				}
			});
		}
	}

	removerGerido = pessoa => {
		const {geridos} = this.state;
		geridos.filter(p => p.id === pessoa.id).forEach(g => g.gestor = null);
		this.setState({geridos});
	}

	listarPessoas = event => {
		this.pessoaFisicaService.listar(`search=status:ATIVADO;nome,rg,cpf,passaporte~${event.query};id!${this.state.pessoa.id || ""};id!${this.state.geridos.map(p => p.id).join(",")}`, {size: 10, page: 0}).then(pessoas => this.setState({pessoas}));
	}

	adicionarGerido = event => {
		const pessoa = event.value;
		const {geridos} = this.state;
		pessoa.gestor = {id: this.state.pessoa.id};
		pessoa._salvar = true;
		geridos.unshift(pessoa);
		this.setState({geridos, gerido: ""});
	}

	editarEmpresa = () => {
		this.pessoaJuridicaService.buscar(this.state.pessoa.empresa).then(empresa => {
			ModalRealm.showDialog(<EditarPessoaJuridica pessoa={empresa} onModalClose={empresa => {
				const {pessoa} = this.state;
				pessoa.empresa = empresa;
				this.setState({pessoa});
			}}/>);
		});
	}

	render() {
		let fotoPessoal = this.fotoPessoal();
		const documentos = this.state.pessoa.documentos?.sort(byTipoOrdenacaoThenDescricao);
		return (
			<Dialog modal header="Pessoa" visible={this.state.visible} style={{width: "1100px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView className="ui-g-12 ui-g-nopad" activeIndex={this.state.activeIndex} onTabChange={this.tabChange}>
						<TabPanel header="Dados Pessoais">
							<PanelContent>
								<div className="ui-g-8 ui-g-nopad">
									<TextField autofocus grid={6} label="Nome" type="alphabetic" required name="nome" onChange={this.handleChangeWithUpper} value={this.state.pessoa.nome} />
									<TextField invalid={!Validator.isEmpty(this.state.pessoa.email) && !Validator.isEmail(this.state.pessoa.email)} grid={4} label="Email" name="email" onChange={this.handleChange} value={this.state.pessoa.email} />
									<DatePicker appendTo={document.body} grid={2} label="Data de Nascimento" name="nascimento" onChange={this.handleChange} value={this.state.pessoa.nascimento} />
									<TextField onBlur={() => this.buscarPessoaDocumento("cpf")} help="Cadastro de Pessoa Física" grid={3} label="CPF" type="cpf" required={this.todosDocumentosVazios()} invalid={this.state.pessoa.cpf?.length && !Validator.isCPF(this.state.pessoa.cpf)} name="cpf" onChange={this.handleCpfChange} value={this.state.pessoa.cpf}/>
									<TextField onBlur={() => this.buscarPessoaDocumento("rg")} help="Registro Geral" grid={3} label="RG" required={this.todosDocumentosVazios()} name="rg" onChange={this.handleChange} value={this.state.pessoa.rg}/>
									<TextField onBlur={() => this.buscarPessoaDocumento("nis")} help="Número da Inscrição Social (PIS/PASEP)" grid={3} label="NIS" required={this.todosDocumentosVazios()} invalid={this.state.pessoa.nis?.length && !Validator.isNIS(this.state.pessoa.nis)} name="nis" onChange={this.handleChange} value={this.state.pessoa.nis}/>
									<TextField onBlur={() => this.buscarPessoaDocumento("passaporte")} help="Documento Alternativo (Passaporte, CNH, Termo de Responsabilidade, etc.)" grid={3} label="Outro Documento" required={this.todosDocumentosVazios()} name="passaporte" onChange={this.handleChange} value={this.state.pessoa.passaporte}/>
									<TextField grid={6} label="Nome da Mãe" name="nomeMae" value={this.state.pessoa.nomeMae} onChange={this.handleChange}/>
									<TextField grid={6} label="Nome do Pai" name="nomePai" value={this.state.pessoa.nomePai} onChange={this.handleChange}/>
									<TextField grid={3} label="Telefone" type="telefone" name="telefone" onChange={this.handleChange} value={this.state.pessoa.telefone} />
									<TextField grid={3} label="Celular" type="telefone" name="celular" onChange={this.handleChange} value={this.state.pessoa.celular} />
									<MultiCombobox help={
										`Administrador: define o cadastro como administrador para equipamentos\r
										 Aceita Emails: aceita receber emails de comunicado do sistema\r
										 Registra Ponto: esta pessoa deve ser sincronizada com relógios-ponto`
									} appendTo={document.body} defaultLabel="Nenhuma" grid={6} name="flags" label="Opções" value={this.state.pessoa.flags} options={MPessoaFisica.Flags} onChange={this.handleChange} />
									<div className="ui-g-5" style={{position: "relative"}}>
										<SelectPessoaJuridica label="Empresa" name="empresa" onChange={this.handleChange} value={this.state.pessoa.empresa}/>
										{this.securityEmpresa.edit && this.state.pessoa.empresa?.id ? <i title="Editar Empresa" className="fa fa-edit button-cron-editor" onClick={this.editarEmpresa} src={iconeEditar} alt=""/> : null}
									</div>
									<MultiSelectEtiqueta grid={7} label="Etiquetas" onSelect={this.handleChange} name="etiquetas" value={this.state.pessoa.etiquetas} />
									<TextField grid={12} label="Observações" rows={4} style={{height: "6em"}} multiline value={this.state.pessoa.observacao} name="observacao" onChange={this.handleChange} />
								</div>
								<div className="ui-g-4">
									<div onClick={this.tirarFotografia} className="ui-tzm-webcam-container">
										<img alt="" className="ui-tzm-webcam" src={fotoPessoal}/> 
									</div>
									<div style={{float: "right", textAlign: "right", margin: "-37px 8px 0 0", position: "relative", zIndex: 4}}>
										<input accept="image/jpeg" style={{display: "none"}} ref={this.refInput} type="file" value="" onChange={this.uploadPhoto} />
										<Button icon="fa-upload" title="Upload de Imagem" className="ui-button-secondary" onClick={this.handlePhotoUpload} />
										<Button icon="fa-images" title="Galeria de Fotos" className="ui-button-secondary" onClick={this.openGallery} />
									</div>
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel disabled={!this.securityDocumento?.view} header="Documentos">
							<PanelContent>
								<DataTable
										emptyMessage="Nenhum documento cadastrado"
										value={documentos}
										rows={GUI.defaultRows}
										rowsPerPageOptions={GUI.rowsPerPage}
										paginator
										paginatorLeft={this.securityDocumento?.create ? <Button onClick={() => this.editarDocumento()} icon="fa-plus" className="ui-tzm-pagleft-icon" /> : null}
										children={this.detailsDocumentos}
								/>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Endereços">
							<PanelContent>
								<Combobox grid={2} label="Tipo" appendTo={document.body} value={this.state.endereco.tipo} name="tipo" options={MEndereco.Tipos} onChange={this.changeTipoEndereco} />
								<TextField grid={2} label="CEP" type="cep" name="cep" onBlur={this.buscarEndereco} onKeyUp={(event) => {
									if (event.keyCode === 13) {
										document.activeElement.blur();
									}
								}} onChange={this.handleChangeEndereco} value={this.state.endereco.cep} />
								<TextField grid={6} label="Logradouro" name="logradouro" onChange={this.handleChangeEndereco} value={this.state.endereco.logradouro} />
								<TextField grid={2} label="Número" name="numero" onChange={this.handleChangeEndereco} value={this.state.endereco.numero} />
								<TextField grid={3} label="Complemento" name="complemento" onChange={this.handleChangeEndereco} value={this.state.endereco.complemento} />
								<TextField grid={3} label="Bairro" name="bairro" onChange={this.handleChangeEndereco} value={this.state.endereco.bairro} />
								<TextField grid={3} label="Cidade" name="nome" onChange={this.handleChangeEnderecoLocalidade} value={this.state.endereco.localidade.nome} />
								<Combobox grid={3} label="UF" appendTo={document.body} name="uf" options={MEndereco.UFs} onChange={this.handleChangeEnderecoLocalidadeSuggestion} value={this.state.endereco.localidade.uf} />
							</PanelContent>
						</TabPanel>
						<TabPanel header="Credenciais">
							<PanelContent>
								<SuggestionBox field="descricao" suggestions={this.state.scredenciais} completeMethod={this.listarCredenciais} grid={6} label="Credencial" name="credencial" onChange={this.handleState} onSelect={this.adicionarCredencial} value={this.state.credencial} />
								<MultiCombobox defaultLabel="Nenhuma" name="flags" onChange={this.handleParams} value={this.state.params.flags && this.state.params.flags.length ? this.state.params.flags.split(",") : []} label="Opções" grid={3} options={MCredencial.Flags} />
								<MultiCombobox defaultLabel="Nenhum" name="documentos" onChange={this.handleDocumentos} value={this.state.documentos} label="Documentos como Senha" grid={3} options={this.opcoesDocumento()} />
								<div className="ui-g-12">
									<DataTable rows={10} paginator paginatorLeft={
										this.securityCredencial.create ? <Button onClick={() => this.novaCredencial()} icon="fa-plus" className="ui-tzm-pagleft-icon" /> : null
									} emptyMessage="Nenhuma credencial adicionada" value={this.state.credenciais != null ? this.state.credenciais.filter(c => c.pessoa != null && c.status === "ATIVADO" && !c.flags.includes("DOCUMENTO")) : []} children={this.detalhesCredenciais} />
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Avisos" headerClassName="ui-tzm-alt-tab">
							<DataTable rows={10} emptyMessage="Nenhum aviso encontrado" value={this.state.avisos} paginator paginatorLeft={this.footerAvisos} children={this.detailsAviso} />
						</TabPanel>
						<TabPanel header="Acesso" headerClassName="ui-tzm-alt-tab" disabled={!this.state.pessoa.id}>
							<PanelContent style={{display: this.state.criterioAcesso ? "block" : "none", position: "relative"}}>
								<div className="ui-g-6">
									<div className="ui-tzm-controlpanel-group">Bloqueios</div>
									{
										this.state.criterioAcesso && this.state.criterioAcesso.bloqueios
										? <div className="ui-tzm-controlpanel ui-tzm-controlpanel-fail"><i className="fa fa-times" style={{marginRight: "10px"}} />O acesso desta pessoa está bloqueado.{this.securityBloqueio.remove ? <div className="ui-tzm-controlpanel-button" onClick={this.desbloquearPessoa}>Desbloquear</div> : null}</div>
										: <div className="ui-tzm-controlpanel"><i className="fa fa-check" style={{marginRight: "10px"}} />Não há bloqueios para esta pessoa.</div>
									}
									<div className="ui-tzm-controlpanel-group">Credenciais</div>
									{
										this.state.criterioAcesso && this.state.criterioAcesso.credenciais
										? <div className="ui-tzm-controlpanel"><i className="fa fa-check" style={{marginRight: "10px"}} />Esta pessoa possui credenciais ativas.</div>
										: <div className="ui-tzm-controlpanel ui-tzm-controlpanel-warn"><i className="fa fa-exclamation-triangle" style={{marginRight: "10px"}} />Não há credenciais para esta pessoa.{this.securityCredencial.create ? <span><div className="ui-tzm-controlpanel-button" onClick={this.cadastrarDigital}>Cadastrar Digital</div><div className="ui-tzm-controlpanel-button" onClick={this.criarSenha}>Criar Senha</div></span> : null}</div>
									}
									<div className="ui-tzm-controlpanel-group">Agendas</div>
									{
										this.state.criterioAcesso && this.state.criterioAcesso.agendas
										? <div className="ui-tzm-controlpanel"><i className="fa fa-check" style={{marginRight: "10px"}} />Esta pessoa possui agendas ativas.</div>
										: <div className="ui-tzm-controlpanel ui-tzm-controlpanel-warn"><i className="fa fa-exclamation-triangle" style={{marginRight: "10px"}} />Esta pessoa não possui agendas ativas.{this.securityAgenda.create ? <div className="ui-tzm-controlpanel-button" onClick={this.criarAgenda}>Criar Agenda</div> : null}</div>
									}
								</div>
								<div className="ui-g-6">
									<div className="ui-tzm-controlpanel-group">Anotações</div>
									<ul>
										{this.state.criterioAcesso?.lugares?.length ? this.state.criterioAcesso.lugares.filter((l) => l != null).map(l => <li key={l.id}>{`Possui acesso em ${l.descricao}`}</li>) : <li>Não possui acesso a lugar nenhum</li>}
										{this.state.criterioAcesso?.unidades?.length ? this.state.criterioAcesso.unidades.map(e => <li key={e.id}>{`Possui agenda(s) para ${e.descricao}`}</li>) : <li>Não possui agenda para nenhuma unidade</li>}
										{this.state.criterioAcesso?.liberacoes?.length ? this.state.criterioAcesso.liberacoes.map(e => <li key={e.id}>{`Possui pré-autorização para ${e.descricao}`}</li>) : <li>Não possui pré-autorização para nenhuma unidade</li>}
									</ul>
								</div>
								<div style={{position: "absolute", bottom: "4px", right: "2px"}}>
									{this.state.pessoa.id && this.state.criterioAcesso && this.state.criterioAcesso.agendas > 0 ? <Action mode="delete" label="Revogar Acessos" onClick={this.revogarAcessos} /> : null}
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Manutenção" headerClassName="ui-tzm-alt-tab">
							<PanelContent>
								<Combobox grid={3} label="Categoria" options={[{label: "Todas", value: null}, ...MEquipamento.Categorias]} name="categoria" value={this.state.categoria} onChange={this.handleCategoria}/>
								<div className="ui-g-9" style={{textAlign: "center"}}>
									<div style={{textAlign: "left"}}>
										<Label>Ações</Label>
									</div>
									<div>
										<Action mode="post" label="Enviar" onClick={this.enviarEquipamentos}/>
										<Action mode="delete" label="Remover" onClick={this.removerEquipamentos}/>
									</div>
								</div>
								<div className="ui-g-4">
									{this.printTableEquipamentos(0)}
								</div>
								<div className="ui-g-4">
									{this.printTableEquipamentos(1)}
								</div>
								<div className="ui-g-4">
									{this.printTableEquipamentos(2)}
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Ponto" headerClassName="ui-tzm-alt-tab">
							<PanelContent>
								<SelectPessoaFisica grid={6} name="gestor" value={this.state.pessoa.gestor} onChange={this.handleChange} label="Gestor"/>
								<TextField grid={2} label="Turno" value={this.state.pessoa.turno} name="turno" onChange={this.handleChange}/>
								<SelectEtiqueta grid={4} label="Centro de Custo" name="centroCusto" value={this.state.pessoa.centroCusto} onChange={this.handleChange}/>
								<SelectGrupoTrabalho grid={4} name="grupoTrabalho" value={this.state.pessoa.grupoTrabalho} onChange={this.handleChange} label="Grupo de Trabalho"/>
								<TextField grid={4} label="Função" value={this.state.pessoa.funcao} name="funcao" onChange={this.handleChange}/>
								<DatePicker appendTo={document.body} grid={2} name="dataAdmissao" value={this.state.pessoa.dataAdmissao} label="Data de Admissão" onChange={this.handleChange}/>
								<DatePicker appendTo={document.body} grid={2} name="dataDemissao" value={this.state.pessoa.dataDemissao} label="Data de Demissão" onChange={this.handleChange}/>
								<SuggestionBox disabled={!this.state.pessoa.id} placeholder="Pesquise por nome ou qualquer documento" style={{position: "relative"}} appendTo={document.body} label="Pessoas" grid={12} field="nome" completeMethod={this.listarPessoas} suggestions={this.state.pessoas} onChange={e => this.setState({gerido: e.value})} onSelect={this.adicionarGerido} value={this.state.gerido}/>
								<div className="ui-g-12">
									<DataTable paginator rowsPerPageOptions={GUI.rowsPerPage} rows={10} disabled={!this.state.pessoa.id} emptyMessage="Nenhum registro encontrado" header="Pessoas Gerenciadas" value={this.state.geridos.filter(g => g.gestor)}>
										<Column header="Nome" field="nome"/>
										<Column style={{width: "9em"}} header="RG" field="rg"/>
										<Column style={{width: "10em"}} header="CPF" body={g => g.cpf?.formatCpf()}/>
										<Column style={{width: "10em"}} header="NIS" field="nis"/>
										<Column style={{width: "5em"}} header="Ações" body={g => (
											<div style={{textAlign: "center"}}>
												<img alt="" className="ui-action-icon" src={iconeRemover} title="Remover Pessoa" onClick={() => this.removerGerido(g)} />
											</div>
										)}/>
									</DataTable>
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.pessoa.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/pessoas-fisicas", this.state.pessoa)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.pessoa.id)) || this.state.lockSave} onClick={this.salvarPessoa} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.fecharSemSalvar} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.salvarPessoa} onEscape={this.fecharSemSalvar}/> : null}
			</Dialog>
		);
	}

	handleCategoria = (event) => {
		this.setState({[event.name]: event.value}, () => {
			if (this.state.equipamentos?.length) {
				const {equipamentos} = this.state;
				equipamentos.forEach(e => e._visivel = e.categoria === this.state.categoria);
				this.setState({equipamentos});
			}
		});
	}

	printTableEquipamentos = (index) => {
		return (
			<DataTable emptyMessage="Nenhum registro encontrado" value={this.state.equipamentos?.filter((e, i) => (!this.state.categoria || e._visivel) && i % 3 === index)}>
				<Column style={{width: "3em", textAlign: "center"}} header={
					index === 0 ? <CheckSquare checked={this.state.equipamentos?.every(e => e._selecionado)} onChange={this.toggleAllEquipamentos}/> : null
				} body={(e) => (
					<CheckSquare checked={e._selecionado} onChange={() => this.toggleEquipamento(e)}/>
				)}/>
				<Column style={{width: "4em", textAlign: "center"}} header="Cat" body={(e) => {
					switch (e.categoria) {
						case "ACESSO": return <img title="Acesso" className="inline-image" src={iconeAcesso} alt="" />;
						case "PONTO": return <img title="Ponto" className="inline-image" src={iconePonto} alt="" />;
						default: return null;
					}
				}}/>
				<Column header="Descrição" field="descricao"/>
			</DataTable>
		);
	}

	enviarManutencao = (comando, confirm) => {
		const manutencao = {
			equipamentos: this.state.equipamentos?.filter(e => e._selecionado),
			pessoas: [this.state.pessoa]
		};
		if (manutencao.equipamentos?.length) {
			if (confirm) {
				ModalRealm.showConfirmacao("Tem certeza de que deseja fazer isto?", () => {
					this.manutencaoService[comando](manutencao).then(() => {
						ModalRealm.showInformacao("info", ["Comando enviado com sucesso!"]);
					});
				});
			} else {
				this.manutencaoService[comando](manutencao).then(() => {
					ModalRealm.showInformacao("info", ["Comando enviado com sucesso!"]);
				});
			}
		} else {
			ModalRealm.showInformacao("warn", ["Nenhum equipamento selecionado"]);
		}
	}

	enviarEquipamentos = () => this.enviarManutencao("enviarPessoa");

	removerEquipamentos = () => this.enviarManutencao("removerPessoa", true);

	toggleEquipamento = (e) => {
		const {equipamentos} = this.state;
		equipamentos.filter(f => e.id === f.id).forEach(e => e._selecionado = !e._selecionado);
		this.setState({equipamentos});
	}

	toggleAllEquipamentos = () => {
		const {equipamentos} = this.state;
		equipamentos.forEach(e => e._selecionado = !e._selecionado);
		this.setState({equipamentos});
	}

	uploadPhoto = (event) => {
		const file = event.target.files[0];
		if (file) {
			let reader = new FileReader();
			reader.onloadend = async () => {
				let foto = {
					status: "ATIVADO",
					_timestamp: new Date(),
					finalidade: "IDENTIFICACAO",
					conteudo: reader.result.split(",")[1]
				};
				foto = await httpPost("/fotos", foto);
				const {pessoa} = this.state;
				if (!pessoa.fotos) {
					pessoa.fotos = [];
				}
				pessoa.fotos.push(foto);
				this.setState({pessoa, _modFotos: true});
			};
			reader.readAsDataURL(file);
		}
	}

	handlePhotoUpload = (event) => {
		event.stopPropagation();
		this.inputFile.click();
	}

	refInput = (el) => this.inputFile = el;

	revogarAcessos = () => {
		ModalRealm.showDialog(<ConfirmarRevogacao onYes={() => {
			httpDelete(`/agendas/revogar/${this.state.pessoa.id}`).then(() => {
				const criterioAcesso = this.state.criterioAcesso;
				criterioAcesso.agendas = false;
				this.setState({criterioAcesso});
			});
		}} />);
	}

	todosDocumentosVazios() {
		return (
			Validator.isEmpty(this.state.pessoa.rg)
			&& Validator.isEmpty(this.state.pessoa.cpf)
			&& Validator.isEmpty(this.state.pessoa.nis)
			&& Validator.isEmpty(this.state.pessoa.passaporte)
		);
	}

}

export class Galeria extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			visible: true,
			fotos: props.fotos != null ? props.fotos.filter(f => f.status === "ATIVADO").sort((a, b) => {
				if (a.id == null) return -1;
				if (b.id == null) return 1;
				return b.id - a.id;
			}) : []
		};
	}

	onClose = () => {
		this.setState({visible: false});
	}

	imageTemplate = (foto) => {
		return (
			<div className="ui-g-3" style={{position: "relative"}}>
				<img style={{cursor: "pointer"}} className="ui-gallery-image" src={toSrc(foto)} alt="" onClick={() => {
					if (foto.conteudo) {
						let image = new Image();
						image.src = foto.conteudo;
						let w = window.open("");
						w.document.write(image.outerHTML);
					} else {
						window.open(toSrc(foto), "_blank")
					}
				}} />
				<img alt="" className="icon-remove-foto" src={iconeRemover} onClick={() => this.removerFoto(foto)} />
			</div>
		);
	}

	removerFoto = (foto) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta imagem?", () => {
			if (foto.id) {
				httpDelete(`/fotos/${foto.id}`).then(() => {
					let fotos = this.state.fotos;
					fotos = fotos.filter(f => f.id !== foto.id);
					this.setState({fotos, _mod: true});
					if (this.props.onRemoverFoto) {
						this.props.onRemoverFoto(foto);
					}
				});
			} else {
				let fotos = this.state.fotos;
				fotos = fotos.filter(f => f.id !== foto.id);
				this.setState({fotos, _mod: true});
				if (this.props.onRemoverFoto) {
					this.props.onRemoverFoto(foto);
				}
			}
		});
	}

	render() {
		return (
			<Dialog header="Galeria de Fotos" modal visible={this.state.visible} style={{width: "80%"}} onHide={this.onClose}>
				<DialogContent>
					<div className="ui-g-12 ui-g-nopad">
						<DataView paginator value={this.state.fotos} layout="grid" rows={8} itemTemplate={this.imageTemplate} />
					</div>
				</DialogContent>
				<DialogFooter>
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose}/>
				</DialogFooter>
				{this.state.visible ? <Shortcut onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}
