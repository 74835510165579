import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarCredencial} from "../components/Credencial/EditarCredencial";
import {CredencialService} from "../components/servicos/CredencialService";
import {MCredencial} from "../utils/models/MCredencial";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectCredencial extends React.Component {

	service = new CredencialService();

	security = UserData.security("CRD");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarCredencial key={Math.random()} credencial={{...MCredencial.Modelo(), descricao: event.value.descricao.replace(`Criar credencial `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value});
		}
	}

	render() {
		return <Select insertable={this.security.create && this.props.insertable} entidade="credencial" service={this.service} field="descricao" query="status:ATIVADO;descricao" {...this.props} onSelect={this.onSelect} />;
	}

}
