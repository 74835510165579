import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeCredenciais from "../../media/icones/credenciais.png";
import iconeCriarSenha from "../../media/icones/criar-senha.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import {AppletUtils} from "../../utils/AppletUtils";
import {GUI} from "../../utils/Constants";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {CriarSenha} from "../Credencial/CriarSenha";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {EditarPessoaFisica} from "./EditarPessoaFisica";
import {byRegistroDesc, isFinalidadeIdentificacao, toSrc} from "../../utils/StringUtils";
import {SelectPessoaJuridica} from "../../select/SelectPessoaJuridica";
import {ColetarDigital} from "./ColetarDigital";
import {isDentroOuGaragem} from "../../utils/PessoaFisicaUtils";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";

export class PessoaFisica extends React.Component {

	state = {
		nome: "",
		cpf: "",
		rg: "",
		nis: "",
		passaporte: "",
		endereco: "",
		numero: "",
		etiqueta: [],
		firstResult: 0,
		empresa: null
	};

	etiquetaService = new EtiquetaService();
	pessoaFisicaService = new PessoaFisicaService();
	security = UserData.security("PES", true);
	securityCredentials = UserData.security('CRD');

	listarPessoas = () => {
		if (this.queryTimeout != null) clearTimeout(this.queryTimeout);
		this.queryTimeout = setTimeout(() => {
			let aParams = [];
			if (!this.state.excluidos) aParams.push("status:ATIVADO");
			if (this.state.nome?.length) aParams.push(`nome~${this.state.nome}`);
			if (this.state.cpf?.length) aParams.push(`cpf~${this.state.cpf}`);
			if (this.state.rg?.length) aParams.push(`rg~${this.state.rg}`);
			if (this.state.nis?.length) aParams.push(`nis~${this.state.nis}`);
			if (this.state.telefone?.length) aParams.push(`telefone~${this.state.telefone.replace(/[^0-9]/g, " ")}`);
			if (this.state.celular?.length) aParams.push(`celular~${this.state.celular.replace(/[^0-9]/g, " ")}`);
			if (this.state.passaporte?.length) aParams.push(`passaporte~${this.state.passaporte}`);
			let params = aParams.join(";");
			if (this.state.endereco?.length) params += `&enderecos=logradouro~${this.state.endereco}`;
			if (this.state.numero?.length) params += `&enderecos=numero~${this.state.numero}`;
			if (this.state.etiquetas?.length) params += `&etiquetas=id:${this.state.etiquetas.map((e) => e.id).join(";&etiquetas=id:")}`;
			if (this.state.empresa) params += `&empresa=id:${this.state.empresa.id}`;
			this.pessoaFisicaService.listar(`search=${params}`, {page: 0, size: GUI.maxResults, sort: "nome"}).then((pessoas) => this.setState({pessoas, firstResult: 0}));
		}, 50);
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarPessoa = (rowData) => {
		if (rowData?.id) {
			this.pessoaFisicaService.buscar(rowData).then((pessoa) => {
				ModalRealm.showDialog(<EditarPessoaFisica key={Math.random()} pessoa={pessoa} onModalClose={this.listarPessoas}/>);
			});
		} else {
			ModalRealm.showDialog(<EditarPessoaFisica key={Math.random()} pessoa={null} onModalClose={this.listarPessoas}/>);
		}
	}

	excluirPessoa = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta pessoa?", () => this.pessoaFisicaService.excluir(rowData).then(this.listarPessoas));
	}

	handleUpdateSuggestion = (event) => {
		this.setState({[event.name]: event.value});
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {page: 0, size: 10, sort: "descricao"}).then((etiquetas) => this.setState({etiquetas}));
	}

	cadastrarDigital = (rowData) => {
		AppletUtils.downloadFingerprint(rowData);
	}

	criarSenha = (rowData) => {
		ModalRealm.showDialog(<CriarSenha key={Math.random()} pessoa={rowData} onModalClose={() => {
			this.listarPessoas();
		}} />);
	}

	coletarDigital = pessoa => {
		ModalRealm.showDialog(<ColetarDigital key={Math.random()} pessoa={pessoa}/>);
	}

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/pessoas-fisicas", entidade);
	}

	removerEtiqueta = (rowData) => {
		this.setState({etiqueta: this.state.etiqueta.filter(e => e.id !== rowData.id)});
	}

	restaurarPessoa = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar esta pessoa?", () => {
			if (rowData != null && rowData.id != null) {
				this.pessoaFisicaService.buscar(rowData).then((pessoa) => {
					pessoa.status = "ATIVADO";
					this.pessoaFisicaService.salvar(pessoa).then((pessoa) => {
						this.listarPessoas();
						ModalRealm.showDialog(<EditarPessoaFisica key={Math.random()} pessoa={pessoa} activeTab={5} />);
						ModalRealm.showInformacao("warn", ["Ao reativar uma pessoa seus acessos são restaurados. Verifique se é necessária alguma alteração."]);
					});
				});
			}
		});
	}

	eliminarPessoa = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente esta pessoa?", () => this.pessoaFisicaService.eliminar(rowData).then((result) => {
			if (result) this.listarPessoas(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar esta pessoa: há outros registros dependentes dela no sistema."]);
		}));
	}

	changePage = (event) => this.setState({firstResult: event.first});

	render() {
		return (
			<div>
				<Panel header="Pessoas">
					<PanelContent>
						<TextField grid={10} label="Nome" autofocus name="nome" value={this.state.nome} onChange={this.handleUpdate}/>
						<TextField grid={2} label="CPF" name="cpf" value={this.state.cpf} onChange={this.handleUpdate}/>
						<TextField grid={2} label="RG" name="rg" value={this.state.rg} onChange={this.handleUpdate}/>
						<TextField grid={2} label="NIS" name="nis" value={this.state.nis} onChange={this.handleUpdate}/>
						<TextField grid={2} label="Documento" name="passaporte" value={this.state.passaporte} onChange={this.handleUpdate}/>
						<TextField grid={5} label="Endereço" name="endereco" value={this.state.endereco} onChange={this.handleUpdate}/>
						<TextField grid={1} label="Número" name="numero" value={this.state.numero} onChange={this.handleUpdate}/>
						<MultiSelectEtiqueta grid={4} value={this.state.etiquetas} name="etiquetas" onSelect={this.handleUpdate}/>
						<TextField grid={2} type="telefone" label="Telefone" name="telefone" value={this.state.telefone} onChange={this.handleUpdate}/>
						<TextField grid={2} type="telefone" label="Celular" name="celular" value={this.state.celular} onChange={this.handleUpdate}/>
						<SelectPessoaJuridica grid={4} label="Empresa" value={this.state.empresa} onChange={this.handleUpdate} name="empresa"/>
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate}/> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarPessoas}/>
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.pessoas} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator>
					<Column style={{width: "8em"}} header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id"/>
					<Column header="Foto"  style={{width: "88px", textAlign: "center"}} body={pessoa => {
						if (pessoa.fotos) {
							let identificacoes = pessoa.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc);
							if (identificacoes.length) {
								return <img className="logs-acesso-v2-foto" style={{float: "none", verticalAlign: "top"}} src={toSrc(identificacoes[0], true)} alt=""/>;
							}
						}
						return <span className="logs-acesso-v2-foto sem-foto"/>;
					}} />
					<Column header="Nome" style={{width: "*"}} sortable field="nome" body={pessoa => (
						<div>
							<div>
								{pessoa.flags.includes("ADMINISTRADOR") ? <span><i title="Administrador" className="fad fa-user-shield" style={{color: "#4CAF50"}}/> </span> : null}
								{pessoa.nascimento && pessoa.nascimento.endsWith(moment().format("MM-DD")) ? <span><i title="Aniversariante" className="fad fa-birthday-cake" style={{color: "#EC407A"}}/> </span> : null}
								{pessoa.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
								<span style={{fontWeight: "bold"}}>{pessoa.nome}</span>
							</div>
							<div style={{fontSize: ".8em", fontStyle: "italic"}}>
								{pessoa.empresa?.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
								{pessoa.empresa?.nome}
							</div>
							<div>
								{`${isDentroOuGaragem(pessoa) ? `Em ${pessoa.lugar.descricao}` : "Fora"}${pessoa.ultimaPassagem ? ` desde ${moment(pessoa.ultimaPassagem).format("DD/MM/YY HH:mm:ss")}` : ""}`}{pessoa.ultimaVisita ? ` - Último acesso em ${pessoa.ultimaVisita}` : ""}<br/>
								{pessoa.telefone != null ? <span><i className="fa fa-phone"/> {pessoa.telefone}</span> : null}
								{pessoa.telefone != null && pessoa.celular != null ? " • " : ""}
								{pessoa.celular != null ? <span><i className="fa fa-phone"/> {pessoa.celular}</span> : null}
							</div>
						</div>
					)} />
					<Column header="Documentos" style={{width: "15em", textAlign: "center"}} body={pessoa => (
						<div>
							{pessoa.rg ? <div>RG: {pessoa.rg}</div> : null}
							{pessoa.cpf ? <div>CPF: {pessoa.cpf.formatCpf()}</div> : null}
							{pessoa.nis ? <div>NIS: {pessoa.nis}</div> : null}
							{pessoa.passaporte ? <div>Documento: {pessoa.passaporte}</div> : null}
							{
								pessoa.documentos?.map(documento => {
									const expired = documento.validade && moment(documento.validade).add(1, "days").isBefore(moment());
									return (
										<div title={expired ? "Documento expirado" : ""} className={expired ? "ui-expired-doc" : ""}>
											{`${documento.tipo?.descricao}${documento.numero ? `: ${documento.numero}` : ""}`}
										</div>
									);
								})
							}
						</div>
					)}/>
					<Column header="Etiquetas" style={{width: "*", textAlign: "center"}} body={pessoa => pessoa.etiquetas.map(e => <EtiquetaChip key={e.id} etiqueta={e}/>)}/>
					<Column header="Ações" style={{width: "9em"}} body={pessoa => {
						return (
							<div style={{textAlign: "center"}}>
								{this.security.edit && pessoa.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Pessoa" onClick={() => this.editarPessoa(pessoa)}/> : null}
								{this.security.edit && this.securityCredentials.create && pessoa.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeCriarSenha} title="Cadastrar Senha" onClick={() => this.criarSenha(pessoa)}/> : null}
								{this.security.edit && this.securityCredentials.create && pessoa.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeCredenciais} title="Cadastrar Digital" onClick={() => this.cadastrarDigital(pessoa)}/> : null}
								{/*{this.security.edit && this.securityCredentials.create && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeCredenciais} title="Cadastrar Digital" onClick={() => this.coletarDigital(rowData)}/> : null}*/}
								{this.security.remove && pessoa.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Pessoa" onClick={() => this.excluirPessoa(pessoa)}/> : null}
								{this.security.remove && pessoa.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(pessoa)}/> : null}
								{this.security.remove && pessoa.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Pessoa" onClick={() => this.restaurarPessoa(pessoa)}/> : null}
								{this.security.remove && pessoa.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Pessoa" onClick={() => this.eliminarPessoa(pessoa)}/> : null}
							</div>
						);
					}}/>
				</DataTable>
				<Shortcut
					onEnter={this.listarPessoas}
					onCtrlN={() => window.location.pathname === "/pessoas-fisicas" && this.security.create && this.editarPessoa()}
					onCtrlE={() => window.location.pathname === "/pessoas-fisicas" && this.security.edit && this.state.pessoas.length === 1 && this.editarPessoa(this.state.pessoas[0])}
				/>
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Nova Pessoa" icon="fa-plus" onClick={this.editarPessoa}/> : null}
				</ActionFloat>
				<ModalRealm/>
			</div>
		);
	}

}
