import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Dialog} from "primereact/components/dialog/Dialog";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import iconeExcluir from "../../media/icones/excluir.png";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {MCredencial, optionsTecnologias} from "../../utils/models/MCredencial";
import {NumberUtils} from "../../utils/NumberUtils";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {CredencialService} from "../servicos/CredencialService";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {WiegandHelp} from "./WiegandHelp";
import {SelectVeiculo} from "../../select/SelectVeiculo";
import {httpGet} from "../../utils/Request";
import {ManutencaoService} from "../servicos/ManutencaoService";

const formasWiegand = [
	"PROXIMIDADE",
	"TAG"
];

export class EditarCredencial extends React.Component {

	fillNulls = (credencial) => {
		if (!credencial) {
			credencial = MCredencial.Modelo();
		} else if (credencial.id) {
			switch (credencial.forma) {
				case "TAG":
				case "PROXIMIDADE":
					// TODO converter para wiegand
					let facility = NumberUtils.intToByteArray(credencial.codigo);
					console.log(facility);
					credencial._wiegand = Number(`${(facility[2] << 8) | facility[3]}${String(credencial.codigo & 0xFFFFFFFF).padStart(5, '0')}`);
					const _wiegand = Number(credencial._wiegand);
					if (_wiegand >= 100000) {
						credencial._decimal = Number(((_wiegand / 100000) << 16) | (_wiegand % 100000));
					}
					break;
				default:
			}
		}
		return credencial;
	}

	state = {
		credencial: this.fillNulls(this.props.credencial),
		titularAnterior: this.props.credencial?.pessoa,
		visible: true,
		activeIndex: 0,
		lockTitular: this.props.credencial && !this.props.credencial.id && this.props.credencial.pessoa,
		lockSave: false
	};

	credencialService = new CredencialService();
	pessoaFisicaService = new PessoaFisicaService();
	manutencaoService = new ManutencaoService();
	security = UserData.security("CRD", true);

	onClose = () => {
		this.setState({visible: false});
	}
	gerarQr = () => {
		this.credencialService.gerarQr();
	}

	saveCredencial = () => {
		if (this.security.edit) {
			let credencial = MCredencial.cleanupBeforeSave(Object.assign({}, this.state.credencial));
			let messages = MCredencial.validarParaSalvar(credencial);
			if (messages.length > 0) {
				ModalRealm.showInformacao("warn", messages);
			} else {
				if (credencial && credencial.pessoa && !credencial.pessoa.id) {
					if (this.props.onModalClose) {
						this.props.onModalClose(credencial);
					}
					this.onClose(credencial);
				} else {
					this.setState({lockSave: true});
					this.credencialService.salvar(credencial).then(credencial => {
						this.setState({lockSave: false});
						if (this.props.onModalClose) {
							this.props.onModalClose(credencial);
						}
						if (credencial.forma === "CONTROLE") {
							httpGet("/equipamentos?search=familia:LINEAR;status:ATIVADO&page=0&size=100").then(equipamentos => {
								if (equipamentos.length) {
									if (credencial.pessoa) {
										this.manutencaoService.enviarPessoa({
											equipamentos,
											pessoas: [credencial.pessoa]
										});
									} else {
										if (this.state.titularAnterior) {
											this.manutencaoService.removerCredencial({
												equipamentos,
												credenciais: [credencial]
											});
										}
									}
								}
							});
						}
						this.onClose();
					}).catch(() => this.setState({lockSave: false}));
				}
			}
		}
	}

	handleChange = (event) => {
		let credencial = this.state.credencial;
		credencial[event.name] = event.value;
		this.setState({credencial});
	}

	handleChangeItem = (value, property, index) => {
		let credencial = this.state.credencial;
		credencial.itens[index][property] = value;
		this.setState({credencial});
	}

	handleDropdown = (property, event) => {
		let credencial = this.state.credencial;
		credencial[property] = event.value;
		this.setState({credencial});
	}

	flags = [
		{label: "Temporária", value: "TEMPORARIA"},
		{label: "Master", value: "MASTER"},
		{label: "Documento", value: "DOCUMENTO"}
	];

	listTitulares = (event) => {
		this.pessoaFisicaService.listar(`search=nome,cpf,rg,passaporte~${event.query}`, {page: 0, size: 10}).then((titulares) => this.setState({titulares}));
	}

	handleAutoCompleteSelect = (event) => {
		let credencial = this.state.credencial;
		credencial[event.name] = event.value;
		this.setState({credencial});
	}

	removerItem = (event, rowData, column) => {
		let credencial = this.state.credencial;
		credencial.itens.splice(column.rowIndex, 1);
		this.setState({credencial});
	}

	handleChangeCodigo = (event) => {
		const credencial = this.state.credencial;
		if (!credencial.id && credencial.forma === "HANDKEY") {
			credencial.descricao = `${credencial.forma} ${event.value}`;
		}
		credencial.codigo = event.value;
		this.setState({credencial});
	}

	dedos = [
		"Mínimo Esquerdo",
		"Anelar Esquerdo",
		"Médio Esquerdo",
		"Indicador Esquerdo",
		"Polegar Esquerdo",
		"Polegar Direito",
		"Indicador Direito",
		"Médio Direito",
		"Anelar Direito",
		"Mínimo Direito"
	];

	detalhamento = [
		<Column style={{width: "5em"}} header="Auxiliar" body={(rowData, column) => {
			switch (this.state.credencial.forma) {
				case "DIGITAL":
					if (rowData.numero != null) {
						return <div style={{textAlign: "center"}}>{this.dedos[rowData.numero]}</div>;
					}
					break;
				default:
					break;
			}
			return <TextField index={column.rowIndex} value={this.state.credencial.itens[column.rowIndex].numero} onChange={(event) => this.handleChangeItem(event.target.value, "numero", column.rowIndex)} />;
		}} />,
		<Column style={{width: "*"}} header="Opções" body={(rowData, column) => {
			return <MultiCombobox appendTo={document.body} defaultLabel="Nenhuma" options={MCredencial.Item.Flags} value={this.state.credencial.itens[column.rowIndex].flags} onChange={(event) => this.handleChangeItem(event.value, "flags", column.rowIndex)} />;
		}} />,
		<Column style={{width: "8em"}} header="Formato" body={(rowData, column) => {
			return MCredencial.Item.MapFormatos[this.state.credencial.itens[column.rowIndex].formato];
		}} />,
		<Column style={{width: "5em"}} header="Qualidade" body={(rowData, column) => {
			return this.state.credencial.itens[column.rowIndex].qualidade;
		}} />,
		<Column style={{width: "5em"}} header="Ações" body={(rowData, column) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Remover Detalhe" onClick={(event) => this.removerItem(event, rowData, column)} /> : null}
				</div>
			);
		}} />
	];

	tabChange = (event) => this.setState({activeIndex: event.index});

	handleCodigo = (event) => {
		if (event.charCode === 13) {
			let credencial = this.state.credencial;
			credencial.codigo = NumberUtils.toLittleEndian(credencial.codigo);
			this.setState({credencial});
		}
	}

	handleWiegand = () => {
		const {credencial} = this.state;
		let _wiegand = Number(this.state.credencial._wiegand);
		let _decimal = _wiegand;
		if (_wiegand >= 100000) {
			_decimal = ((_decimal / 100000) << 16) | (_decimal % 100000);
		}
		credencial._wiegand = _wiegand;
		credencial._decimal = _decimal;
		credencial.codigo = (Math.floor(_wiegand / 100000) * Math.pow(2, 32)) + (_wiegand % 100000);
		this.setState({credencial});
	}

	handleDecimal = () => {
		const {credencial} = this.state;
		let _decimal = Number(this.state.credencial._decimal);
		let _wiegand = Number(`${(_decimal & 0xFFFF0000) >> 16}${_decimal & 0x0000FFFF}`);
		credencial._decimal = _decimal;
		credencial._wiegand = _wiegand;
		credencial.codigo = (Math.floor(_wiegand / 100000) * Math.pow(2, 32)) + (_wiegand % 100000);
		this.setState({credencial});
	}

	detalhavel = [
		"CONTROLE",
		"DIGITAL"
	];

	showWiegandHelp = () => {
		ModalRealm.showDialog(<WiegandHelp/>);
	}

	handleChangeVeiculo = event => {
		const {credencial} = this.state;
		if (event.value) {
			credencial.veiculo = event.value;
			credencial.pessoa = event.value.proprietario;
		} else {
			credencial.veiculo = null;
		}
		this.setState({credencial});
	}

	render() {
		let incluirItem = this.state.credencial.id == null || this.state.credencial.forma !== "DIGITAL" ? <Button className="ui-tzm-pagleft-icon" icon="fa-plus" onClick={() => {
			let credencial = this.state.credencial;
			if (credencial.itens == null) credencial.itens = [];
			credencial.itens.push({});
			this.setState({credencial});
		}} /> : null;
		return (
			<Dialog modal header="Credencial" visible={this.state.visible} style={{width: "950px"}} onHide={this.onClose}>
				<DialogContent>
					<TabView onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Dados Principais">
							<div className="ui-g">
								<TextField label="Descrição" grid={this.state.credencial.forma === "PROXIMIDADE" ? 6 : 8} required name="descricao" onChange={this.handleChange} value={this.state.credencial.descricao} />
								<Combobox label="Forma" grid={4} appendTo={document.body} disabled={this.state.credencial.id} options={MCredencial.Formas} onChange={(event) => this.handleDropdown("forma", event)} value={this.state.credencial.forma} />
								{this.state.credencial.forma === "PROXIMIDADE" ? <Combobox label="Tecnologia" grid={2} appendTo={document.body} options={optionsTecnologias} onChange={(event) => this.handleDropdown("tecnologia", event)} value={this.state.credencial.tecnologia} /> : null}
								<TextField label="Código" type="numeric" grid={formasWiegand.includes(this.state.credencial.forma) ? 4 : 12} required name="codigo" onKeyUp={this.handleCodigo} onChange={this.handleChangeCodigo} value={this.state.credencial.forma === "SENHA" ? `${this.state.credencial.codigo.toString().substring(0, 3)}****` : this.state.credencial.codigo} />
								{formasWiegand.includes(this.state.credencial.forma) ? <TextField onKeyUp={this.handleWiegand} label={<label style={{fontSize: "12px"}}>Wiegand<span onClick={this.showWiegandHelp} style={{cursor: "pointer", textDecoration: "underline", fontSize: "12px", float: "right"}}>Clique aqui para instruções</span></label>} type="numeric" grid={4} name="_wiegand" onChange={this.handleChange} value={this.state.credencial._wiegand} /> : null}
								{formasWiegand.includes(this.state.credencial.forma) ? <TextField label={<label style={{fontSize: "12px"}}>Decimal<span onClick={this.showWiegandHelp} style={{cursor: "pointer", textDecoration: "underline", fontSize: "12px", float: "right"}}>Clique aqui para instruções</span></label>} type="numeric" grid={4} name="codigoPonto" value={this.state.credencial.codigoPonto} onChange={this.handleChange} /> : null}
								<MultiCombobox label="Opções" grid={6} defaultLabel="Nenhuma" onChange={(event) => this.handleDropdown("flags", event)} value={this.state.credencial.flags} options={this.flags} />
								<SelectVeiculo disabled={this.state.lockTitular || MCredencial.FormasIntransferiveis.includes(this.state.credencial.forma)}  label="Veículo" grid={6} onChange={this.handleChangeVeiculo} name="veiculo" value={this.state.credencial.veiculo}/>
								<DatePicker showTime showSeconds appendTo={document.body} label="Validade" grid={4} name="validade" onChange={this.handleChange} value={this.state.credencial.validade} />
								<SelectPessoaFisica grid={8} label="Titular" name="pessoa" disabled={this.state.credencial.veiculo?.proprietario?.id || this.state.lockTitular || (this.state.credencial.id && MCredencial.FormasIntransferiveis.includes(this.state.credencial.forma))} appendTo={document.body} value={this.state.credencial.pessoa} onChange={this.handleChange} />
							</div>
						</TabPanel>
						<TabPanel disabled={!this.detalhavel.includes(this.state.credencial.forma)} header="Detalhamento">
							<DataTable rows={10} paginator paginatorLeft={incluirItem} emptyMessage="Nenhuma configuração adicional" value={this.state.credencial.itens} children={this.detalhamento} />
						</TabPanel>
					</TabView>
				</DialogContent>
				<DialogFooter>
					{this.security.remove && this.state.credencial.id ? <Action mode="secondary" label="Histórico" style={{float: "left"}} icon="fa-clock" onClick={() => EntidadeNextGenService.listarHistorico("/credenciais", this.state.credencial)} /> : null}
					<Action mode="post" label="Salvar" disabled={!(this.security.edit || (this.security.create && !this.state.credencial.id)) || this.state.lockSave} onClick={this.saveCredencial} icon="fa-save" />
					<Action mode="secondary" label="Fechar" icon="fa-times" onClick={this.onClose} />
				</DialogFooter>
				{this.state.visible ? <Shortcut onCtrlS={this.saveCredencial} onEscape={this.onClose}/> : null}
			</Dialog>
		);
	}

}
