import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import iconeAcesso from "../../media/icones/acesso.png";
import iconeAnexo from "../../media/icones/anexo.png";
import iconeAuditoria from "../../media/icones/auditoria.png";
import iconeCopiar from "../../media/icones/copiar.png";
import iconeDispositivo from "../../media/icones/dispositivo.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeControlador from "../../media/icones/controladores.png";
import iconeEliminar from "../../media/icones/eliminar.png";
import iconeEmpresa from "../../media/icones/empresas.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeHistorico from "../../media/icones/historico.png";
import iconePonto from "../../media/icones/ponto.png";
import iconeLeitor from "../../media/icones/rele.png";
import iconeEtiqueta from "../../media/icones/etiquetas.png";
import {GUI} from "../../utils/Constants";
import {MCredencial} from "../../utils/models/MCredencial";
import {labelEquipamentoFlags, MDispositivo, MEquipamento, MLeitor} from "../../utils/models/MEquipamento";
import {UserData} from "../../utils/UserData";
import {Action} from "../commons/Action";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {Combobox} from "../commons/Combobox";
import {ModalRealm} from "../commons/ModalRealm";
import {PanelContent} from "../commons/PanelContent";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {TextField} from "../commons/TextField";
import {EntidadeNextGenService} from "../servicos/EntidadeNextGenService";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {EditarEquipamento} from "./EditarEquipamento";
import {WizardEquipamento} from "./WizardEquipamento";
import {SelectLugar} from "../../select/SelectLugar";
import {SelectPessoaJuridica} from "../../select/SelectPessoaJuridica";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {If} from "../../utils/If";

export class Equipamento extends React.Component {

	state = {
		descricao: "",
		ip: "",
		familia: null,
		categoria: "ACESSO",
		firstResult: 0,
		excluidos: false
	};

	equipamentoService = new EquipamentoService();
	security = UserData.security("EQP", true);

	listarEquipamentos = () => {
		const params = [];
		if (!this.state.excluidos) params.push("status=ATIVADO");
		if (this.state.categoria?.length) params.push(`categoria=${this.state.categoria}`);
		if (this.state.descricao?.length) params.push(`descricao=${this.state.descricao}`);
		if (this.state.ip?.length) params.push(`ip=${this.state.ip}`);
		if (this.state.serial?.length) params.push(`serial=${this.state.serial}`);
		if (this.state.lugar?.id) params.push(`lugar=${this.state.lugar.id}`);
		if (this.state.empresa?.id) params.push(`empresa=${this.state.empresa.id}`);
		if (this.state.etiquetas?.length) params.push(`etiquetas=${this.state.etiquetas.map(e => e.id).join(",")}`);
		if (this.state.familia?.length) params.push(`familia=${this.state.familia}`);
		this.equipamentoService.listarAlt(params.join("&")).then((equipamentos) => this.setState({equipamentos, firstResult: 0}));
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
	}

	editarEquipamento = (rowData) => {
		if (rowData != null && rowData.id != null) {
			this.equipamentoService.buscar(rowData).then((equipamento) => {
				ModalRealm.showDialog(<EditarEquipamento key={Math.random()} equipamento={equipamento} onModalClose={this.listarEquipamentos} />);
			});
		} else {
			ModalRealm.showDialog(<EditarEquipamento key={Math.random()} equipamento={rowData} onModalClose={this.listarEquipamentos} />);
		}
	}

	excluirEquipamento = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este equipamento?", () => this.equipamentoService.excluir(rowData).then(this.listarEquipamentos));
	}

	detalhes = [
		<Column style={{width: "8em"}} key="id" header="ID" body={(e) => <div className="ui-id">{e.id.toLocale()}</div>} sortable field="id" />,
		<Column key={0} header="Cat" style={{textAlign: "center", width: "4em"}} body={(e) => {
			switch (e.categoria) {
				case "ACESSO": return <img title="Acesso" className="inline-image" src={iconeAcesso} alt="" />;
				case "PONTO": return <img title="Ponto" className="inline-image" src={iconePonto} alt="" />;
				default: return null;
			}
		}} />,
		<Column key={1} field="descricao" sortable header="Informações" style={{width: "*"}} body={(equipamento) => {
			return (
				<div>
					<b>{equipamento.descricao}</b>
					<If condition={equipamento.flags?.length}>
						<div>
							<img src={iconeEtiqueta} className="inline-image" alt=""/> {equipamento.flags?.map(f => labelEquipamentoFlags[f]).join(", ")}
						</div>
					</If>
					{equipamento.mestre ? <div><img className="inline-image" src={iconeAnexo} alt="" /> Conectado a {equipamento.mestre.descricao}</div> : null}
					<div>{equipamento.leitores.map(l => l.auxiliares.map(a => <div><img title="Leitor Comum" className="inline-image" src={iconeLeitor} alt=""/> {`${MCredencial.MapFormas[l.forma]} na porta ${l.porta} aciona ${a.atuadores.map(t => MDispositivo.MapAtuadores[t]).join(", ")} para auxiliar ${a.auxiliar === -1 ? "global" : a.auxiliar}`} {l.flags && l.flags.length ? `(${l.flags.map(o => MLeitor.MapFlags[o]).join(", ")})` : null}</div>))}</div>
					<div>{equipamento.dispositivos.map(d => <div><img title="Dispositivo" className="inline-image" src={iconeDispositivo} alt="" /> {`${MDispositivo.MapAtuadores[d.atuador]} para ${d.destino ? d.destino.descricao : "fora"} (${MDispositivo.MapSensorizacoes[d.sensorizacao]}, ${d.acionamento}ms)`}</div>)}</div>
					<div>{equipamento.empresa ? <div><img className="inline-image" src={iconeEmpresa} alt="" /> {equipamento.empresa.nome}</div> : ""}</div>
					<div><img className="inline-image" src={iconeControlador} alt="" /> {equipamento.controlador?.descricao || "Sem controlador"}</div>
				</div>
			);
		}} />,
		<Column key={2} header="IP" style={{width: "10em", textAlign: "center"}} sortable field="ip" />,
		<Column key={3} sortable header="Número de Série" style={{width: "12em", textAlign: "center"}} field="serial" />,
		<Column key={4} field="familia" sortable header="Família" style={{width: "10em", textAlign: "center"}} body={(equipamento) => MEquipamento.MapFamilias[equipamento.familia]} />,
		<Column key={5} header="Ações" style={{width: "7em"}} body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Equipamento" onClick={() => this.editarEquipamento(rowData)} /> : null}
					{this.security.edit && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeCopiar} title="Clonar Equipamento" onClick={() => this.clonarEquipamento(rowData)} /> : null}
					{this.security.remove && rowData.status !== "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Equipamento" onClick={() => this.excluirEquipamento(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeAuditoria} title="Ver Histórico" onClick={() => this.visualizarHistorico(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeHistorico} title="Restaurar Equipamento" onClick={() => this.restaurarEquipamento(rowData)} /> : null}
					{this.security.remove && rowData.status === "EXCLUIDO" ? <img alt="" className="ui-action-icon" src={iconeEliminar} title="Eliminar Equipamento" onClick={() => this.eliminarEquipamento(rowData)} /> : null}
				</div>
			);
		}} />
	];

	clonarEquipamento = (equipamento) => {
		equipamento.id = null;
		if (/ \d$/.test(equipamento.descricao)) {
			const parts = equipamento.descricao.split(" ");
			equipamento.descricao = parts.slice(0, parts.length - 1).join(" ") + " " + (Number(parts[parts.length - 1]) + 1);
		}
		equipamento.ip = null;
		equipamento.serial = null;
		equipamento.chaveIntegracao = null;
		if (equipamento.leitores) {
			equipamento.leitores.forEach(l => {
				l.id = null;
				l.chaveIntegracao = null;
				if (l.auxiliares) {
					l.auxiliares.forEach(la => {
						la.id = null;
						la.chaveIntegracao = null;
					});
				}
			});
		}
		if (equipamento.dispositivos) {
			equipamento.dispositivos.forEach(d => {
				d.id = null;
				d.chaveIntegracao = null;
			});
		}
		this.editarEquipamento(equipamento);
	}

	visualizarHistorico = (entidade) => {
		EntidadeNextGenService.listarHistorico("/equipamentos", entidade);
	}

	wizardEquipamento = () => {
		ModalRealm.showDialog(<WizardEquipamento key={Math.random()} onModalClose={this.listarEquipamentos} />);
	}

	changePage = (event) => this.setState({firstResult: event.first});

	restaurarEquipamento = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja restaurar este equipamento?", () => {
			if (rowData != null && rowData.id != null) {
				this.equipamentoService.buscar(rowData).then((equipamento) => {
					equipamento.status = "ATIVADO";
					this.equipamentoService.salvar(equipamento).then(this.listarEquipamentos);
				});
			}
		});
	}

	eliminarEquipamento = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja eliminar permanentemente este equipamento?", () => this.equipamentoService.eliminar(rowData).then((result) => {
			if (result) this.listarEquipamentos(); else ModalRealm.showInformacao("info", ["Não foi possível eliminar este equipamento: há outros registros dependentes dele no sistema."]);
		}));
	}

	render() {
		return (
			<div>
				<Panel header="Equipamentos">
					<PanelContent>
						<Combobox label="Categoria" grid={2} value={this.state.categoria} options={MEquipamento.Categorias} onChange={this.handleUpdate} name="categoria" />
						<TextField grid={4} label="Descrição" autofocus name="descricao" value={this.state.descricao} onChange={this.handleUpdate} />
						<TextField label="IP" grid={3} name="ip" value={this.state.ip} onChange={this.handleUpdate} />
						<TextField label="Número de Série" grid={3} name="serial" value={this.state.serial} onChange={this.handleUpdate} />
						<SelectLugar grid={3} label="Lugar" name="lugar" value={this.state.lugar} onChange={this.handleUpdate} />
						<SelectPessoaJuridica grid={3} label="Empresa" name="empresa" value={this.state.empresa} onChange={this.handleUpdate} />
						<MultiSelectEtiqueta grid={4} label="Etiquetas" name="etiquetas" value={this.state.etiquetas} onSelect={this.handleUpdate} />
						<Combobox label="Família" grid={2} options={[{label: "Todas", value: null}, ...MEquipamento.Familias]} name="familia" value={this.state.familia} onChange={this.handleUpdate} />
					</PanelContent>
					<PanelFooter>
						{this.security.remove ? <CheckButton style={{float: "left", margin: "0 2px"}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={this.handleUpdate} /> : null}
						<Action label="Procurar" icon="fa-search" onClick={this.listarEquipamentos} />
					</PanelFooter>
				</Panel>
				<div className="ui-tzm-panel-separator" />
				<DataTable first={this.state.firstResult} onPage={this.changePage} emptyMessage="Nenhum registro encontrado" value={this.state.equipamentos} rows={GUI.defaultRows} rowsPerPageOptions={GUI.rowsPerPage} paginator children={this.detalhes} />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button mode="post" title="Novo Equipamento" icon="fa-plus" onClick={this.wizardEquipamento} /> : null}
				</ActionFloat>
				<Shortcut
					onEnter={this.listarEquipamentos}
					onCtrlN={() => window.location.pathname === "/equipamentos" && this.security.create && this.wizardEquipamento()}
					onCtrlE={() => window.location.pathname === "/equipamentos" && this.state.equipamentos.length === 1 && this.security.edit && this.editarEquipamento(this.state.equipamentos[0])}
				/>
				<ModalRealm />
			</div>
		);
	}

}
