import {EntidadeNextGenService} from "./EntidadeNextGenService";
import {httpPost} from "../../utils/Request";

export class UnidadePortalCondominoService extends EntidadeNextGenService {

	constructor() {
		super("/portal/unidades");
	}

	async ativarUnidade(unidades) {
		return await httpPost(`${this.basePath}/ativar`, unidades);
	}

    async desativarUnidade(unidade) {
        return await httpPost(`${this.basePath}/desativar`, unidade);
    }

}
