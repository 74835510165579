import {Panel} from "primereact/components/panel/Panel";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import iconePessoaDentro from "../../media/icones/pessoa-dentro.png";
import iconePessoaFora from "../../media/icones/pessoa-fora.png";
import React from "react";
import {MUnidade} from "../../utils/models/MUnidade";
import {httpGet} from "../../utils/Request";
import {StringUtils} from "../../utils/StringUtils";
import {UserData} from "../../utils/UserData";
import {ActionFloat} from "../commons/ActionFloat";
import {CheckButton} from "../commons/CheckButton";
import {ModalRealm} from "../commons/ModalRealm";
import {UnidadeService} from "../servicos/UnidadeService";
import {CriarCondominio} from "./CriarCondominio";
import {EditarUnidade} from "./EditarUnidade";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {Action} from "../commons/Action";
import {isDentro} from "../../utils/PessoaFisicaUtils";

export class Unidade extends React.Component {

	state = {
		agrupamentos: [],
		descricao: "",
		loaded: false,
		selectedTab: 0,
		contagens: [],
		presentes: {}
	};

	unidadeService = new UnidadeService();
	security = UserData.security("UNI", true);

	componentDidMount() {
		this.listarUnidades("");
	}
	
	listarUnidades = (descricao) => {
		let params =  Object.assign({});
		params.bloco = descricao;
		if (!this.state.excluidos) {
			params.status = "ATIVADO";
		}
		this.unidadeService.contagens().then((contagens) => {
			this.unidadeService.listarDTO(params).then((unidades) => {
				unidades.forEach(u => u.agrupamento = u.agrupamento || {id: 0, descricao: "Geral"});
				let agrupamentos = [];
				unidades.forEach(u => {
					if (!agrupamentos.map(a => a.id).includes(u.agrupamento.id)) agrupamentos.push(u.agrupamento);
				});
				agrupamentos.forEach(a => a.unidades = unidades.filter(u => u.agrupamento.id === a.id));
				this.setState({agrupamentos, descricao, loaded: true, contagens: contagens.sort((a, b) => a.categoria.descricao.localeCompare(b.categoria.descricao))});
				httpGet(`/monitoramentos/presenca/unidade/count`).then((logsPresencaUnidade) => {
					const presentes = this.state.presentes;
					logsPresencaUnidade.forEach(lpu => presentes[lpu.etiqueta.id] = lpu.contagem);
					this.setState({presentes});
				});
			});
		});
	}

	handleUpdate = (event) => {
		this.setState({[event.name]: event.value});
		this.listarUnidades("");
	}

	editarUnidade = (rowData) => {
		if (rowData.id) {
			this.unidadeService.buscar(rowData).then((unidade) => {
				ModalRealm.showDialog(<EditarUnidade key={Math.random()} unidade={unidade} onModalClose={() => this.listarUnidades("")} />);
			});
		} else {
			ModalRealm.showDialog(<EditarUnidade key={Math.random()} unidade={MUnidade.Modelo()} onModalClose={() => this.listarUnidades("")} />);
		}
	}

	excluirUnidade = (rowData) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir esta unidade?", () => this.unidadeService.excluir(rowData).then(() => this.listarUnidades("")));
	}

	showCriarCondominio = () => {
		ModalRealm.showDialog(<CriarCondominio key={Math.random()} onModalClose={() => this.listarUnidades("")} />);
	}

	render() {
		let abas = this.state.agrupamentos.map(a => {
			let blocos = [...new Set(a.unidades.map(u => u.bloco))].sort((a, b) => StringUtils.sortByLength(a, b));
			let icones = blocos.map((bloco) => {
				return <div key={bloco} className="ui-tzm-bloco-icone" onClick={() => {
					this.listarUnidades(bloco);
				}}>{bloco}</div>;
			});
			if (this.state.descricao.length > 0) {
				icones.push(<div key="empty" className="ui-tzm-bloco-icone ui-tzm-bloco-icone-clear" onClick={() => {
					this.listarUnidades("");
				}}><i className="fa fa-times" /></div>);
			}
			let unidades = blocos.map((bloco) => {
				return (
					<div key={Math.random()} className="ui-g">
						<div className="ui-g-1">
							{this.security.create ? <div className="ui-tzm-bloco-chip"><div className="ui-tzm-bloco-chip-add" onClick={() => {
								ModalRealm.showDialog(<EditarUnidade key={Math.random()} unidade={{...MUnidade.Modelo(), bloco}} onModalClose={() => {
									this.setState({maxOrdem: this.state.maxOrdem + 1});
									this.listarUnidades("");
								}} />);
							}}><i className="fa fa-plus" /></div>{bloco}</div> : null}
						</div>
						<div className="ui-g-11 ui-g-nopad">
							{
								a.unidades.filter(u => u.bloco === bloco).map((unidade) => {
									return (
										<div key={Math.random()} className="tzm-unidade-chip-container ui-g-2 ui-xl-2 ui-lg-3 ui-md-4 ui-sm-6">
											<UnidadeChip presentes={this.state.presentes} onClick={this.editarUnidade} unidade={unidade} contagens={this.state.contagens} />
										</div>
									);
								})
							}
						</div>
					</div>
				);
			});
			return (
				<TabPanel header={a.descricao} key={a.id}>
					<div style={{textAlign: "center"}}>{icones}</div>
					{unidades}
				</TabPanel>
			);
		});
		return (
			<div>
				<Panel header="Unidades">
					<div style={{padding: "4px 0 4px 10px", textAlign: "center"}}>
						{this.state.contagens.map((contagem) => <ContagemChip key={contagem.categoria.id} contagem={contagem} />)}
						{this.security.remove ? <CheckButton style={{float: "right", margin: 0}} name="excluidos" onIcon="fa-eye" offIcon="fa-eye-slash" checked={this.state.excluidos} onChange={(event) => {
							this.setState({excluidos: !this.state.excluidos}, () => {
								this.listarUnidades("");
							});
						}} /> : null}
					</div>
					{this.state.agrupamentos.length > 0 ? <TabView activeIndex={this.state.selectedTab} onTabChange={(event) => this.setState({selectedTab: event.index})}>{abas}</TabView> : this.state.loaded ? <div style={{textAlign: "center", padding: "1em"}}><Action label="Criar Unidades" onClick={this.showCriarCondominio} /></div> : <div style={{textAlign: "center"}}><i className="fa fa-spinner fa-spin" /></div>}
				</Panel>
				<div className="ui-tzm-floatbutton-margin" />
				<ActionFloat>
					{this.security.create ? <ActionFloat.Button onClick={this.editarUnidade} title="Nova Unidade" mode="post" icon="fa-plus" /> : null}
				</ActionFloat>
				<ModalRealm />
			</div>
		);
	}

	handleChange = (event) => this.setState({[event.name]: event.value});

}

class UnidadeChip extends React.Component {

	onClick = () => {
		if (this.props.onClick) {
			this.props.onClick(this.props.unidade);
		}
	}

	render() {
		const contagem = this.props.presentes[this.props.unidade.etiqueta.id];
		const agendaTip = `agenda-tooltip-${Math.round(Math.random() * 10000000)}`;
		return (
			<div className={`ui-tzm-unidade-chip ui-pattern-${this.props.unidade.etiqueta.padrao} ui-pattern-a${this.props.unidade.etiqueta.efeito}`} style={{opacity: (this.props.unidade.status === "ATIVADO" ? 1 : 0.5), color: this.props.unidade.etiqueta.fonte, backgroundColor: this.props.unidade.etiqueta.fundo}} onClick={this.onClick}>
				{this.props.unidade.contato ? <img id={agendaTip} className="unidade-contato" src={isDentro(this.props.unidade.contato) ? iconePessoaDentro : iconePessoaFora} alt="" /> : null}
				{this.props.unidade.contato ? <Tooltip for={`#${agendaTip}`} title={`${this.props.unidade.contato.nome}\nestá ${this.props.unidade.contato.lugar ? `em ${this.props.unidade.contato.lugar.descricao}` : "fora"}`} tooltipPosition="top" /> : null}
				<div className="ui-tzm-unidade-label">
					<span className="label">{`${this.props.unidade.etiqueta.descricao}`}</span>
					<span className="contagem">{contagem ? `${contagem} pessoas dentro` : <i className="fa fal fa-empty-set" style={{fontSize: "12px"}}/>}</span>
				</div>
				<div className="tzm-unidade-flag-container">{this.props.unidade.mudanca ?  
					<div key={this.props.unidade.id} className={`tzm-unidade-flag MUDANCA`}>{MUnidade.FlagMap["MUDANCA"]}</div>  : null}
				</div> 
				<div className="ui-tzm-catchip-container" style={this.props.unidade.contato ? {} : {marginLeft: 0}}><div className="ui-tzm-catchip-fake" />{this.props.contagens ? this.props.contagens.filter((c) => c.unidades.includes(this.props.unidade.id)).map((c) => <div key={c.categoria.id} className={`ui-tzm-catchip ui-pattern-${c.categoria.padrao} ui-pattern-a${c.categoria.efeito}`} title={c.categoria.descricao} style={{backgroundColor: c.categoria.fundo, color: c.categoria.fonte}}></div>) : null}</div>
			</div>
		);
	}

}

class ContagemChip extends React.Component {

	render() {
		return (
			<div className="tzm-contagem-chip">
				<b className={`ui-pattern-${this.props.contagem.categoria.padrao} ui-pattern-a${this.props.contagem.categoria.efeito}`} style={{backgroundColor: this.props.contagem.categoria.fundo, color: this.props.contagem.categoria.fonte}}>{this.props.contagem.categoria.descricao}</b>
				<i>{this.props.contagem.unidades.length}</i>
			</div>
		);
	}

}
