import moment from "moment";
import {Button} from "primereact/components/button/Button";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import React from "react";
import ResizeObserver from "react-resize-observer";
import iconeBloqueios from "../../media/icones/bloqueios.png";
import iconeCartao from "../../media/icones/cartao.png";
import iconeCredenciais from "../../media/icones/credenciais.png";
import iconeCriarSenha from "../../media/icones/criar-senha.png";
import iconeDesvincular from "../../media/icones/desvincular.png";
import iconeEditar from "../../media/icones/editar.png";
import iconeEmpresa from "../../media/icones/empresas.png";
import iconeExcluir from "../../media/icones/excluir.png";
import iconeLancarEntrada from "../../media/icones/lancar-entrada.png";
import iconeLancarSaida from "../../media/icones/lancar-saida.png";
import iconePessoaDentro from "../../media/icones/pessoa-dentro.png";
import iconePessoaFora from "../../media/icones/pessoa-fora.png";
import iconePessoa from "../../media/icones/pessoas.png";
import iconePortaria from "../../media/icones/portaria.png";
import iconeRemover from "../../media/icones/remover.png";
import iconeTelefone from "../../media/icones/telefone.png";
import iconeUnidade from "../../media/icones/unidades.png";
import iconeEtiqueta from "../../media/icones/etiquetas.png";
import iconeVeiculo from "../../media/icones/veiculos.png";
import iconeMotorista from "../../media/icones/motorista.png";
import iconeVisitado from "../../media/icones/visitados.png";
import iconeEstrela from "../../media/icones/favoritos.png";
import {AppletUtils} from "../../utils/AppletUtils";
import {ArrayUtils} from "../../utils/ArrayUtils";
import {LocalDate, LocalDateTime} from "../../utils/DateUtils";
import "../../utils/Format";
import {colorStatusAgenda, MAgenda} from "../../utils/models/MAgenda";
import {MCredencial} from "../../utils/models/MCredencial";
import {MPessoaFisica} from "../../utils/models/MPessoaFisica";
import {MUnidade} from "../../utils/models/MUnidade";
import {iconVehicle, MVeiculo} from "../../utils/models/MVeiculo";
import {NumberUtils} from "../../utils/NumberUtils";
import {byDescricao, StringUtils, toSrcUltimaFoto} from "../../utils/StringUtils";
import {UserData} from "../../utils/UserData";
import {Validator} from "../../utils/Validator";
import {EditarAgenda} from "../Agenda/EditarAgenda";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {Label} from "../commons/Label";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {PainelAcesso} from "../commons/PainelAcesso";
import {PainelOcorrencia} from "../commons/PainelOcorrencia";
import {PanelFooter} from "../commons/PanelFooter";
import {Shortcut} from "../commons/Shortcut";
import {SuggestionBox} from "../commons/SuggestionBox";
import {TextField} from "../commons/TextField";
import {CriarSenha} from "../Credencial/CriarSenha";
import {EtiquetaChip} from "../Etiqueta/EtiquetaChip";
import {EditarPessoaFisica} from "../PessoaFisica/EditarPessoaFisica";
import {AgendaService} from "../servicos/AgendaService";
import {AvisoService} from "../servicos/AvisoService";
import {CredencialService} from "../servicos/CredencialService";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {LogAcessoService} from "../servicos/LogAcessoService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {PortariaService} from "../servicos/PortariaService";
import {SetupCondominioService} from "../servicos/SetupCondominioService";
import {UnidadeService} from "../servicos/UnidadeService";
import {EditarUnidade} from "../Unidade/EditarUnidade";
import {EditarVeiculo} from "../Veiculo/EditarVeiculo";
import {PlacaMercosul} from "../Veiculo/PlacaMercosul";
import {EditarCredencialTemporaria} from "./EditarCredencialTemporaria";
import {OpcoesPortaria} from "./OpcoesPortaria";
import {SafetyWebSocket} from "../../utils/WebSocket";
import {SudoUser} from "../SudoUser/SudoUser";
import {EditarEquipamento} from "../Equipamento/EditarEquipamento";
import {MDispositivo, MEquipamento} from "../../utils/models/MEquipamento";
import {SetupSistemaService} from "../servicos/SetupSistemaService";
import "../../auxiliar.css";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectCredencial} from "../../select/SelectCredencial";
import {httpGet, httpPost} from "../../utils/Request";
import {isDentro, isDentroOuGaragem} from "../../utils/PessoaFisicaUtils";
import {EditarPessoaJuridica} from "../PessoaJuridica/EditarPessoaJuridica";
import {SelecionarVeiculo} from "./SelecionarVeiculo";
import {VeiculoService} from "../servicos/VeiculoService";
import {SelecionarAviso} from "./SelecionarAviso";
import {FacialDialog} from "../commons/FacialDialog";
import {PreencherFormulario} from "../Formulario/PreencherFormulario";
import {uuidv4} from "../../utils/UUIDUtils";
import {
	byIsInThenDescricao,
	byPrincipalThenNome,
	byVeiculoTipo,
	hasFlagUnidade,
	hasNotFlagUnidade
} from "../../utils/SortUtils";
import {ChipAviso} from "../commons/ChipAviso";
import {ImpressaoEtiqueta} from "./ImpressaoEtiqueta";
import {SelectButton} from "../commons/SelectButton";
import {PessoaJuridicaService} from "../servicos/PessoaJuridicaService";
import {If} from "../../utils/If";

const rowsPerPageOptions = [
	{label: "10", value: 10},
	{label: "20", value: 20},
	{label: "50", value: 50}
];

export class Portaria extends React.Component {

	constructor() {
		super();
		let habilitarBlocosConsulta = window.localStorage.getItem("safety-portaria-habilitarBlocosConsulta");
		habilitarBlocosConsulta = habilitarBlocosConsulta != null ? habilitarBlocosConsulta === "true" : true;
		let mostrarAcoesPessoaVisita = window.localStorage.getItem("safety-portaria-mostrarAcoesPessoaVisita");
		mostrarAcoesPessoaVisita = mostrarAcoesPessoaVisita != null ? mostrarAcoesPessoaVisita === "true" : false;
		let nomesEmMaiuscula = window.localStorage.getItem("safety-portaria-nomesEmMaiuscula");
		nomesEmMaiuscula = nomesEmMaiuscula != null ? nomesEmMaiuscula === "true" : true;
		this.state = {
			agendas: [],
			agenda: MAgenda.Modelo(),
			portarias: [],
			setores: [],
			equipamento: {},
			data: LocalDate.now(),
			pesquisa: "",
			selectedTab: 0,
			leftPanelWidth: 0,
			categorias: [],
			modelosAgenda: [],
			categoria: null,
			fancyVisible: true,
			setupCondominio: {
				expiracoes: [],
				textos: []
			},
			setupSistema: {
				flags: []
			},
			permitirDocumento: true,
			habilitarBlocosConsulta,
			mostrarAcoesPessoaVisita,
			nomesEmMaiuscula,
			lock: false,
			lockAgendas: [],
			lockSave: false,
			page: 0,
			agrupamento: window.localStorage.getItem("X-Portaria-Agrupamento") == null || Number(window.localStorage.getItem("X-Portaria-Agrupamento")) === 1,
			size: Number(window.localStorage.getItem("X-Portaria-RowsPerPage") || "10"),
			lockList: false,
			equipamentosFaciais: [],
			status: "ATIVADO",
			modelosAdicionais: [],
			adicionais: [],
			formularios: []
		};
		this.portariaService = new PortariaService();
		this.pessoaFisicaService = new PessoaFisicaService();
		this.pessoaJuridicaService = new PessoaJuridicaService();
		this.credencialService = new CredencialService();
		this.agendaService = new AgendaService();
		this.logAcessoService = new LogAcessoService();
		this.etiquetaService = new EtiquetaService();
		this.equipamentoService = new EquipamentoService();
		this.unidadeService = new UnidadeService();
		this.setupCondominioService = new SetupCondominioService();
		this.setupSistemaService = new SetupSistemaService();
		this.avisoService = new AvisoService();
		this.veiculoService = new VeiculoService();
		this.security = UserData.security("POR", true);
		this.securityUnidade = UserData.security("UNI");
		this.securityAgenda = UserData.security("SCH");
		this.securityPessoa = UserData.security("PES");
		this.securityEmpresa = UserData.security("EMP");
		this.securityAviso = UserData.security("AVI");
		this.securityCredencial = UserData.security("CRD");
		this.securityVeiculo = UserData.security("VEI");
	}

	printClickToFill = (mado, entidade, resend = false) => {
		return (
			<> (<i className="tzm-clickable-link" onClick={e => this.preencherFormulario(e, mado.tipo, entidade)}>{resend ? "Reiniciar" : "Preencher"}</i>)</>
		);
	}

	preencherFormulario = (e, tipo, entidade) => {
		e.stopPropagation();
		const id = uuidv4();
		ModalRealm.showDialog(<PreencherFormulario requisicao={{id, tipo, entidade}} onModalClose={() => {
			const {formularios} = this.state;
			formularios.push({id, entidade});
			const {agenda} = this.state;
			entidade.documentos = entidade.documentos.filter(e => e.tipo.id !== tipo.id || !e.pendente);
			entidade.documentos.push({_key: id, tipo, pendente: true});
			this.setState({agenda, formularios});
		}}/>);
	}

	printMissingDocs = (tipo, entidade) => {
		const documentosObrigatorios = [];
		if (entidade?.documentos) {
			entidade.documentos.forEach(vd => {
				if (moment().startOf("day").isAfter(vd.validade)) {
					documentosObrigatorios.push(
						<div style={{color: "#F44336"}}>
							<i className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> Documento {vd.tipo?.descricao} com validade expirada!
							{vd.tipo?.formulario?.id ? this.printClickToFill(vd.tipo, entidade) : null}
						</div>
					);
				}
				if (vd.pendente) {
					documentosObrigatorios.push(
						<div style={{color: "#666666"}}>
							<i className="fad fa-spinner fa-spin" style={{color: "#666666"}}/> Documento {vd.tipo?.descricao} sendo preenchido...
							{vd.tipo?.formulario?.id ? this.printClickToFill(vd.tipo, entidade, true) : null}
						</div>
					);
				}
			});
		}
		if (this.state.modeloAgenda?.modelosAcesso) {
			this.state.modeloAgenda.modelosAcesso.forEach(ma => {
				ma.documentosObrigatorios.filter(mado => mado.tipo.aplicacao === tipo).forEach(mado => {
					if (!entidade.documentos?.some(vd => vd.tipo?.id === mado.tipo.id)) {
						documentosObrigatorios.push(
							<div key={mado.id} style={{color: "#F44336"}}>
								<i className="fad fa-exclamation-triangle"/> Falta documento obrigatório {mado.tipo.descricao}!
								{mado.tipo.formulario?.id ? this.printClickToFill(mado, entidade) : null}
							</div>
						);
					}
				});
			});
		}
		return documentosObrigatorios;
	}

	refreshAgendas = () => {
		const agendas = this.state.agendas;
		this.setState({agendas});
		setTimeout(this.refreshAgendas, 60 * 1000);
	}

	componentDidMount() {
		this.setupSistemaService.buscar().then(setupSistema => this.setState({setupSistema}));
		this.setupCondominioService.buscar().then(setupCondominio => {
			if (setupCondominio?.categoriasPortaria) {
				let categoria = null;
				let ultimaCategoria = window.localStorage.getItem("safety-categoria-last");
				if (ultimaCategoria) {
					categoria = setupCondominio.categoriasPortaria.filter((c) => c.id === Number(ultimaCategoria));
					if (categoria && categoria.length) {
						categoria = categoria[0];
					} else {
						categoria = null;
					}
				}
				this.setState({
					setupCondominio,
					categoria,
					categorias: [
						{label: "Nenhuma", value: null},
						...setupCondominio.categoriasPortaria.sort(byDescricao).map(c => ({label: c.descricao, value: c}))
					]
				});
				this.portariaService.listar(`search=descricao~`, {page: 0, size: 10}).then(portarias => {
					const etiquetasOperador = UserData.operador.etiquetas;
					if (etiquetasOperador?.length && etiquetasOperador.some(e => e.flags.includes("PORTARIA"))) {
						const ids = etiquetasOperador.filter(e => e.flags.includes("PORTARIA")).map(e => e.id);
						portarias = portarias.filter(p => p.etiquetas?.some(e => ids.includes(e.id)));
					}
					if (portarias.length) {
						let ultimaPortaria = window.localStorage.getItem("safety-portaria-last");
						let portaria;
						if (ultimaPortaria) {
							portarias.filter(p => p.id === Number(ultimaPortaria)).forEach(p => {
								portaria = p;
							});
						}
						if (!portaria) {
							portaria = portarias[0];
						}
						this.setState({portaria, portarias, setores: portaria.setores}, () => {
							this.switchPortaria({value: portaria}, this.listarVisitas);
						});
					}
				});
			}
			if (setupCondominio?.categoriasFacialPortaria) {
				httpGet("/portaria/equipamentos-facial").then(equipamentosFaciais => {
					this.setState({equipamentosFaciais});
				});
			}
		});
		const seconds = 60 - new Date().getSeconds();
		setTimeout(this.refreshAgendas, seconds * 1000);
		this.setState({
			painelExpanded: window.localStorage.getItem("safety-painelacesso") === "true",
			painelMute: window.localStorage.getItem("safety-painelacesso-mute") === "true"
		});
	}

	cadastrarDigital = (rowData) => {
		AppletUtils.downloadFingerprint(rowData);
	}

	criarSenha = (rowData) => {
		ModalRealm.showDialog(<CriarSenha key={Math.random()} pessoa={rowData} />);
	}

	toggleModoEmergencia = () => {
		ModalRealm.showConfirmacao(`Tem certeza de que deseja ${this.state.portaria?.status === "EMERGENCIA" ? "des" : "h"}abilitar o modo de emergência?`, () => {
			const {portaria} = this.state;
			portaria.status = portaria.status === "OPERANTE" ? "EMERGENCIA" : "OPERANTE";
			this.portariaService.salvar(portaria).then(portaria => {
				this.setState({portaria});
			});
		});
	}

	listarEntidades = (event) => {
		let query = event.query.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9 +$@#*-]/g, "");
		const visitados = event.query.startsWith("#");
		if (this.state.nomesEmMaiuscula) query = query.toUpperCase();
		if (query.length) {
			if (/(\+[0-9A-Fa-f]+)/g.test(query)) {
				let value = NumberUtils.toLittleEndian(/(\+[0-9A-Fa-f]+)/g.exec(query)[0]);
				if (value.length > 4) query = query.replace(/(\+[0-9A-Fa-f]+)/g, "+" + value);
			}
			this.portariaService.listarEntidades(`search=${query.replace("+", "%2B").replace("#", "%23")}`, {page: 0, size: 10}).then((criterio) => {
				if (criterio.pessoas.length > 0) {
					if (criterio.credencial != null) {
						criterio.pessoas.forEach((pessoa) => pessoa.credencial = criterio.credencial);
					}
					if (criterio.veiculo != null) {
						criterio.pessoas.forEach((pessoa) => pessoa._veiculo = criterio.veiculo);
					} else if (StringUtils.hasPlacaMercosul(event.query)) {
						let placa = StringUtils.getPlacaMercosul(event.query);
						if (placa.length > 0) {
							let veiculo = {placa};
							criterio.pessoas.forEach(pessoa => pessoa._veiculo = veiculo);
						}
					}
					if (criterio.unidades != null && criterio.unidades.length === 1) {
						criterio.pessoas.forEach((pessoa) => pessoa.unidade = criterio.unidades[0]);
					}
					if (criterio.visitado != null) {
						criterio.pessoas.forEach((pessoa) => pessoa.visitado = criterio.visitado);
					}
					if (criterio.empresa) {
						criterio.pessoas.forEach(pessoa => pessoa._empresa = criterio.empresa);
					}
					this.setState({entidades: criterio.pessoas});
				} else if (visitados && criterio.visitados && criterio.visitados.length) {
					const entidades = criterio.visitados.map(v => ({...v, _visitado: true}));
					this.setState({entidades});
				} else {
					let entidades = [];
					let value = query.split(/[!+$@#*-]/g)[0];
					if (this.securityPessoa.create && value.length > 0) {
						entidades.push({...MPessoaFisica.Modelo(), id: -1, nome: `Criar pessoa ${value}`});
					} else {
						entidades.push({...MPessoaFisica.Modelo(), id: -2});
					}
					if (criterio.credencial != null) {
						entidades.forEach((pessoa) => pessoa.credencial = criterio.credencial);
					}
					if (criterio.veiculo != null) {
						entidades.forEach((pessoa) => pessoa._veiculo = criterio.veiculo);
					} else if (StringUtils.hasPlacaMercosul(event.query)) {
						let placa = StringUtils.getPlacaMercosul(event.query);
						if (placa.length > 0) {
							let veiculo = {placa};
							entidades.forEach(pessoa => pessoa._veiculo = veiculo);
						}
					}
					if (criterio.visitado != null) {
						entidades.forEach((pessoa) => pessoa.visitado = criterio.visitado);
					}
					if (entidades.length === 1 && entidades[0].id === -1) {
						if (criterio.unidades != null && criterio.unidades.length === 1) {
							entidades[0].unidade = criterio.unidades[0];
						}
					} else {
						if (criterio.unidades != null && criterio.unidades.length > 0) {
							entidades = criterio.unidades.map((unidade) => {
								let pessoa = Object.assign({}, unidade.contato);
								pessoa.id = -2;
								pessoa.cpf = pessoa.rg = pessoa.passaporte = null;
								pessoa.unidade = unidade;
								return pessoa;
							});
						}
					}
					if (criterio.empresa) {
						entidades.forEach(pessoa => pessoa._empresa = criterio.empresa);
					}
					this.setState({entidades});
				}
			});
		}
	}

	lancarAcesso = (criterio) => {
		const agenda = criterio.agenda;
		criterio.equipamento = this.state.equipamento;
		if (Validator.isEntidade(criterio.equipamento)) {
			if (criterio.pessoa.lugar != null && criterio.pessoa.lugar.tipo !== "EXTERNO") criterio.destino = null; else criterio.destino = this.state.destino != null && this.state.destino.id !== -1 ? this.state.destino : null;
			if ((criterio.pessoa.lugar == null || criterio.pessoa.lugar.tipo === "EXTERNO") && (this.state.destino == null || this.state.destino.tipo === "EXTERNO" || this.state.destino.id === -1)) {
				if (this.state.equipamento != null && this.state.equipamento !== "" && this.state.equipamento.dispositivos != null && this.state.equipamento.dispositivos.length > 0) {
					let destinos = this.state.equipamento.dispositivos.map(d => d.destino).filter(d => d != null && d.tipo !== "EXTERNO");
					if (destinos.length === 1) {
						criterio.destino = destinos[0];
						ModalRealm.showConfirmacao(`Tem certeza de que deseja lançar a entrada de ${criterio.pessoa.nome} em ${criterio.destino.descricao}?`, () => {
							this.portariaService.lancarAcesso(criterio).then(() => this.updateAgenda(agenda));
						});
					} else {
						ModalRealm.showInformacao("warn", ["Você precisa selecionar uma opção para o acesso manual para entrada"]);
					}
				} else {
					ModalRealm.showInformacao("warn", ["Este equipamento não tem opção de liberação de entrada disponível"]);
				}
			} else {
				if (criterio.destino != null) {
					ModalRealm.showConfirmacao(`Tem certeza de que deseja lançar a entrada de ${criterio.pessoa.nome} em ${criterio.destino.descricao}?`, () => {
						this.portariaService.lancarAcesso(criterio).then(() => this.updateAgenda(agenda));
					});
				} else {
					ModalRealm.showConfirmacao(`Tem certeza de que deseja lançar a saída de ${criterio.pessoa.nome}?`, () => {
						this.portariaService.lancarAcesso(criterio).then(() => this.updateAgenda(agenda));
					});
				}
			}
		} else {
			ModalRealm.showInformacao("warn", ["Este equipamento não tem opção de liberação de acesso disponível"]);
		}
	}

	updateAgenda = (agenda) => {
		this.agendaService.buscar(agenda).then((agenda) => {
			let agendas = this.state.agendas;
			if (this.state.status && this.state.status !== agenda.status) {
				agendas = agendas.filter((a) => a.id !== agenda.id);
			} else {
				agendas[agendas.findIndex((a) => a.id === agenda.id)] = agenda;
			}
			this.setState({agendas});
			this.buscarCredencial(agenda);
		});
	}

	lancarSaidaTotal = (agenda, index) => {
		index = index === undefined ? 0 : index;
		const pessoas = agenda.pessoas;
		const criterio = {equipamento: this.state.equipamento};
		if (Validator.isEntidade(criterio.equipamento)) {
			criterio.destino = null;
			criterio.etiquetas = agenda.etiquetas;
			criterio.pessoa = pessoas[index];
			if (index < pessoas.length) {
				if (pessoas[index].lugar) {
					this.portariaService.lancarAcesso(criterio).then(() => this.lancarSaidaTotal(agenda, ++index));
				} else {
					this.lancarSaidaTotal(agenda, ++index);
				}
			} else {
				this.listarVisitas();
			}
		} else {
			ModalRealm.showInformacao("warn", ["Este equipamento não tem opção de liberação de acesso disponível"]);
		}
	}

	gerenciarCredenciais = (pessoa) => {
		this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
			ModalRealm.showDialog(<EditarPessoaFisica activeTab={3} params={{flags: "TEMPORARIA"}} key={Math.random()} pessoa={pessoa} onModalClose={() => this.listarVisitas()} />);
		});
	}

	gerenciarCredenciaisTemporarias = (credencial) => {
		this.credencialService.buscar(credencial).then((credencial) => {
			ModalRealm.showDialog(<EditarCredencialTemporaria credencial={credencial} onModalClose={() => this.listarVisitas()} />);
		});
	}

	toggleAgenda = agenda => {
		agenda.status = agenda.status === "ATIVADO" ? "PENDENTE" : "ATIVADO";
		httpPost(`/agendas/status?id=${agenda.id}&status=${agenda.status}`).then(() => {
			const {agendas} = this.state;
			agendas.filter(a => a.id === agenda.id).forEach(a => a.status = agenda.status);
			this.setState({agendas});
		});
	}

	editarEmpresa = empresa => {
		httpGet(`/pessoas-juridicas/${empresa.id}`).then(empresa => {
			ModalRealm.showDialog(<EditarPessoaJuridica pessoa={empresa} onModalClose={empresa => {
				const {agendas} = this.state;
				for (const agenda of agendas) {
					for (const pessoa of agenda.pessoas) {
						if (pessoa.empresa?.id === empresa.id) {
							pessoa.empresa = empresa;
						}
					}
				}
				this.setState({agendas});
			}}/>);
		});
	}

	imprimirEtiqueta = agenda => {
		ModalRealm.showDialog(<ImpressaoEtiqueta agenda={agenda}/>);
	}

	detalhesVisitas = [
		<Column key="1" style={{width: "*"}} header="Informações" body={(rowData, column) => {
			let status = 0;
			if (rowData.validade && moment().isAfter(moment(rowData.validade))) {
				if (rowData.pessoas.some((p) => p.lugar)) {
					status = 1;
				} else {
					status = 2;
				}
			}
			const hasVehicle = rowData.veiculos?.length;
			let itens = rowData.pessoas.sort((a, b) => byPrincipalThenNome(rowData, a, b)).map((pessoa) => {
				const isPrincipal = rowData.principalPessoa === pessoa.id;
				let id = `${pessoa.id}_vs${column.rowIndex}`;
				return (
					<div key={id} style={{position: "relative"}}>
						<div className="ui-portaria-item-pessoa visitante" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
							<img className="ui-portaria-icone-posicao" src={isDentro(pessoa) ? iconePessoaDentro : iconePessoaFora} alt="" />
							<i style={{marginRight: ".25em", color: isPrincipal ? hasVehicle ? "#1565C0" : "#FFA000" : "transparent"}} className={hasVehicle ? "fad fa-steering-wheel" : "fad fa-star"} title={isPrincipal ? hasVehicle ? "Motorista" : "Visitante Principal" : null}/>
							{pessoa.flags && pessoa.flags.includes("ADMINISTRADOR") ? <span><i title="Administrador" className="fad fa-user-shield" style={{color: "#4CAF50"}} /> </span> : null}
							{pessoa.nascimento && pessoa.nascimento.endsWith(moment().format("MM-DD")) ? <span><i title="Aniversariante" className="fad fa-birthday-cake" style={{color: "#EC407A"}} /> </span> : null}
							{pessoa.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}} /> </span>)}
							{StringUtils.simplificarNome(pessoa.nome)}
							<br />
							{pessoa.credenciais != null ? (
								<div style={{right: this.state.mostrarAcoesPessoaVisita || this.state.vcard === id ? "140px" : "0"}} className="ui-portaria-pessoa-credencial-container">
									{pessoa.credenciais.length > 1 ? (
										<span>
											<div className="ui-portaria-pessoa-credencial" onClick={() => this.gerenciarCredenciaisTemporarias(pessoa.credenciais[0])}>{pessoa.credenciais[0].descricao}</div>
											<div className="ui-portaria-pessoa-credencial" onClick={() => this.gerenciarCredenciais(pessoa)}>+{pessoa.credenciais.length - 1}</div>
										</span>
									) : (
										pessoa.credenciais.map(c => <div key={c.id} className="ui-portaria-pessoa-credencial" onClick={() => this.gerenciarCredenciaisTemporarias(pessoa.credenciais[0])}>{c.descricao}</div>
										))
									}
								</div>
							) : null}
							{this.state.mostrarAcoesPessoaVisita || this.state.vcard === id ? (
								<div className="ui-portaria-pessoa-acao-container">
									{this.securityPessoa.edit ? <img title="Editar Pessoa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarPessoa(pessoa)} /> : null}
									{this.securityCredencial.create ? <img title="Cadastrar Senha" className="ui-portaria-pessoa-acao" src={iconeCriarSenha} alt="" onClick={() => this.criarSenha(pessoa)} /> : null}
									{this.securityCredencial.edit ? <img title="Gerenciar Credenciais" className="ui-portaria-pessoa-acao" src={iconeCartao} alt="" onClick={() => this.gerenciarCredenciais(pessoa)} /> : null}
									{this.securityCredencial.create ? <img title="Cadastrar Digital" className="ui-portaria-pessoa-acao" src={iconeCredenciais} alt="" onClick={() => this.cadastrarDigital(pessoa)} /> : null}
									<img title={pessoa.lugar && pessoa.lugar.tipo !== "EXTERNO" ? "Lançar Saída" : "Lançar Entrada"} className="ui-portaria-pessoa-acao" src={pessoa.lugar && pessoa.lugar.tipo !== "EXTERNO" ? iconeLancarSaida : iconeLancarEntrada} alt="" onClick={() => this.lancarAcesso({agenda: rowData, pessoa, etiquetas: rowData.etiquetas})} />
								</div>
							) : null}
						</div>
						<VCard pessoa={pessoa} active={this.state.vcard === id} />
					</div>
				);
			});
			if (rowData.empresa?.id) {
				const id = `${column.rowIndex}_age${rowData.empresa.id}`;
				itens.unshift(
					<div key={id} style={{position: "relative"}}>
						<div key={id} className="ui-portaria-item-pessoa empresa" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
							<img className="ui-portaria-icone-posicao" style={{marginTop: "0"}} src={iconeEmpresa} alt="" />
							{rowData.empresa.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
							<span title={`CNPJ: ${rowData.empresa.cnpj?.formatCnpj()} - Telefone: ${rowData.empresa.telefone}`}>{rowData.empresa.nome}</span>
							{
								rowData.empresa.telefone?.length || rowData.empresa.cnpj?.length ? (
									<div className="fone-empresa">
										{rowData.empresa.cnpj ? <span>{rowData.empresa.cnpj.formatCnpj()}</span> : null}
										{rowData.empresa.telefone ? <span><i className="fa fa-phone-rotary"/>{rowData.empresa.telefone}</span> : null}
									</div>
								) : null
							}
							<div className="ui-portaria-pessoa-acao-container">
								{this.securityEmpresa.edit ? <img title="Editar Empresa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarEmpresa(rowData.empresa)} /> : null}
							</div>
						</div>
					</div>
				);
			}
			if (rowData.pessoas.some(p => p.empresa)) {
				const es = rowData.pessoas.filter((p) => p.empresa).map((p) => p.empresa).filter((e, i, a) => a.map((e) => e.id).indexOf(e.id) === i);
				itens.unshift(...es.map((e) => {
					const id = `${e.nome}_ve${column.rowIndex}`;
					return (
						<div key={id} style={{position: "relative"}}>
							<div key={e.id} className="ui-portaria-item-pessoa empresa" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
								<img className="ui-portaria-icone-posicao" style={{marginTop: "0"}} src={iconeEmpresa} alt=""/>
								{e.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
								<span title={`CNPJ: ${e.cnpj?.formatCnpj()} - Telefone: ${e.telefone}`}>{e.nome}</span>
								{
									e.telefone?.length || e.cnpj?.length ? (
										<div className="fone-empresa">
											{e.cnpj ? <span>{e.cnpj.formatCnpj()}</span> : null}
											{e.telefone ? <span><i className="fa fa-phone-rotary"/>{e.telefone}</span> : null}
										</div>
									) : null
								}
								{
									!(this.state.mostrarAcoesPessoaVisita || this.state.vcard === id) ? null : (
										<div className="ui-portaria-pessoa-acao-container">
											{this.securityEmpresa.edit ? <img title="Editar Empresa" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarEmpresa(e)} /> : null}
										</div>
									)
								}
							</div>
						</div>
					);
				}));
			}
			itens.unshift(
				<div key={`s_${rowData.id}`} style={{margin: "-2px -2px 2px -2px"}}>
					<div onClick={() => this.toggleAgenda(rowData)} className="ui-tzm-minchip" style={{backgroundColor: rowData.status === "ATIVADO" ? "" : "#F44336", cursor: "pointer", color: rowData.status === "ATIVADO" ? "#222222" : "#FFFFFF", width: "32px", textAlign: "center"}}>
						<i title={rowData.status === "ATIVADO" ? "Bloquear Agenda" : "Liberar Agenda"} style={{fontSize: "11px"}} className={`fa fa-${rowData.status === "ATIVADO" ? "lock-open" : "lock"}`}/>
					</div>
					<div title="Data da Criação da Agenda" className="ui-tzm-minchip" style={{color: "#222222"}}>
						<i title="Data do Registro" style={{fontSize: "9px", marginRight: "6px", transform: "translateY(-1px)"}} className="fa fa-calendar-alt"/>
						{moment(rowData.registro).format("DD/MM/YY HH:mm")}
					</div>
					{this.state.status !== "ATIVADO" ? <div className="ui-tzm-minchip" style={{minWidth: "70px", textAlign: "center", backgroundColor: colorStatusAgenda(rowData)}}>{MAgenda.MapStatus[rowData.status]}</div> : null}
					<div className={`ui-tzm-minchip ${status === 1 ? " agenda-expirada" : status === 2 ? " agenda-encerrada" : " agenda-valida"}`}>
						{
							status === 2
								? "Atividade Encerrada"
								: rowData.validade != null ? `Validade: ${moment(rowData.validade).format("DD/MM/YY HH:mm")}` : "Validade Indeterminada"}
						{
							rowData.flags.includes("TEMPORARIA")
								? <div className="portaria-temporaria" title="Temporária"><i className="fal fa-clock"/></div>
								: <div/>
						}
						{
							rowData.flags.includes("COLETOR_LIVRE")
								? <div className="portaria-temporaria" title="Coletor Livre"><i className="fal fa-arrow-up"/></div>
								: <div/>
						}
						<If condition={UserData.hasRole("XX2E")}>
							<div className="portaria-temporaria">
								{MAgenda.IconOperacao[rowData.operacao]} {MAgenda.LabelOperacao[rowData.operacao]}
							</div>
						</If>
					</div>
					<div>
						{rowData.etiquetas.filter(hasNotFlagUnidade).sort((a, b) => byIsInThenDescricao(this.state.portaria?.etiquetas, a, b)).map(e => <EtiquetaChip key={e.id} etiqueta={e}/>)}
						{rowData.etiquetas.filter(hasFlagUnidade).sort((a, b) => byIsInThenDescricao(this.state.portaria?.etiquetas, a, b)).map(e => <EtiquetaChip key={e.id} etiqueta={e}/>)}
					</div>
				</div>
			);
			if (rowData.veiculos) {
				rowData.veiculos.sort(byVeiculoTipo).forEach(veiculo => {
					const id = `${veiculo.placa}_vc${column.rowIndex}`;
					itens.push(
						<div key={id} style={{position: "relative"}}>
							<div className="ui-portaria-item-pessoa veiculo" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
								{iconVehicle(veiculo)}
								{veiculo.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> </span>)}
								{MVeiculo.print(veiculo)}
								{this.state.mostrarAcoesPessoaVisita || this.state.vcard === id ? (
									<div className="ui-portaria-pessoa-acao-container">
										{this.securityVeiculo.edit ? <img title="Editar Veículo" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarVeiculoVisita(veiculo)} /> : null}
										{
											veiculo.tipo !== "CARRETA" && rowData.pessoas.some((p) => p.lugar) ? (
												<img title="Lançar Saída de Todos" className="ui-portaria-pessoa-acao" src={iconeLancarSaida} alt="" onClick={() => {
													ModalRealm.showConfirmacao("Tem certeza de que deseja confirmar a saída de todos os membros desta agenda?", () => {
														this.lancarSaidaTotal(rowData);
													});
												}} />
											) : null
										}
									</div>
								) : null}
							</div>
						</div>
					);
				});
			}
			if (rowData._veiculo != null) {
				const id = `${rowData._veiculo.placa}_vc${column.rowIndex}`;
				itens.push(
					<div key={id} style={{position: "relative"}}>
						<div className="ui-portaria-item-pessoa veiculo" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
							<img className="ui-portaria-icone-posicao" src={iconeVeiculo} alt="" />
							{`${rowData._veiculo.placa} (${rowData._veiculo.fabricante?.nome || "Fabricante"} - ${rowData._veiculo.modelo || "Modelo"} ${MVeiculo.MapCores[rowData._veiculo.cor]})`}
							{this.state.mostrarAcoesPessoaVisita || this.state.vcard === id ? (
								<div className="ui-portaria-pessoa-acao-container">
									{this.securityVeiculo.edit ? <img title="Editar Veículo" className="ui-portaria-pessoa-acao" src={iconeEditar} alt="" onClick={() => this.editarVeiculoVisita(rowData._veiculo)} /> : null}
									{rowData.pessoas.some((p) => p.lugar) ? (
										<img title="Lançar Saída de Todos" className="ui-portaria-pessoa-acao" src={iconeLancarSaida} alt="" onClick={() => {
											ModalRealm.showConfirmacao("Tem certeza de que deseja confirmar a saída de todos os membros desta agenda?", () => {
												this.lancarSaidaTotal(rowData);
											});
										}} />
									) : null}
								</div>
							) : null}
						</div>
					</div>
				);
			}
			if (rowData.visitados && rowData.visitados.length) {
				itens.push(<div key={-3} className="ui-portaria-item-pessoa-end spaced" />);
				rowData.visitados.sort((a, b) => a.nome.localeCompare(b.nome)).forEach((pessoa) => {
					let id = `${pessoa.id}_vt${column.rowIndex}`;
					itens.push(
						<div key={`v_${pessoa.id}`} style={{position: "relative"}}>
							<div className="ui-portaria-item-pessoa visitado" onMouseLeave={() => this.setState({vcard: 0})} onMouseOver={() => this.state.vcard !== id && this.setState({vcard: id})}>
								<img className="ui-portaria-icone-posicao" src={isDentro(pessoa) ? iconePessoaDentro : iconePessoaFora} alt="" />
								{pessoa.nascimento && pessoa.nascimento.endsWith(moment().format("MM-DD")) ? <span><i title="Aniversariante" className="fad fa-birthday-cake" style={{color: "#EC407A"}} /> </span> : null}
								{pessoa.documentos?.filter(d => d.validade && moment().startOf("day").isAfter(d.validade)).map(d => <span><i title={`${d.tipo?.descricao} com validade expirada`} className="fad fa-exclamation-triangle" style={{color: "#FF9800"}} /> </span>)}
								<span>{pessoa.nome}</span>
								{pessoa.telefone ? <a href={`skype:${pessoa.telefone.replace(/[^0-9]/g, "")}`}><div className="fone-empresa">{pessoa.telefone}</div></a> : null}
								{!pessoa.telefone && pessoa.celular ? <a href={`skype:${pessoa.celular.replace(/[^0-9]/g, "")}`}><div className="fone-empresa">{pessoa.celular}</div></a> : null}
							</div>
							<VCard pessoa={pessoa} active={this.state.vcard === id} />
						</div>
					);
				});
			}
			itens.push(<div key={-1} className="ui-portaria-item-pessoa-end" />);
			return itens;
		}} />,
		<Column key="2" style={{width: "7em"}} header="Ações" body={(rowData) => {
			return (
				<div style={{textAlign: "center"}}>
					{this.securityAgenda.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Agenda" onClick={(event) => this.editarAgenda(rowData)} /> : null}
					{UserData.hasRole("XX0R") ? <img alt="" className="ui-action-icon" src={iconeEtiqueta} title="Imprimir Etiqueta" onClick={(event) => this.imprimirEtiqueta(rowData)} /> : null}
					{this.securityAgenda.edit ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Encerrar Agenda" onClick={(event) => this.encerrarAgenda(rowData)} /> : null}
				</div>
			);
		}} />
	];

	bloquearVisitaPorVisitadoFora = () => this.state.agenda?.visitados?.some(v => !isDentro(v));

	opcoesVisita = <Button className="ui-tzm-pagleft-icon" icon="fa-cog" onClick={() => ModalRealm.showDialog(<OpcoesPortaria key={Math.random()} onClose={() => {
		let habilitarBlocosConsulta = window.localStorage.getItem("safety-portaria-habilitarBlocosConsulta");
		habilitarBlocosConsulta = habilitarBlocosConsulta != null ? habilitarBlocosConsulta === "true" : true;
		let mostrarAcoesPessoaVisita = window.localStorage.getItem("safety-portaria-mostrarAcoesPessoaVisita");
		mostrarAcoesPessoaVisita = mostrarAcoesPessoaVisita != null ? mostrarAcoesPessoaVisita === "true" : false;
		let nomesEmMaiuscula = window.localStorage.getItem("safety-portaria-nomesEmMaiuscula");
		nomesEmMaiuscula = nomesEmMaiuscula != null ? nomesEmMaiuscula === "true" : true;
		this.setState({habilitarBlocosConsulta, mostrarAcoesPessoaVisita, nomesEmMaiuscula});
	}} />)} />;

	editarAgenda = (agenda) => {
		this.agendaService.buscar(agenda).then((agenda) => {
			ModalRealm.showDialog(<EditarAgenda agenda={agenda} key={Math.random()} onSave={(agenda) => {
				let agendas = this.state.agendas;
				let index = this.state.agendas.map(a => a.id).indexOf(agenda.id);
				agendas[index] = agenda;
				this.setState({agendas});
				this.updateAgenda(agenda);
			}} />);
		})
	}

	encerrarAgenda = (agenda) => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja encerrar esta agenda?", () => {
			this.agendaService.excluir(agenda).then(() => {
				let agendas = this.state.agendas;
				agendas = agendas.filter(a => a.id !== agenda.id);
				this.setState({agendas});
			});
		});
	}

	resetAgenda = callback => {
		let modeloAgenda;
		let agenda = MAgenda.Modelo();
		if (this.state.categoria) {
			let modeloCategoria = this.state.modelosAgenda.filter(ma => !ma.value.flags.includes("PORTARIA_ADICIONAL") && ma.label.includes(this.state.categoria.descricao))[0];
			if (modeloCategoria) {
				modeloAgenda = modeloCategoria;
			} else {
				let modeloPortaria = this.state.modelosAgenda.filter((ma) => !ma.value.flags.includes("PORTARIA_ADICIONAL") && ma.label.includes(this.state.portaria.descricao))[0];
				if (modeloPortaria) {
					modeloAgenda = modeloPortaria;
				} else {
					modeloAgenda = this.state.modelosAgenda[0];
				}
			}
		} else {
			let modeloPortaria = this.state.modelosAgenda.filter((ma) => !ma.value.flags.includes("PORTARIA_ADICIONAL") && ma.label.includes(this.state.portaria.descricao))[0];
			if (modeloPortaria) {
				modeloAgenda = modeloPortaria;
			} else {
				modeloAgenda = this.state.modelosAgenda[0];
			}
		}
		let permitirDocumento = true;
		if (modeloAgenda) {
			modeloAgenda = modeloAgenda.value;
			permitirDocumento = modeloAgenda.flags.includes("PERMITIR_DOCUMENTO");
		}
		this.setState({adicionais: [], agenda, search: "", observacao: "", modeloAgenda, permitirDocumento}, callback);
	}

	entidadeTemplate(entidade) {
		let pessoal = [];
		if (!Validator.isEmpty(entidade.cpf)) pessoal.push(`CPF ${entidade.cpf.formatCpf()}`);
		if (pessoal.length === 0 && !Validator.isEmpty(entidade.rg)) pessoal.push(`RG ${entidade.rg}`);
		if (pessoal.length === 0 && !Validator.isEmpty(entidade.passaporte)) pessoal.push(`Passaporte ${entidade.passaporte}`);
		let fones = [];
		if (!Validator.isEmpty(entidade.telefone)) fones.push(entidade.telefone);
		if (!Validator.isEmpty(entidade.celular)) fones.push(entidade.celular);
		pessoal = pessoal.join(", ");
		fones = fones.map((fone) => <span style={{marginRight: "4px"}}><img src={iconeTelefone} alt="" />{fone}</span>);
		// let fotos = entidade.fotos != null ? entidade.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc) : [];
		let enderecoUnidade = entidade.unidade?.contato?.enderecos.filter(e => e.tipo === "PRINCIPAL")[0];
		return (
			<table className="ui-portaria-frame">
				<tbody>
					<tr>
						<td style={{width: "90px"}} className="ui-portaria-photo-frame" rowSpan={2}>
							{entidade.ultimaFoto_ ? <img className="logs-acesso-v2-foto portaria" src={toSrcUltimaFoto(entidade.ultimaFoto_, true)} alt=""/> : <span className="logs-acesso-v2-foto portaria sem-foto"/>}
						</td>
						<td className="ui-portaria-pessoa-frame">
							<div className="ui-portaria-pessoa-nome">
								<img src={entidade.id === -2 ? entidade.unidade ? iconeUnidade : null : isDentro(entidade) ? iconePessoaDentro : iconePessoaFora} alt="" />
								{
									entidade.id === -2 && entidade.unidade ? (
										<span>
												<span>{entidade.unidade.etiqueta.descricao}</span>
												<div>{enderecoUnidade ? `${enderecoUnidade?.logradouro}, ${enderecoUnidade?.numero}` : null}</div>
											</span>
									) : entidade.nome
								}
							</div>
							{entidade.bloqueio ? <div className="ui-portaria-pessoa-documentos"><img src={iconeBloqueios} alt="" />O acesso desta pessoa está bloqueado</div> : <div className="ui-portaria-pessoa-documentos">{pessoal.length > 0 ? <img src={iconePortaria} alt="" /> : null}{pessoal}</div>}
							{fones.length > 0 ? <div  style={{margin: "4px 0"}}>{fones}</div> : null}
							{entidade.credencial != null ? <div className="ui-portaria-credencial"><img src={iconeCartao} alt="" />{entidade.credencial.descricao}</div> : null}
							{entidade.visitado != null ? <div className="ui-portaria-visitado"><img src={iconeVisitado} alt="" />{entidade.visitado.nome}</div> : null}
						</td>
					</tr>
					<tr>
						<td className="ui-portaria-auxiliares">
							{entidade._veiculo != null ? <div className="ui-portaria-veiculo"><img src={iconeVeiculo} alt="" />{entidade._veiculo.placa}</div> : null}
							{entidade.unidade != null ? entidade.id === -2 && !Validator.isEmpty(entidade.nome) ? <div className="ui-portaria-unidade"><img src={entidade.lugar ? iconePessoaDentro : iconePessoaFora} alt="" />{entidade.nome}</div> : <div className="ui-portaria-unidade"><img src={iconeUnidade} alt="" />{entidade.unidade.etiqueta.descricao}</div> : null}
							{entidade._empresa != null ? <div className="ui-portaria-veiculo"><img src={iconeEmpresa} alt="" />{entidade._empresa.nome}</div> : null}
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	switchPortaria = async (event, callback) => {
		if (event.value) {
			let modelosAgenda = event.value?.modelosAgenda?.filter(ma => !ma.flags.includes("PORTARIA_ADICIONAL")).map(ma => ({label: ma.descricao, value: ma})) || [];
			let equipamento = await this.equipamentoService.me();
			if (!equipamento?.id) {
				equipamento = event.value.equipamentoPadrao;
			}
			let did = Number(window.localStorage.getItem("safety-portaria-destino"));
			let destino = null;
			if (!isNaN(did)) {
				if (did === -1) {
					destino = {id: -1};
				} else {
					for (const dispositivo of equipamento.dispositivos) {
						if (dispositivo.destino?.id === did) {
							destino = dispositivo.destino;
							break;
						}
					}
				}
			}
			const modelosAdicionais = event.value.modelosAgenda?.filter(ma => ma.flags.includes("PORTARIA_ADICIONAL")).map(ma => ({label: ma.descricao, value: ma})) || [];
			this.setState({modelosAdicionais, adicionais: [], portaria: event.value, modelosAgenda, equipamento, destino}, () => {
				window.localStorage.setItem("safety-portaria-last", event.value.id);
				this.resetAgenda(callback);
			});
		}
	}

	listarVisitas = (portaria, immediate = false) => {
		if (this.listQueue != null) clearTimeout(this.listQueue);
		this.listQueue = setTimeout(() => {
			let addParams = "";
			if (this.state.status?.length) {
				if (this.state.status === "ATIVADO") {
					addParams += `&search=status:ATIVADO,PENDENTE`;
				} else {
					addParams += `&search=status:${this.state.status}`;
				}
			}
			if (this.state.etiqueta?.id != null) {
				addParams += `&etiquetas=id:${this.state.etiqueta.id}`;
			}
			if (this.state.etiquetas?.length) {
				this.state.etiquetas.forEach(e => {
					addParams += `&etiquetas=id:${e.id}`;
				});
			}
			if (this.state.credencial?.id) {
				addParams += `&pessoas=id:${this.state.credencial.pessoa?.id || "0"}`;
			}
			if (this.state.pesquisa?.length) {
				addParams += `&pessoas=nome,cpf,rg,passaporte~${this.state.pesquisa}`;
			}
			if (this.state.placa?.trim().length > 2) {
				addParams += `&veiculos=placa~${this.state.placa.trim()}`;
			}
			if (this.state.modelo?.trim().length) {
				addParams += `&veiculos=modelo~${this.state.modelo.trim()}`;
			}
			this.handleList(portaria, addParams);
		}, immediate ? 10 : 500);
	}

	handleList = (portaria, addParams) => {
		let registro;
		if (this.state.data?.length) {
			registro = `registro>${this.state.data}T00:00:00;registro<${this.state.data}T23:59:59;`;
		} else {
			registro = "";
		}
		portaria = portaria || this.state.portaria;
		if (portaria?.etiquetas?.length) {
			this.setState({lockList: true});
			const query = `search=${registro}${portaria.etiquetas.map(e => `&etiquetas=id:${e.id}`).join("&")}${addParams}`;
			this.agendaService.listar(`count&${query}`, {page: 0, size: 1}).then(totalAgendas => this.setState({totalAgendas: totalAgendas[0]}, () => {
				this.agendaService.listar(query, {page: this.state.page, size: this.state.size, sort: "id desc"}).then((agendas) => {
					this.setState({portaria, agendas, lockList: false});
					this.buscarCredenciais(agendas);
				});
			}));
		}
	}

	buscarCredencial = (agenda) => {
		setTimeout(() => {
			this.credencialService.temporarias(agenda.pessoas.map(p => p.id)).then((credenciais) => {
				const agendas = this.state.agendas;
				agendas.filter(a => a.id === agenda.id).forEach((agenda) => {
					agenda.pessoas.forEach((pessoa) => {
						pessoa.credenciais = credenciais.filter(c => c.pessoa.id === pessoa.id);
					});
				})
				this.setState({agendas});
			});
		}, 50);
	}

	buscarCredenciais = (agendas) => {
		setTimeout(() => {
			let pessoas = agendas.map((a) => a.pessoas).reduce((a, b) => a.concat(b), []).filter((p, i, a) => a.findIndex((f) => f.id === p.id) === i);
			this.credencialService.temporarias(pessoas.map(p => p.id)).then(credenciais => {
				const agendas = this.state.agendas;
				agendas.forEach((agenda) => {
					agenda.pessoas.forEach((pessoa) => {
						pessoa.credenciais = credenciais.filter((c) => c.pessoa.id === pessoa.id);
					});
				});
				this.setState({agendas});
			});
		}, 50);
	}

	cadastrarEquipamento = () => {
		const equipamento = {
			...MEquipamento.ModeloPC(),
			dispositivos: [
				{...MDispositivo.Modelo(), atuador: "SOLENOID1", sensorizacao: "GIRO"},
				{...MDispositivo.Modelo(), atuador: "SOLENOID2", sensorizacao: "GIRO"}
			],
			ip: this.state.equipamento.ip,
			descricao: `Portaria ${this.state.equipamento.ip}`
		};
		if (UserData.securities["EQP"].create) {
			ModalRealm.showDialog(<EditarEquipamento key={Math.random()} equipamento={equipamento} onModalClose={() => window.location.reload()} />);
		} else {
			ModalRealm.showDialog(<SudoUser role={{nome: "EQP", chave: "criar"}} permissoes={["Cadastrar equipamento"]} onSuccess={(usuario) => {
				ModalRealm.showDialog(<EditarEquipamento onModalClose={() => window.location.reload()} key={Math.random()} equipamento={equipamento} />);
			}} />);
		}
	}

	handlePRChange = (event) => {
		this.setState({[event.name]: event.value});
	}

	handleDestino = (event) => {
		window.localStorage.setItem("safety-portaria-destino", event.value != null ? event.value.id : null);
		this.setState({[event.name]: event.value});
	}

	handlePRPChange = (event) => {
		if (typeof event.value === "string") {
			event.value = event.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ0-9 +$@#*-]/g, "");
			if (this.state.nomesEmMaiuscula) event.value = event.value.toUpperCase();
		}
		this.setState({[event.name]: event.value});
	}

	showEditPessoa = (event, pessoa) => {
		ModalRealm.showDialog(
			<EditarPessoaFisica manutencao={false} obrigatorios={this.state.modeloAgenda?.obrigatorios} uppercase={this.state.nomesEmMaiuscula} 
						key={Math.random()} pessoa={pessoa} onModalClose={(pessoa) => {
			let {agenda} = this.state;
			if (event.value.credencial) {
				pessoa.credencial = event.value.credencial;
			}
			if (event.value.unidade) {
				if (!agenda.unidades) {
					agenda.unidades = [];
				}
				if (!agenda.unidades.map(u => u.id).includes(event.value.unidade.id)) agenda.unidades.push(event.value.unidade);
			}
			pessoa._documentos = [];
			if (this.state.modeloAgenda && this.state.modeloAgenda.flags) {
				if (!pessoa._documentos.includes("RG") && pessoa.rg && this.state.modeloAgenda.flags.includes("PERMITIR_DOCUMENTO")) {
					pessoa._documentos.push("RG");
				}
				if (!pessoa._documentos.includes("CPF") && pessoa.cpf && this.state.modeloAgenda.flags.includes("PERMITIR_DOCUMENTO")) {
					pessoa._documentos.push("CPF");
				}
			}
			if (!agenda.pessoas.length) {
				agenda.principalPessoa = pessoa.id;
			}
			if (!agenda.pessoas.map(p => p.id).includes(pessoa.id)) agenda.pessoas.push(pessoa);
			this.setState({agenda}, this.atualizarExpedientes);
		}} />);
	}

	onSelectEntidade = async event => {
		const {agenda} = this.state;
		this.setState({lockSave: true, search: ""}, async () => {
			if (event.value.id === -1) {
				let value = event.value.nome.replace("Criar pessoa ", "");
				if (this.state.nomesEmMaiuscula) value = value.toUpperCase();
				let property = value.length === 11 && !isNaN(Number(value)) ? "cpf" : !isNaN(Number(value)) ? "rg" : "nome";
				if (property === "cpf") value = value.formatCpf();
				await this.pessoaFisicaService.buscarPorDocumentoExcluido(property,value).then( pessoa => {
					if (pessoa && pessoa.id) {	
						const status = pessoa.status;				
						pessoa.status = 'ATIVADO';
						pessoa.fotos = [];
						if (status === 'EXCLUIDO') {
							return ModalRealm.showInformacao("info", ["Encontramos um cadastro inativo para esta pessoa. Clique em OK para reativar o registro."], () => this.showEditPessoa(event, pessoa));
						}
					} else {
						pessoa = {...MPessoaFisica.Modelo(), [property]: value}; 
					}
					this.showEditPessoa(event, pessoa)
				});
				if (event.value._veiculo) {
					if (event.value._veiculo.id) {
						if (event.value._veiculo.tipo === "CARRETA") {
							if (agenda._veiculo) {
								if (!agenda._veiculo.carretas) {
									agenda._veiculo.carretas = [];
								}
								agenda._veiculo.carretas.push(event.value._veiculo);
							} else {
								ModalRealm.showInformacao("warn", ["Antes de adicionar uma carreta, você deve adicionar o veículo que a transporta."]);
							}
						} else {
							agenda._veiculo = event.value._veiculo;
						}
					} else {
						ModalRealm.showDialog(<EditarVeiculo veiculo={{...MVeiculo.Modelo(), tipo: agenda._veiculo ? "CARRETA" : "OUTRO", placa: event.value._veiculo.placa}} onModalClose={veiculo => {
							let {agenda} = this.state;
							if (veiculo.tipo === "CARRETA") {
								if (agenda._veiculo) {
									if (!agenda._veiculo.carretas) {
										agenda._veiculo.carretas = [];
									}
									agenda._veiculo.carretas.push(veiculo);
								} else {
									ModalRealm.showInformacao("warn", ["Antes de adicionar uma carreta, você deve adicionar o veículo que a transporta."]);
								}
							} else {
								agenda._veiculo = veiculo;
							}
							this.setState({agenda});
						}} />);
					}
				}
				if (event.value.visitado) {
					if (!agenda.visitados) {
						agenda.visitados = [];
					}
					agenda.visitados.push(event.value.visitado);
				}
				if (event.value._empresa) {
					agenda.empresa = event.value._empresa;
				}
			} else if (event.value.id === -2) {
				if (event.value.unidade) {
					if (!agenda.unidades) {
						agenda.unidades = [];
					}
					if (!agenda.unidades.map(u => u.id).includes(event.value.unidade.id)) {
						agenda.unidades.push(event.value.unidade);
						const avisos = await this.avisoService.listarAlt(agenda.pessoas.map(p => p.id), [event.value.unidade.etiqueta.id]);
						avisos.forEach(aviso => {
							agenda.pessoas.filter(p => p.id === aviso.pessoa.id).forEach(p => {
								p.avisos = p.avisos == null ? [aviso] : [...p.avisos.filter(a => a.id !== aviso.id), aviso];
							});
						});
					}
				}
				if (event.value._veiculo) {
					if (event.value._veiculo.id) {
						if (event.value._veiculo.tipo === "CARRETA") {
							if (agenda._veiculo) {
								if (!agenda._veiculo.carretas) {
									agenda._veiculo.carretas = [];
								}
								agenda._veiculo.carretas.push(event.value._veiculo);
							} else {
								ModalRealm.showInformacao("warn", ["Antes de adicionar uma carreta, você deve adicionar o veículo que a transporta."]);
							}
						} else {
							agenda._veiculo = event.value._veiculo;
						}
					} else {
						ModalRealm.showDialog(<EditarVeiculo veiculo={{...MVeiculo.Modelo(), tipo: agenda._veiculo ? "CARRETA" : "OUTRO", placa: event.value._veiculo.placa}} onModalClose={veiculo => {
							let {agenda} = this.state;
							if (veiculo.tipo === "CARRETA") {
								if (agenda._veiculo) {
									if (!agenda._veiculo.carretas) {
										agenda._veiculo.carretas = [];
									}
									agenda._veiculo.carretas.push(veiculo);
								} else {
									ModalRealm.showInformacao("warn", ["Antes de adicionar uma carreta, você deve adicionar o veículo que a transporta."]);
								}
							} else {
								agenda._veiculo = veiculo;
							}
							this.setState({agenda});
						}} />);
					}
				}
				if (event.value.visitado) {
					if (!agenda.visitados) {
						agenda.visitados = [];
					}
					agenda.visitados.push(event.value.visitado);
				}
				if (event.value._empresa) {
					agenda.empresa = event.value._empresa;
				}
			} else if (event.value._visitado) {
				const agenda = this.state.agenda;
				if (!agenda.visitados) {
					agenda.visitados = [];
				}
				agenda.visitados.push(event.value);
			} else {
				event.value = {...event.value, ...(await this.portariaService.dadosComplementares(event.value))};
				if (UserData.hasRole("XX1E")) {
					const veiculos = await this.veiculoService.listar(`proprietario=id:${event.value.id}`, {page: 0, size: 10});
					switch (veiculos.length) {
						case 0:
							break;
						case 1:
							agenda._veiculo = veiculos[0];
							break;
						default:
							ModalRealm.showDialog(<SelecionarVeiculo veiculos={veiculos} onModalClose={veiculos => {
								const {agenda} = this.state;
								for (const veiculo of veiculos) {
									if (veiculo.tipo !== "CARRETA") {
										agenda._veiculo = veiculo;
										break;
									}
								}
								if (agenda._veiculo) {
									agenda._veiculo.carretas = veiculos.filter(v => v.tipo === "CARRETA");
								} else {
									ModalRealm.showInformacao("warn", ["Não foi selecionado nenhum veículo automotor"]);
								}
								this.setState({agenda});
							}}/>);
							break;
					}
				}
				// const result = await this.portariaService.existeComboPermanente(event.value.id);
				// agenda.pessoas.filter(p => p.id === event.value.id).forEach(p => p.permanente = result);
				if (event.value.unidade) {
					if (!agenda.unidades) {
						agenda.unidades = [];
					}
					if (!agenda.unidades.map(u => u.id).includes(event.value.unidade.id)) {
						agenda.unidades.push(event.value.unidade);
					}
				}
				agenda.pessoas = agenda.pessoas.filter(p => p.id !== event.value.id);
				if (!agenda.pessoas.length) {
					agenda.principalPessoa = event.value.id;
				}
				agenda.pessoas.push(event.value);
				const avisos = await this.avisoService.listarAlt(agenda.pessoas.map(p => p.id), (agenda.unidades || []).map(u => u.etiqueta.id));
				avisos.forEach(aviso => {
					agenda.pessoas.filter(p => p.id === aviso.pessoa.id).forEach(p => {
						p.avisos = p.avisos == null ? [aviso] : [...p.avisos.filter(a => a.id !== aviso.id), aviso];
					});
				});
				if (!agenda.unidades?.length) {
					this.avisoService.listarAvisosPortaria(event.value.id).then(avisos => {
						if (avisos.length) {
							ModalRealm.showDialog(<SelecionarAviso avisos={avisos} onModalClose={async aviso => {
								const {agenda} = this.state;
								const unidade = await this.unidadeService.buscarUnidadePorEtiqueta(aviso.etiqueta);
								if (unidade) {
									if (!agenda.unidades) {
										agenda.unidades = [];
									}
									if (!agenda.unidades.some(u => u.id === unidade.id)) {
										agenda.unidades.push(unidade);
									}
									agenda.pessoas.filter(ap => ap.id === aviso.pessoa.id).forEach(ap => {
										if (!ap.avisos) {
											ap.avisos = [];
										}
										ap.avisos.push(aviso);
									});
								}
								this.setState({agenda});
							}}/>);
						}
					});
				}
				if (event.value.visitado) {
					if (!agenda.visitados) {
						agenda.visitados = [];
					}
					agenda.visitados.push(event.value.visitado);
				}
				if (event.value._empresa) {
					agenda.empresa = event.value._empresa;
				}
				const credenciais = await this.credencialService.listarAlt({page: 0, excluidos: true, size: 10, flags: "DOCUMENTO", pessoa: event.value.id});
				agenda.pessoas.filter(p => p.id === event.value.id).forEach(pessoa => {
					pessoa._documentos = [];
					if (this.state.modeloAgenda?.flags && this.state.modeloAgenda.flags.includes("PERMITIR_DOCUMENTO")) {
						if (!pessoa._documentos.includes("RG") && pessoa.rg?.length) {
							pessoa._documentos.push("RG");
						}
						if (!pessoa._documentos.includes("CPF") && pessoa.cpf?.length) {
							pessoa._documentos.push("CPF");
						}
					}
					pessoa.docsAsCredenciais = credenciais;
				});
				if (event.value._veiculo) {
					if (event.value._veiculo.id) {
						if (event.value._veiculo.tipo === "CARRETA") {
							if (agenda._veiculo) {
								if (!agenda._veiculo.carretas) {
									agenda._veiculo.carretas = [];
								}
								agenda._veiculo.carretas.push(event.value._veiculo);
							} else {
								ModalRealm.showInformacao("warn", ["Antes de adicionar uma carreta, você deve adicionar o veículo que a transporta."]);
							}
						} else {
							agenda._veiculo = event.value._veiculo;
						}
					} else {
						ModalRealm.showDialog(<EditarVeiculo veiculo={{...MVeiculo.Modelo(), tipo: agenda._veiculo ? "CARRETA" : "OUTRO", placa: event.value._veiculo.placa}} onModalClose={veiculo => {
							let {agenda} = this.state;
							if (veiculo.tipo === "CARRETA") {
								if (agenda._veiculo) {
									if (!agenda._veiculo.carretas) {
										agenda._veiculo.carretas = [];
									}
									agenda._veiculo.carretas.push(veiculo);
								} else {
									ModalRealm.showInformacao("warn", ["Antes de adicionar uma carreta, você deve adicionar o veículo que a transporta."]);
								}
							} else {
								agenda._veiculo = veiculo;
							}
							this.setState({agenda});
						}} />);
					}
				}
			}
			await this.atualizarExpedientes();
			let {agrupamento} = this.state;
			if (agenda._veiculo) {
				agrupamento = true;
			}
			this.setState({agenda, agrupamento, search: "", lockSave: false}, () => this.superInput.input.focus());
		});
	}

	atualizarExpedientes = async () => {
		if (this.state.agenda.unidades) {
			const {agenda} = this.state;
			for (const unidade of agenda.unidades) {
				unidade._expediente = !(await httpGet(`/unidades/expediente/${unidade.etiqueta?.id}`));
			}
			this.setState({agenda});
		}
	}

	handleChangeVisita = (event, pessoa) => {
		let agenda = this.state.agenda;
		agenda.pessoas[agenda.pessoas.map(p => p.id).indexOf(pessoa.id)][event.name] = event.value;
		this.setState({agenda});
	}

	convertCartao = (event, pessoa) => {
		if (event.charCode === 13) {
			let agenda = this.state.agenda;
			let index = agenda.pessoas.map(p => p.id).indexOf(pessoa.id);
			let parameter = agenda.pessoas[index].credencial;
			this.credencialService.listar(`search=status=ATIVADO;codigo:${NumberUtils.toLittleEndian(parameter)}`, {page: 0, size: 1}).then((credenciais) => {
				if (credenciais.length > 0) {
					agenda.pessoas[index].credencial = credenciais[0];
					this.setState({agenda});
				}
			});
		}
	}

	documentosComoSenha = (pessoa) => {
		let options = [];
		if (!Validator.isEmpty(pessoa.rg)) options.push({label: "RG", value: "RG"});
		if (Validator.isCPF(pessoa.cpf)) options.push({label: "CPF", value: "CPF"});
		return options;
	}

	togglePrincipalPessoa = (e, principalPessoa) => {
		e.stopPropagation();
		e.preventDefault();
		const {agenda} = this.state;
		if (agenda.principalPessoa === principalPessoa && !this.state.modeloAgenda?.flags?.includes("FORCAR_PRINCIPAL")) {
			agenda.principalPessoa = null;
		} else {
			agenda.principalPessoa = principalPessoa;
		}
		this.setState({agenda});
	}

	detalhesVisita = [
		<Column key="2" header="Pessoa" style={{width: "*"}} body={(pessoa) => {
			const telefones = [];
			if (pessoa.telefone) telefones.push(<span><i className="fa fa-phone" /> {pessoa.telefone}</span>);
			if (pessoa.celular) telefones.push(<span><i className="fa fa-phone" /> {pessoa.celular}</span>);
			const mensagensDocumentosObrigatorios = [];
			if (this.state.modeloAgenda) {
				pessoa.documentos.forEach(pd => {
					if (moment().startOf("day").isAfter(pd.validade)) {
						mensagensDocumentosObrigatorios.push(
							<div style={{color: "#F44336"}}>
								<i className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> Documento {pd.tipo?.descricao} com validade expirada!
								{pd.tipo?.formulario?.id ? this.printClickToFill(pd.tipo, pessoa) : null}
							</div>
						);
					}
					if (pd.pendente) {
						mensagensDocumentosObrigatorios.push(
							<div style={{color: "#666666"}}>
								<i className="fad fa-spinner fa-spin" style={{color: "#666666"}}/> Documento {pd.tipo?.descricao} sendo preenchido...
								{pd.tipo?.formulario?.id ? this.printClickToFill(pd.tipo, pessoa, true) : null}
							</div>
						);
					}
				});
				if (pessoa.empresa && pessoa.empresa.documentos) {
					pessoa.empresa.documentos.forEach(ped => {
						if (moment().startOf("day").isAfter(ped.validade)) {
							mensagensDocumentosObrigatorios.push(
								<div style={{color: "#F44336"}}>
									<i className="fad fa-exclamation-triangle" style={{color: "#FF9800"}}/> Documento {ped.tipo?.descricao} (da empresa) com validade expirada!
									{ped.tipo?.formulario?.id ? this.printClickToFill(ped.tipo, pessoa.empresa) : null}
								</div>
							);
						}
						if (ped.pendente) {
							mensagensDocumentosObrigatorios.push(
								<div style={{color: "#666666"}}>
									<i className="fad fa-spinner fa-spin" style={{color: "#666666"}}/> Documento {ped.tipo?.descricao} sendo preenchido...
								</div>
							);
						}
					});
				}
				this.state.modeloAgenda.modelosAcesso.forEach(ma => {
					ma.documentosObrigatorios.forEach(mado => {
						switch (mado.obrigatoriedade) {
							case "PRINCIPAL":
								if (this.state.agenda.principalPessoa !== pessoa.id) {
									return;
								}
								break;
							case "SECUNDARIOS":
								if (this.state.agenda.principalPessoa === pessoa.id) {
									return;
								}
								break;
							default:
								break;
						}
						if (mado.tipo.aplicacao === "FISICA" && !pessoa.documentos?.some(pd => pd.tipo?.id === mado.tipo.id)) {
							mensagensDocumentosObrigatorios.push(
								<div style={{color: "#F44336"}}>
									<i className="fad fa-exclamation-triangle"/> Falta documento obrigatório {mado.tipo.descricao}!
									{mado.tipo.formulario?.id ? this.printClickToFill(mado, pessoa) : null}
								</div>
							);
						}
						if (mado.tipo.aplicacao === "JURIDICA" && !pessoa.empresa?.documentos?.some(ped => ped.tipo?.id === mado.tipo.id)) {
							mensagensDocumentosObrigatorios.push(
								<div style={{color: "#F44336"}}>
									<i className="fad fa-exclamation-triangle"/> Falta documento obrigatório (para empresa) {mado.tipo.descricao}!
									{mado.tipo.formulario?.id ? this.printClickToFill(mado, pessoa.empresa) : null}
								</div>
							);
						}
					});
				});
			}
			const hasVehicle = this.state.agenda?._veiculo?.id;
			return (
				<div className="ui-portaria-frame">
					<div className="ui-g">
						<div className="ui-g-12">
							<div className="ui-portaria-pessoa-nome" style={{paddingBottom: "4px"}}>
								<div className="ui-portaria-aviso ui-portaria-aviso-clear" onClick={() => this.securityPessoa.edit && this.editarPessoa(pessoa, false)}>
									{pessoa.ultimaFoto_ ? <img className="logs-acesso-v2-foto visitante" src={toSrcUltimaFoto(pessoa.ultimaFoto_)} alt="" /> : <span className="logs-acesso-v2-foto visitante sem-foto"/>}
									<div className="ui-portaria-desc-block">
										{pessoa.flags && pessoa.flags.includes("ADMINISTRADOR") ? <span><i title="Administrador" className="fad fa-user-shield" style={{color: "#4CAF50"}} /> </span> : null}
										{pessoa.nascimento && pessoa.nascimento.endsWith(moment().format("MM-DD")) ? <span><i title="Aniversariante" className="fad fa-birthday-cake" style={{color: "#EC407A"}} /> </span> : null}
										<span>{pessoa.nome}</span>
										<br />
										<div className="txt-smaller">
											{pessoa.empresa ? <span>{pessoa.empresa.nome}<br /></span> : ""}
											{pessoa.cpf ? <span>{` CPF: ${pessoa.cpf}`}</span> : ""}
											{pessoa.rg ? <span>{` RG: ${pessoa.rg}`}</span> : ""}
											{pessoa.passaporte && !(pessoa.cpf || pessoa.rg) ? <span>{` Doc: ${pessoa.passaporte}`}</span> : ""}
										</div>
										<div className="txt-smaller">{telefones.length ? telefones : ""}</div>
										<div className="txt-smaller">
											{
												pessoa.ultimaPassagem
													? pessoa.ultimaVisita
													? `Última visita: ${moment(pessoa.ultimaPassagem).format("DD/MM/YY")} em ${pessoa.ultimaVisita.split(";").join(", ")}`
													: `Última visita: ${moment(pessoa.ultimaPassagem).format("DD/MM/YY")}`
													: null
											}
										</div>
										<div className="txt-smaller">{mensagensDocumentosObrigatorios}</div>
									</div>
									<img alt="" className="ui-action-icon ui-portaria-remove-pessoa" src={iconeDesvincular} title="Desvincular Pessoa" onClick={(event) => {
										event.stopPropagation();
										this.excluirPessoa(pessoa);
									}}/>
									<div style={{clear: "both"}}/>
									<img onClick={e => this.togglePrincipalPessoa(e, pessoa.id)} title={hasVehicle ? "Motorista" : "Visitante Principal"} src={hasVehicle ? iconeMotorista : iconeEstrela} alt="" className="portaria-star-icon" style={this.state.agenda.principalPessoa === pessoa.id ? {filter: "grayscale(0)", opacity: "1"} : {filter: "grayscale(1)", opacity: ".5"}}/>
								</div>
								{pessoa.credencial && pessoa.credencial.flags && pessoa.credencial.flags.includes("TEMPORARIA") && pessoa.lugar && pessoa.lugar.tipo !== "EXTERNO" ? <div className="portaria-aviso">Irregularidade na posição da pessoa</div> : null}
								{pessoa.bloqueio ? <div className="portaria-aviso red">Acesso bloqueado</div> : null}
								{pessoa.permanente ? <div className="portaria-aviso">Já possui acesso com agenda permanente</div> : null}
								{this.securityAviso.view && pessoa.avisos ? pessoa.avisos.map(a => <ChipAviso aviso={a} onDelete={this.excluirAviso}/>) : null}
								{this.expiracaoPessoa(pessoa) ? <div className="portaria-aviso" key={-11}>Cadastro desatualizado</div> : null}
							</div>
						</div>
					</div>
					{this.securityCredencial.edit ? <div className="ui-g">
						<SuggestionBox ref={(el) => this.refCredencialVisita(el, pessoa)} id={`kVisCrd${pessoa.id}`} grid={12} onKeyPress={(event) => this.convertCartao(event, pessoa)} name="credencial" label="Credencial de Visita" value={pessoa.credencial} suggestions={this.state.credenciais} completeMethod={this.listarCredenciaisTemporarias} onChange={(event) => this.handleChangeVisita(event, pessoa)} field="descricao" />
					</div> : null}
					{this.securityCredencial.edit && this.state.permitirDocumento ? <div className="ui-g">
						<MultiCombobox grid={12} defaultLabel="Nenhum" value={pessoa._documentos} onChange={(event) => {
							pessoa._documentos = event.value;
							this.forceUpdate();
						}} label="Documentos como Senha" options={this.documentosComoSenha(pessoa)} />
					</div> : null}
				</div>
			);
		}} />
	];

	refCredencialVisita = (el, pessoa) => {
		if (el && el.input) {
			const input = el.input;
			input.addEventListener("keydown", (event) => this.handleKeysPortaria(event, pessoa));
			this.inputsCredenciais[pessoa.id] = input;
		}
	}

	inputsCredenciais = {};

	editarPessoa = (pessoa, manutencao = true) => {
		this.pessoaFisicaService.buscar(pessoa).then((pessoa) => {
			ModalRealm.showDialog(
			<EditarPessoaFisica manutencao={manutencao} uppercase={this.state.nomesEmMaiuscula} key={Math.random()} pessoa={pessoa} 				
				equipamentos={this.state.equipamentosFaciais}
				onModalClose={(novo) => {
					const {agenda} = this.state;
					const index = agenda.pessoas.findIndex(p => p.id === novo.id);
					agenda.pessoas[index] = {...agenda.pessoas[index], ...novo};
					this.setState({agenda});
			}} />);
		});
	}

	excluirPessoa = (rowData) => {
		let agenda = this.state.agenda;
		agenda.pessoas = agenda.pessoas.filter(p => p.id !== rowData.id);
		if (agenda.pessoas.length && this.state.modeloAgenda?.flags?.includes("FORCAR_PRINCIPAL") && agenda.principalPessoa === rowData.id) {
			agenda.principalPessoa = agenda.pessoas[0].id;
		}
		this.setState({agenda});
	}

	listarCredenciaisTemporarias = (event) => {
		const parameters = {
			query: event.query,
			flags: "TEMPORARIA",
			page: 0,
			size: 10,
			sort: "descricao"
		}
		if ((process.env.REACT_APP_CUSTOM_FLAGS || "").split(",").includes("CACIQUE")) {
			parameters.status = "LIVRE";
		}
		this.credencialService.listarAlt(parameters).then((credenciais) => this.setState({credenciais}));
	}

	validarSetupPortaria = (agenda) => {
		let messages = [];
		if (!this.state.modeloAgenda) {
			messages.push("É obrigatório selecionar um modelo de agenda.");
			return messages;
		}
		if (agenda.veiculos?.length && !agenda.veiculos.some(av => av.tipo !== "CARRETA")) {
			messages.push("A lista de veículos deve conter ao menos um transporte automotor.");
		}
		if (this.state.modeloAgenda.flags) {
			if (this.state.modeloAgenda.flags.includes("FORCAR_UNIDADE")) {
				if (!agenda.unidades?.length) {
					messages.push("É obrigatório informar à qual unidade a visita se destina.");
				}
			}
			if (this.state.modeloAgenda.flags.includes("FORCAR_VISITADO")) {
				if (!agenda.visitados?.length) {
					messages.push("É obrigatório informar o(s) visitado(s) da agenda.");
				}
			}
			if (this.state.modeloAgenda.flags.includes("FORCAR_CREDENCIAL_TEMPORARIA")) {
				if (!agenda.pessoas.every(p => Validator.isEntidade(p.credencial))) {
					messages.push("Confira se todos os visitantes estão com credencial de acesso.");
				}
			}
			if (this.state.modeloAgenda.flags.includes("FORCAR_VEICULO")) {
				if (!(agenda._veiculo && agenda._veiculo.id)) {
					messages.push("É obrigatório o preenchimento de ao menos um veículo.");
				}
			}
			if (this.state.modeloAgenda.flags.includes("FORCAR_OBSERVACAO")) {
				if (!agenda.observacao?.length) {
					messages.push("Você precisa informar o motivo da visita no campo observação.");
				}
			}
			if (this.state.modeloAgenda.flags.includes("FORCAR_EMPRESA")) {
				if (!agenda.empresa?.id) {
					messages.push("Você precisa informar a empresa da agenda.");
				}
			}
		}
		this.state.modeloAgenda.modelosAcesso.forEach(ma => {
			if (ma.flags?.includes("VALIDAR_DOCUMENTO") && ma.documentosObrigatorios) {
				ma.documentosObrigatorios.filter(mado => mado.tipo.aplicacao === "FISICA").forEach(mado => {
					agenda.pessoas.forEach(ap => {
						switch (mado.obrigatoriedade) {
							case "PRINCIPAL":
								if (agenda.principalPessoa !== ap.id) {
									return;
								}
								break;
							case "SECUNDARIOS":
								if (agenda.principalPessoa === ap.id) {
									return;
								}
								break;
							default:
								break;
						}
						if (!ap.documentos?.some(apd => !apd.pendente && apd.tipo?.id === mado.tipo.id)) {
							messages.push(`A pessoa ${ap.nome} não possui o documento ${mado.tipo.descricao}`);
						} else if (ap.documentos?.some(apd => apd.tipo?.id === mado.tipo.id && moment().startOf("day").isAfter(apd.validade))) {
							messages.push(`O documento ${mado.tipo.descricao} da pessoa ${ap.nome} está com a validade expirada`);
						}
					});
				});
				ma.documentosObrigatorios.filter(mado => mado.tipo.aplicacao === "JURIDICA").forEach(mado => {
					agenda.pessoas.forEach(ap => {
						switch (mado.obrigatoriedade) {
							case "PRINCIPAL":
								if (agenda.principalPessoa !== ap.id) {
									return;
								}
								break;
							case "SECUNDARIOS":
								if (agenda.principalPessoa === ap.id) {
									return;
								}
								break;
							default:
								break;
						}
						if (!ap.empresa?.documentos?.some(apd => !apd.pendente && apd.tipo?.id === mado.tipo.id)) {
							messages.push(`A empresa ${ap.empresa?.nome} não possui o documento ${mado.tipo.descricao}`);
						} else if (ap.empresa?.documentos?.some(apd => apd.tipo?.id === mado.tipo.id && moment().startOf("day").isAfter(apd.validade))) {
							messages.push(`O documento ${mado.tipo.descricao} da empresa ${ap.empresa?.nome} está com a validade expirada`);
						}
					});
				});
				ma.documentosObrigatorios.filter(mado => mado.tipo.aplicacao === "VEICULO").forEach(mado => {
					if (agenda._veiculo) {
						switch (mado.obrigatoriedade) {
							case "PRINCIPAL":
								if (agenda._veiculo.tipo === "CARRETA") {
									return;
								}
								break;
							case "SECUNDARIOS":
								if (agenda._veiculo.tipo !== "CARRETA") {
									return;
								}
								break;
							default:
								break;
						}
						if (!agenda._veiculo.documentos?.some(vd => !vd.pendente && vd.tipo?.id === mado.tipo.id)) {
							messages.push(`O veículo ${agenda._veiculo.placa} não possui o documento ${mado.tipo.descricao}`);
						} else if (agenda._veiculo.documentos?.some(vd => vd.tipo?.id === mado.tipo.id && moment().startOf("day").isAfter(vd.validade))) {
							messages.push(`O documento ${mado.tipo.descricao} do veículo ${agenda._veiculo.placa} está com a validade expirada`);
						}
					}
				});
				ma.documentosObrigatorios.filter(mado => mado.tipo.aplicacao === "AGENDA").forEach(mado => {
					if (!agenda.documentos?.some(d => !d.pendente && d.tipo?.id === mado.tipo.id)) {
						messages.push(`A agenda não possui o documento ${mado.tipo.descricao}`);
					}
					if (agenda.documentos?.some(d => d.tipo?.id === mado.tipo.id && moment().startOf("day").isAfter(d.validade))) {
						messages.push(`O documento ${mado.tipo.descricao} da agenda está com a validade expirada`);
					}
				});
			}
		});
		const obrigatorios = this.state.modeloAgenda ? this.state.modeloAgenda.obrigatorios : [];
		agenda.pessoas.forEach((pessoa) => {
			MPessoaFisica.validarObrigatorios(messages, obrigatorios, pessoa, true);
		});
		return messages;
	}

	confirmarVisita = async () => {
		if (!this.state.agenda.pessoas.length || this.state.lockSave || this.bloquearVisitaPorVisitadoFora()) {
			return;
		}
		let messages;
		let {agenda} = this.state;
		this.setState({lockSave: true});
		if (agenda.pessoas.some(p => p.bloqueio)) {
			ModalRealm.showInformacao("warn", ["Há pessoas com bloqueio de acesso ativo."]);
			setTimeout(() => this.setState({lockSave: false}), 50);
		} else if ((messages = this.validarSetupPortaria(agenda)).length) {
			ModalRealm.showInformacao("warn", messages);
			setTimeout(() => this.setState({lockSave: false}), 50);
		} else {
			agenda.pessoas.forEach((pessoa) => {
				if (Validator.isEntidade(pessoa.credencial)) {
					let credencial = pessoa.credencial;
					pessoa._visitante = 1;
					credencial.pessoa = pessoa;
					pessoa.credencial = null;
					this.credencialService.salvar(credencial);
				} else {
					pessoa.credencial = null;
				}
				if (pessoa._documentos && this.state.permitirDocumento) {
					if (!pessoa.docsAsCredenciais) pessoa.docsAsCredenciais = [];
					let rgs = pessoa.docsAsCredenciais.map(c => c.codigo.toString());
					let cpfs = pessoa.docsAsCredenciais.map(c => c.codigo.toString().padStart(11, "0"));
					pessoa._documentos.forEach(documento => {
						if (documento === "RG") {
							if (!rgs.includes(pessoa.rg.replace(/[^0-9]/g, ""))) {
								this.credencialService.salvar({...MCredencial.Modelo(), pessoa, forma: "SENHA", descricao: `Senha RG ${pessoa.id}`, codigo: Number(pessoa.rg.replace(/[^0-9]/g, "")), flags: ["DOCUMENTO"]});
							} else {
								let recuperar = pessoa.docsAsCredenciais.filter(c => (c.status === "ENCERRADO" || c.status === "EXCLUIDO") && c.codigo === Number(pessoa.rg.replace(/[^0-9]/g, "")));
								if (recuperar.length) {
									recuperar[0].status = "ATIVADO";
									this.credencialService.salvar(recuperar[0]);
								}
							}
						}
						if (documento === "CPF") {
							if (!cpfs.includes(pessoa.cpf)) {
								this.credencialService.salvar({...MCredencial.Modelo(), pessoa, forma: "SENHA", descricao: `Senha CPF ${pessoa.id}`, codigo: Number(pessoa.cpf), flags: ["DOCUMENTO"]});
							} else {
								let recuperar = pessoa.docsAsCredenciais.filter(c => (c.status === "ENCERRADO" || c.status === "EXCLUIDO") && c.codigo === Number(pessoa.cpf));
								if (recuperar.length) {
									recuperar[0].status = "ATIVADO";
									this.credencialService.salvar(recuperar[0]);
								}
							}
						}
					});
					if (pessoa.docsAsCredenciais) {
						pessoa.docsAsCredenciais.filter(c => c.status === "ATIVADO").forEach(credencial => {
							if ((pessoa.rg === credencial.codigo.toString() && !pessoa._documentos.includes("RG")) || (pessoa.cpf === credencial.codigo.toString().padStart(11, "0") && !pessoa._documentos.includes("CPF"))) {
								this.credencialService.excluir(credencial);
							}
						});
					}
				}
			});
			if (this.state.categoria) {
				agenda.etiquetas = agenda.etiquetas.filter(e => e.id !== this.state.categoria.id);
				agenda.etiquetas.push(this.state.categoria);
			}
			if (this.state.modeloAgenda) {
				const modeloAgenda = this.state.modeloAgenda;
				const modelosAcesso = modeloAgenda.modelosAcesso;
				const modelosAcessoIds = modelosAcesso.map((ma) => ma.id);
				agenda.modelosAcesso = agenda.modelosAcesso.filter(ma => !modelosAcessoIds.includes(ma.id));
				agenda.modelosAcesso.push(...modelosAcesso);
				agenda.flags = [...modeloAgenda.agendaFlags];
				switch (modeloAgenda.duracao) {
					case -1:
						agenda.validade = LocalDateTime.atEndOfDayISO();
						break;
					case 0:
						agenda.validade = null;
						break;
					default:
						let validade = new Date();
						validade.setSeconds(59);
						validade.setMinutes(validade.getMinutes() + modeloAgenda.duracao);
						agenda.validade = moment(validade).format("YYYY-MM-DDTHH:mm:ss");
						break;
				}
			}
			if (agenda.unidades) {
				if (agenda.unidades.some(u => u.modeloAcesso)) {
					agenda.modelosAcesso = [];
					agenda.unidades.filter(u => u.modeloAcesso).forEach(u => agenda.modelosAcesso.push(u.modeloAcesso));
				}
				agenda.etiquetas.push(...agenda.unidades.map(u => u.etiqueta));
				agenda.unidades = [];
			}
			if (this.state.adicionais?.length) {
				this.state.adicionais.forEach(ma => agenda.modelosAcesso.push(...ma.modelosAcesso));
			}
			if (this.state.portaria.etiquetas) {
				let ids = agenda.etiquetas.map(e => e.id);
				agenda.etiquetas.push(...this.state.portaria.etiquetas.filter(e => !ids.includes(e.id)));
			}
			if (agenda._veiculo) {
				agenda.veiculos = [agenda._veiculo];
				if (agenda._veiculo.carretas?.length) {
					agenda.veiculos.push(...agenda._veiculo.carretas);
				}
			}
			if (this.state.setupCondominio?.categoriasFacialPortaria?.length) {
				const faciais = this.state.setupCondominio?.categoriasFacialPortaria.map(e => e.id);
				if (agenda.etiquetas.some(e => faciais.includes(e.id))) {
					agenda.pessoas.forEach(p => p._visitante = 1);
					ModalRealm.showDialog(<FacialDialog equipamentos={this.state.equipamentosFaciais} pessoas={agenda.pessoas} onClose={() => this.setState({lockSave: false})}/>);
				}
			}
			if (this.state.destino || agenda.pessoas.some(p => p._visitante === 1)) {
				this.confirmarAcessos({etiquetas: agenda.etiquetas, equipamento: this.state.equipamento, destino: this.state.destino}, agenda);
			} else {
				if (this.state.agrupamento) {
					this.agendaService.salvar(agenda).then(async agenda => {
						this.updateAgenda(agenda);
						this.agendaService.enviarEmail(agenda);
						this.setState({lockSave: false});
					});
				} else {
					for (const pessoa of agenda.pessoas) {
						await this.agendaService.salvar({...agenda, pessoas: [pessoa]}).then(agenda => {
							this.updateAgenda(agenda);
							this.agendaService.enviarEmail(agenda);
						});
					}
					this.setState({lockSave: false});
				}
			}
			this.resetAgenda();
		}
	}

	confirmarAcessos = (criterio, agenda, index = 0) => {
		criterio.pessoa = agenda.pessoas[index];
		if (!this.state.setupSistema?.flags?.includes("VISITANTE_ENTRA_MANUALMENTE") && criterio.pessoa._visitante === 1) {
			if (criterio.pessoa.lugar && criterio.pessoa.lugar.tipo !== "EXTERNO") {
				return this.portariaService.lancarAcesso({...criterio, destino: null, flags: ["AUTOMATICO"], atividade: "AJUSTE", eventos: ["POSICAO"]}).then(() => {
					if (agenda.pessoas.length > ++index) {
						return this.confirmarAcessos(criterio, agenda, index);
					} else {
						this.finalizarAgenda(agenda);
					}
				});
			} else {
				if (agenda.pessoas.length > ++index) {
					return this.confirmarAcessos(criterio, agenda, index);
				} else {
					this.finalizarAgenda(agenda);
				}
			}
		} else if (criterio.destino) {
			const destino = criterio.destino.id > 0 ? criterio.destino : null;
			return this.portariaService.lancarAcesso({...criterio, destino}).then(() => {
				if (agenda.pessoas.length > ++index) {
					return this.confirmarAcessos(criterio, agenda, index);
				} else {
					this.finalizarAgenda(agenda);
				}
			});
		} else {
			if (agenda.pessoas.length > ++index) {
				return this.confirmarAcessos(criterio, agenda, index);
			} else {
				this.finalizarAgenda(agenda);
			}
		}
	}

	finalizarAgenda = async agenda => {
		if (this.state.agrupamento) {
			this.agendaService.salvar(agenda).then(async agenda => {
				this.updateAgenda(agenda);
				this.agendaService.enviarEmail(agenda);
				this.setState({lockSave: false});
			});
		} else {
			for (const pessoa of agenda.pessoas) {
				await this.agendaService.salvar({...agenda, pessoas: [pessoa]}).then(agenda => {
					this.updateAgenda(agenda);
					this.agendaService.enviarEmail(agenda);
				});
			}
			this.setState({lockSave: false});
		}
	}

	cancelarVisita = () => {
		if (this.state.agenda.pessoas.length) {
			ModalRealm.showConfirmacao("Tem certeza de que deseja cancelar esta visita?", this.resetAgenda);
		} else {
			this.resetAgenda();
		}
	}

	handleChange = (event) => {
		switch (event.name) {
			case "size":
				window.localStorage.setItem("X-Portaria-RowsPerPage", event.value);
				break;
			default:
				break;
		}
		this.setState({[event.name]: event.value, page: 0}, this.listarVisitas);
	}

	handleChangeAgenda = (event) => {
		let agenda = this.state.agenda;
		agenda[event.name] = event.value;
		this.setState({agenda});
	}

	listarPessoas = (event) => {
		this.pessoaFisicaService.listar(`search=nome,rg,cpf,passaporte~${event.query}`, {page: 0, size: 10, sort: "nome"}).then((pessoas) => this.setState({pessoas}));
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {page: 0, size: 10, sort: "descricao"}).then((etiquetas) => this.setState({etiquetas}));
	}

	tabChange = (event) => {
		this.setState({selectedTab: event.index});
		if (event.index === 1) setTimeout(this.listarLogsAcesso, 50);
	}

	removerUnidade = (event, unidade) => {
		event.stopPropagation();
		let agenda = this.state.agenda;
		agenda.unidades = agenda.unidades.filter(u => u.id !== unidade.id);
		agenda.pessoas.forEach(pessoa => {
			if (pessoa.avisos != null) {
				pessoa.avisos = pessoa.avisos.filter(aviso => aviso.etiqueta == null || aviso.etiqueta.id !== unidade.etiqueta.id);
			}
		});
		this.setState({agenda});
	}

	editarUnidade = (unidade) => {
		if (this.securityUnidade.edit) {
			this.unidadeService.buscar(unidade).then((unidade) => {
				ModalRealm.showDialog(<EditarUnidade unidade={unidade} key={Math.random()} onModalClose={(unidade) => {
					let agenda = this.state.agenda;
					agenda.unidades = agenda.unidades.filter(u => u.id !== unidade.id);
					agenda.unidades.push(unidade);
					this.setState({agenda});
				}} />);
			})
		}
	}

	togglePainelAcesso = () => {
		const painelExpanded = !this.state.painelExpanded;
		this.setState({painelExpanded});
		window.localStorage.setItem("safety-painelacesso", painelExpanded);
		setTimeout(() => this.windowResize(), 50);
	}

	togglePainelAcessoMute = () => {
		const painelMute = !this.state.painelMute;
		window.localStorage.setItem("safety-painelacesso-mute", painelMute);
		this.setState({painelMute});
	}

	refLeftPanel = (el) => {
		if (el != null) {
			this.leftPanel = el;
		}
	}

	windowResize = () => {
		if (this.leftPanel != null) {
			this.setState({leftPanelWidth: this.leftPanel.clientWidth});
		}
	}

	switchCategoria = (event) => {
		window.localStorage.setItem("safety-categoria-last", event.value != null ? event.value.id : null);
		let modeloAgenda = event.value ? this.state.modelosAgenda.filter(ma => !ma.value.flags.includes("PORTARIA_ADICIONAL") && ma.label.includes(event.value.descricao))[0] || null : null;
		if (modeloAgenda) {
			modeloAgenda = modeloAgenda.value;
		} else {
			let modeloPortaria = this.state.modelosAgenda.filter((ma) => !ma.value.flags.includes("PORTARIA_ADICIONAL") && ma.label.includes(this.state.portaria.descricao))[0];
			if (modeloPortaria) {
				modeloAgenda = modeloPortaria.value;
			}
		}
		this.setState({categoria: event.value});
		this.switchModeloAgenda({value: modeloAgenda});
	}

	fancyPortaria = (value) => {
		if (value && value.length) {
			if (value.startsWith("$")) {
				let val = value.match(/\$[^+$@#*-]+/g);
				return <div className="fancy-input"><span className="fancy-blob"><img src={iconeVeiculo} alt="" /><span>{val ? val[0].substring(1).toUpperCase() : ""}</span></span></div>;
			} else if (value.startsWith("@")) {
				return <div className="fancy-input"><span className="fancy-blob"><img src={iconeUnidade} alt="" /><span>{value.substring(1)}</span></span></div>;
			} else if (value.startsWith("*")) {
				return <div className="fancy-input"><span className="fancy-blob"><img src={iconeUnidade} alt="" /><span>{value.substring(1)}</span></span></div>;
			} else if (value.startsWith("#")) {
				return <div className="fancy-input"><span className="fancy-blob"><img src={iconeVisitado} alt="" /><span>{value.substring(1)}</span></span></div>;
			} else if (value.startsWith("-")) {
				return <div className="fancy-input"><span className="fancy-blob"><img src={iconeEmpresa} alt="" /><span>{value.substring(1)}</span></span></div>;
			} else {
				const el = [];
				el.push(<span key={0} className="fancy-blob"><img src={iconePessoa} alt="" /><span>{value.match(/^[^+$@#*-]+/g)}</span></span>);
				if (value.includes("+")) {
					let val = value.match(/\+[^+$@#*-]+/g);
					el.push(<span key={1} className="fancy-blob"><span className="fancy-link" /><img src={iconeCartao} alt="" /><span>{val && val.length ? val[0].substring(1) : ""}</span></span>);
				}
				if (value.includes("$")) {
					let val = value.match(/\$[^+$@#*-]+/g);
					el.push(<span key={1} className="fancy-blob"><img src={iconeVeiculo} alt="" /><span>{val && val.length ? val[0].substring(1).toUpperCase() : ""}</span></span>);
				}
				if (value.includes("@")) {
					let val = value.match(/@[^+$@#*-]+/g);
					el.push(<span key={1} className="fancy-blob"><img src={iconeUnidade} alt="" /><span>{val && val.length ? val[0].substring(1).toUpperCase() : ""}</span></span>);
				}
				if (value.includes("*")) {
					let val = value.match(/\*[^+$@#*-]+/g);
					el.push(<span key={1} className="fancy-blob"><img src={iconeUnidade} alt="" /><span>{val && val.length ? val[0].substring(1).toUpperCase() : ""}</span></span>);
				}
				if (value.includes("#")) {
					let val = value.match(/#[^+$@#*-]+/g);
					el.push(<span key={1} className="fancy-blob"><img src={iconeVisitado} alt="" /><span>{val && val.length ? val[0].substring(1).toUpperCase() : ""}</span></span>);
				}
				if (value.includes("-")) {
					let val = value.match(/-[^+$@#*-]+/g);
					el.push(<span key={1} className="fancy-blob"><img src={iconeEmpresa} alt="" /><span>{val && val.length ? val[0].substring(1).toUpperCase() : ""}</span></span>);
				}
				return <div className="fancy-input" onMouseOver={this.fancyFocus}>{el}</div>;
			}
		} else return null;
	}

	switchModeloAgenda = (event) => {
		const modeloAgenda = event.value;
		const agenda = this.state.agenda;
		let permitirDocumento = true;
		if (modeloAgenda && modeloAgenda.flags && agenda.pessoas) {
			if (modeloAgenda.flags.includes("PERMITIR_DOCUMENTO")) {
				agenda.pessoas.forEach((pessoa) => {
					if (!pessoa._documentos) {
						pessoa._documentos = [];
					}
					if (!pessoa._documentos.includes("CPF") && pessoa.cpf?.length) {
						pessoa._documentos.push("CPF");
					}
				});
			} else {
				agenda.pessoas.forEach((pessoa) => {
					if (pessoa._documentos && pessoa._documentos.includes("CPF")) {
						if (!pessoa.docsAsCredenciais.some((d) => d.status === "ATIVADO" && d.flags.includes("DOCUMENTO") && d.codigo === Number(pessoa.cpf.replace(/[^0-9]/g, "")))) {
							pessoa._documentos = pessoa._documentos.filter((d) => d !== "CPF");
						}
					}
				});
			}
			if (modeloAgenda.flags.includes("PERMITIR_DOCUMENTO")) {
				agenda.pessoas.forEach((pessoa) => {
					if (!pessoa._documentos) {
						pessoa._documentos = [];
					}
					if (!pessoa._documentos.includes("RG") && pessoa.rg?.length) {
						pessoa._documentos.push("RG");
					}
				});
			} else {
				agenda.pessoas.forEach((pessoa) => {
					if (pessoa._documentos && pessoa._documentos.includes("RG")) {
						if (!pessoa.docsAsCredenciais.some((d) => d.status === "ATIVADO" && d.flags.includes("DOCUMENTO") && d.codigo === Number(pessoa.rg.replace(/[^0-9]/g, "")))) {
							pessoa._documentos = pessoa._documentos.filter((d) => d !== "RG");
						}
					}
				});
			}
			permitirDocumento = modeloAgenda.flags.includes("PERMITIR_DOCUMENTO");
		}
		this.setState({modeloAgenda, agenda, permitirDocumento});
	}

	excluirAviso = aviso => {
		let agenda = this.state.agenda;
		agenda.pessoas.filter(p => p.id === aviso.pessoa.id).forEach((pessoa) => {
			pessoa.avisos = pessoa.avisos != null ? pessoa.avisos.filter(a => a.id !== aviso.id) : [];
		});
		this.setState({agenda});
	}

	limparVeiculo = (event) => {
		event.stopPropagation();
		let agenda = this.state.agenda;
		agenda._veiculo = null;
		this.setState({agenda});
	}

	editarVeiculo = (veiculo) => {
		const carretas = veiculo.carretas || [];
		ModalRealm.showDialog(<EditarVeiculo veiculo={veiculo} onModalClose={(veiculo) => {
			const {agenda} = this.state;
			veiculo.carretas = carretas;
			agenda._veiculo = veiculo;
			this.setState({agenda});
		}} />);
	}

	editarVeiculoVisita = (veiculo) => {
		ModalRealm.showDialog(<EditarVeiculo veiculo={veiculo} onModalClose={(veiculo) => {
			const {agendas} = this.state;
			agendas.forEach((agenda) => {
				const index = agenda.veiculos.findIndex(v => v.id === veiculo.id);
				if (index > -1) {
					agenda.veiculos[index] = veiculo;
				}
			});
			this.setState({agendas});
		}} />);
	}

	fancyFocus = () => this.setState({fancyVisible: false});

	fancyBlur = () => this.setState({fancyVisible: true});

	fancyRef = (el) => {
		if (el && el.input) {
			el.input.onmouseleave = this.fancyBlur;
			el.input.onkeydown = this.handleKeysPortaria;
		}
		this.superInput = el;
	}

	toggleFancy = () => {
		let habilitarBlocosConsulta = !this.state.habilitarBlocosConsulta;
		window.localStorage.setItem("safety-portaria-habilitarBlocosConsulta", habilitarBlocosConsulta);
		this.setState({habilitarBlocosConsulta});
	}

	detalhesVisitado = [
		<Column style={{width: "70px", textAlign: "center"}} key={0} header="" body={(visitado) => {
			return visitado.ultimaFoto_ ? <img className="logs-acesso-v2-foto visitado" src={toSrcUltimaFoto(visitado.ultimaFoto_, true)} alt=""/> : <span className="logs-acesso-v2-foto visitado sem-foto"/>;
		}} />,
		<Column style={{width: "*"}} key={1} header="Pessoa" body={(visitado) => {
			return (
				<div>
					<div>{visitado.nome}</div>
					<div>{visitado.telefone} {visitado.celular}</div>
					<div>{visitado.lugar?.descricao || "Fora"}</div>
					{!isDentro(visitado) ? <div className="tzm-portaria-visitado-ausente">Visitado ausente</div> : null}
				</div>
			);
		}} />,
		<Column style={{width: "5em", textAlign: "center"}} key={2} header="Ações" body={(visitado) => {
			return (
				<div>
					<img title="Desvincular Visitado" src={iconeRemover} alt="" className="ui-action-icon" onClick={() => this.removerVisitado(visitado)} />
				</div>
			);
		}} />
	];

	removerVisitado = (visitado) => {
		const agenda = this.state.agenda;
		agenda.visitados = agenda.visitados.filter((v) => v.id !== visitado.id);
		this.setState({agenda});
	}

	handleWebsocketFormularios = resposta => {
		if (resposta.documento) {
			let {formularios} = this.state;
			formularios.filter(f => f.id === resposta.id).forEach(async f => {
				const {agenda} = this.state;
				switch (resposta.documento.tipo.aplicacao) {
					case "FISICA":
						let pessoa = await this.pessoaFisicaService.buscar(f.entidade);
						pessoa.documentos.push(resposta.documento);
						pessoa = await this.pessoaFisicaService.salvar(pessoa);
						agenda.pessoas[agenda.pessoas.findIndex(e => e.id === pessoa.id)] = pessoa;
						break;
					case "JURIDICA":
						let empresa = await this.pessoaJuridicaService.buscar(f.entidade);
						empresa.documentos.push(resposta.documento);
						empresa = await this.pessoaJuridicaService.salvar(empresa);
						agenda.pessoas.filter(e => e.empresa?.id === empresa.id).forEach(e => e.empresa = empresa);
						break;
					case "VEICULO":
						let veiculo = await this.veiculoService.buscar(f.entidade);
						veiculo.documentos.push(resposta.documento);
						veiculo = await this.veiculoService.salvar(veiculo);
						agenda._veiculo = veiculo;
						break;
					default:
						agenda.documentos.push(resposta.documento);
						break;
				}
				this.setState({agenda});
			});
			formularios = formularios.filter(f => f.id !== resposta.id);
			this.setState({formularios});
		} else if (resposta.discard) {
			let {agenda, formularios} = this.state;
			formularios.filter(f => f.id === resposta.id).forEach(f => f.entidade.documentos = f.entidade.documentos.filter(d => d._key !== resposta.id));
			formularios = formularios.filter(f => f.id !== resposta.id);
			this.setState({agenda, formularios});
		}
	}

	expiracaoPessoa = (pessoa) => {
		const expiracoes = this.state.setupCondominio.expiracoes;
		let expired = false;
		if (expiracoes && expiracoes.length) {
			expiracoes.filter(e => !e.etiquetas || !e.etiquetas.length).forEach((expiracao) => {
				if (pessoa.ultimaAtualizacao && moment(pessoa.ultimaAtualizacao).add(expiracao.dias, "days").isBefore(moment())) {
					expired = true;
				}
			});
			if (!expired && pessoa.etiquetas && pessoa.etiquetas.length) {
				expiracoes.filter(ex => ex.etiquetas.some(et => pessoa.etiquetas.some(ep => ep.id === et.id))).forEach((expiracao) => {
					if (pessoa.ultimaAtualizacao && moment(pessoa.ultimaAtualizacao).add(expiracao.dias, "days").isBefore(moment())) {
						expired = true;
					}
				});
			}
		}
		return expired;
	}

	toggleAgrupamento = () => {
		const agrupamento = !this.state.agrupamento || this.state.agenda?._veiculo;
		this.setState({agrupamento}, () => {
			window.localStorage.setItem("X-Portaria-Agrupamento", String(agrupamento ? 1 : 0));
		});
	}

	removerEmpresa = () => {
		const {agenda} = this.state;
		agenda.empresa = null;
		this.setState({agenda});
	}

	editarEmpresaAgenda = () => {
		const {agenda} = this.state;
		this.pessoaJuridicaService.buscar(agenda.empresa).then(empresa => {
			ModalRealm.showDialog(<EditarPessoaJuridica pessoa={empresa} onModalClose={empresa => {
				agenda.empresa = empresa;
				this.setState({agenda});
			}}/>);
		});
	}

	render() {
		let portarias = this.state.portarias.map((portaria) => {
			return {label: portaria.descricao, value: portaria};
		});
		let destinos = [];
		if (this.state.equipamento?.dispositivos?.length) {
			destinos.push({label: "Nenhum", value: null});
			destinos.push(...this.state.equipamento.dispositivos.map(d => ({label: d.destino && d.destino.tipo !== "EXTERNO" ? "Lançar entrada para " + d.destino.descricao : "Lançar saída", value: d.destino == null ? {id: -1} : d.destino})));
		}
		return (
			<div className="ui-g">
				<div ref={this.refLeftPanel} className={`ui-g-${this.state.painelExpanded ? "9" : "12"} ui-g-nopad`}>
					<PainelOcorrencia equipamento={this.state.equipamento} onLancarSaida={this.listarVisitas} />
					<ResizeObserver onResize={this.windowResize} />
					<div className="ui-g-5 ui-g-nopad">
						<Panel style={{position: "relative"}} header="Portaria">
							<div className="ui-g">
								<div className="ui-g-12">
									<div className="fancy-portaria-container">
										<SuggestionBox
											appendTo={document.body}
											validate={false}
											autoSelectSuggestion
											ref={this.fancyRef}
											delay={200}
											placeholder="Pesquise por pessoa, credencial, veículo ou unidade"
											autofocus
											onSelect={this.onSelectEntidade}
											id="ui-portaria-autocomplete"
											onChange={this.handlePRPChange}
											name="search"
											itemTemplate={this.entidadeTemplate}
											value={this.state.search}
											disabled={this.state.lockSave}
											suggestions={this.state.entidades}
											completeMethod={this.listarEntidades}
										/>
										<span className={`fancy-input-container${this.state.habilitarBlocosConsulta && this.state.fancyVisible ? "" : " hidden"}`}>{this.fancyPortaria(this.state.search)}</span>
										<div className="fancy-toggler" onClick={this.toggleFancy}><i style={{color: this.state.habilitarBlocosConsulta ? "#9C27B0" : "#000000"}} className="fa fa-search" /></div>
									</div>
								</div>
								{
									this.state.agenda?.pessoas?.length ? (
										<>
											<div className="ui-g-12">
												{this.printMissingDocs("AGENDA", this.state.agenda)}
											</div>
										</>
									) : null
								}
								{
									this.state.agenda?.empresa ? (
										<div className="ui-tzm-portaria-empresa">
											<div className="ui-tzm-portaria-empresa-link" onClick={() => this.securityEmpresa.edit && this.editarEmpresaAgenda()}>
												<label>Cliente/Fornecedor</label>
												<div style={{fontWeight: "bold"}}>{this.state.agenda?.empresa?.nome}</div>
												<label>{this.state.agenda?.empresa?.cnpj?.formatCnpj()}</label>
											</div>
											<If condition={UserData.hasRole("XX2E")}>
												<div className="ui-tzm-portaria-empresa-opselect">
													<SelectButton options={MAgenda.OptionsOperacao} name="operacao" value={this.state.agenda?.operacao} onChange={this.handleChangeAgenda}/>
												</div>
											</If>
											<img src={iconeDesvincular} alt="" title="Remover Empresa" onClick={this.removerEmpresa}/>
										</div>
									) : null
								}
								{
									!ArrayUtils.isEmpty(this.state.agenda.unidades) || this.state.agenda._veiculo ? (
										<div className="ui-g-12" style={{textAlign: "center"}}>
											{
												!ArrayUtils.isEmpty(this.state.agenda.unidades) ? (
													this.state.agenda.unidades.map((unidade) => {
														let telefones = [];
														if (unidade.contato != null) {
															if (!Validator.isEmpty(unidade.contato.telefone)) telefones.push("• " + unidade.contato.telefone);
															if (!Validator.isEmpty(unidade.contato.celular)) telefones.push("• " + unidade.contato.celular);
														}
														const endereco = unidade.contato?.enderecos.filter(e => e.tipo === "PRINCIPAL")[0];
														return (
															<div style={{color: unidade.etiqueta.fonte, backgroundColor: unidade.etiqueta.fundo}} className={`tzm-portaria-unidade-panel ui-pattern-${unidade.etiqueta.padrao} ui-pattern-a${unidade.etiqueta.efeito} ${unidade._expediente ? "unidade-sem-expediente" : ""}`} onClick={() => this.editarUnidade(unidade)}>
																<i title="Remover Unidade" style={{float: "right", cursor: "pointer", margin: "6px 6px 0 0"}} className="fa fa-times" onClick={(event) => this.removerUnidade(event, unidade)} />
																<div style={{lineHeight: "30px"}}>{unidade.etiqueta.icone && unidade.etiqueta.icone.length ? <i className={`ui-etiqueta-icone fa fa-${unidade.etiqueta.icone}`} /> : null}{unidade.etiqueta.descricao}{unidade.flags.filter(f => !["CLOUD"].includes(f)).map((flag) => <span className="tzm-portaria-unidade-flag" key={flag}>{MUnidade.FlagMap[flag]}</span>)}</div>
																<div>{endereco ? `${endereco.logradouro}, ${endereco.numero}` : ""}</div>
																<div>{unidade.contato != null ? `${StringUtils.simplificarNome(unidade.contato.nome)} ${telefones.join(" ")}` : null}</div>
																<div>{unidade._expediente ? "Fora do Horário de Expediente da Unidade" : ""}</div>
																{unidade.modeloAcesso ? <div className="unidade-warning"><i className="fad fa-exclamation-triangle"/> O modelo de acesso desta unidade irá sobrescrever o da portaria!</div> : null}
															</div>
														);
													})
												) : null
											}
											{
												this.state.agenda._veiculo != null ? (
													<div onClick={() => this.editarVeiculo(this.state.agenda._veiculo)} style={{cursor: "pointer", textAlign: "left", position: "relative"}}>
														<i title="Remover Veículo" style={{position: "absolute", top: 0, right: 0, cursor: "pointer", margin: "6px 6px 0 0"}} className="fa fa-times" onClick={this.limparVeiculo} />
														<PlacaMercosul veiculo={this.state.agenda._veiculo} />
														{this.printMissingDocs("VEICULO", this.state.agenda._veiculo)}
													</div>
												) : null
											}
										</div>
									) : null
								}
								<div className="ui-g-12">
									<Label>
										<span className="label-visitantes">
											Visitantes
										</span>
										<div onClick={this.toggleAgrupamento} className={`portaria-agrupamento ${this.state.agrupamento ? "on" : "off"}`}>
											{this.state.agrupamento ? <><i className="fal fa-link"/> Todos na mesma agenda</> : <><i className="fal fa-page-break"/> Cada um em uma agenda</>}
										</div>
									</Label>
									<DataTable emptyMessage="Nenhuma pessoa adicionada" value={this.state.agenda.pessoas} children={this.detalhesVisita} />
									{
										this.state.agenda.visitados && this.state.agenda.visitados.length ? (
											<div>
												<div style={{height: "4px"}} />
												<Label>Visitados</Label>
												<DataTable emptyMessage="Nenhuma pessoa adicionada" value={this.state.agenda.visitados} children={this.detalhesVisitado} />
											</div>
										) : null
									}
								</div>
								<div className="ui-g-6"><Label>Categoria</Label><Combobox options={this.state.categorias} value={this.state.categoria} onChange={this.switchCategoria} name="categoria" /></div>
								<div className="ui-g-6"><Label>Modelo de Agenda</Label><Combobox options={this.state.modelosAgenda} value={this.state.modeloAgenda} onChange={this.switchModeloAgenda} name="modeloAgenda" /></div>
								<div className="ui-g-5"><Label>Portaria</Label><Combobox disabled={this.state.portarias.length === 1} value={this.state.portaria} onChange={e => this.switchPortaria(e)} name="portaria" options={portarias} field="descricao" /></div>
								<div className="ui-g-7"><Label>Acesso Manual</Label><Combobox disabled={destinos.length < 2} name="destino" onChange={this.handleDestino} value={this.state.destino} options={destinos} /></div>
								{UserData.hasRole("XX1C") ? <MultiCombobox grid={12} options={this.state.modelosAdicionais} name="adicionais" value={this.state.adicionais} label="Acessos Adicionais" onChange={this.handlePRChange}/> : null}
								<TextField name="observacao" grid={12} multiline onChange={this.handleChangeAgenda} label="Observações" style={{height: "6em"}} value={this.state.agenda.observacao} />
							</div>
							<PanelFooter>
								{UserData.hasRole("XX0V") ? <div className={`modo-emergencia ${this.state.portaria?.status === "EMERGENCIA" ? " active" : ""}`}><Action onClick={this.toggleModoEmergencia} mode={this.state.portaria?.status === "EMERGENCIA" ? "delete" : "post"} style={{float: "left"}} icon="fad fa-radiation-alt" label="Emergência"/></div> : null}
								<Action
									mode="post"
									disabled={!this.state.agenda.pessoas.length || this.state.lockSave || this.bloquearVisitaPorVisitadoFora()}
									icon="fa-check"
									label="Confirmar"
									onClick={this.confirmarVisita}
								/>
								<Action mode="secondary" disabled={!this.state.agenda.pessoas.length} icon="fa-times" label="Cancelar" onClick={this.cancelarVisita} />
							</PanelFooter>
							{(!this.state.equipamento || !this.state.equipamento.id) && !UserData.hasRole("XX0R") ? (
								<div className="ui-new-equipamento-portaria">
									Este equipamento não está habilitado para o uso da portaria.<br />
									<span onClick={this.cadastrarEquipamento}>Clique aqui para cadastrá-lo.</span>
								</div>
							) : null}
							{this.state.lockSave ? <div style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, .2)"}}/> : null}
						</Panel>
					</div>
					<div className="ui-g-7 ui-g-nopad ui-g-leftpad">
						<Panel header={
							<div>
								Visitas
								{this.state.lock ? <i className="portaria-lock fa fa-lock" /> : null}
								{this.state.lockAgendas.length ? <i className="portaria-lock fa fa-sparkles" /> : null}
							</div>
						}>
							<div className="ui-g">
								<DatePicker grid={3} label="Data" name="data" value={this.state.data} onChange={this.handleChange}/>
								<Combobox options={MAgenda.StatusPortaria} grid={3} value={this.state.status} name="status" onChange={this.handleChange} label="Status"/>
								<MultiSelectEtiqueta value={this.state.etiquetas} grid={6} onSelect={this.handleChange} name="etiquetas" label="Etiquetas"/>
								<TextField grid={7} label="Pesquisa" placeholder="Pesquise por nome ou documento do visitante" type="alphanumeric" value={this.state.pesquisa} name="pesquisa" onChange={this.handleChange}/>
								<SelectCredencial grid={5} label="Credencial" name="credencial" value={this.state.credencial} onChange={this.handleChange}/>
								<TextField grid={3} label="Placa" type="alphanumeric" value={this.state.placa?.toUpperCase()} name="placa" onChange={this.handleChange}/>
								<TextField grid={7} label="Modelo" placeholder="Pesquise pelo modelo do veículo" type="alphanumeric" value={this.state.modelo} name="modelo" onChange={this.handleChange}/>
								<Combobox grid={2} label="Quantidade" value={this.state.size} name="size" onChange={this.handleChange} options={rowsPerPageOptions}/>
							</div>
						</Panel>
						<div className="ui-tzm-panel-separator" />
						<div onMouseOver={this.lockVisitas} onMouseOut={this.unlockVisitas} className="visitas-portaria ui-g-12 ui-g-nopad">
							<DataTable style={{marginRight: "1px"}}
									   rows={this.state.size}
									   value={this.state.agendas}
									   children={this.detalhesVisitas}
									   emptyMessage="Nenhuma visita encontrada"
									   footer={
										   <>
											   <div style={{float: "left"}}>{this.opcoesVisita}</div>
											   <Button disabled={this.state.lockList || !this.state.page} style={{margin: "2px"}} icon="fa fa-step-backward" onClick={() => {
												   this.setState({page: 0}, () => this.listarVisitas(undefined, true));
											   }}/>
											   <Button disabled={this.state.lockList || !this.state.page} style={{margin: "2px"}} icon="fa fa-backward" onClick={() => {
												   this.setState({page: Math.max(0, this.state.page - 1)}, () => this.listarVisitas(undefined, true));
											   }}/>
											   {this.printPages()}
											   <Button disabled={this.state.lockList || this.state.agendas?.length < this.state.size} style={{margin: "2px"}} icon="fa fa-forward" onClick={() => {
												   this.setState({page: this.state.page + 1}, () => this.listarVisitas(undefined, true));
											   }}/>
											   <Button disabled={this.state.lockList || !this.state.agendas?.length || this.state.page >= Math.floor(this.state.totalAgendas / this.state.size)} style={{margin: "2px"}} icon="fa fa-step-forward" onClick={() => {
												   this.setState({page: Math.floor(this.state.totalAgendas / this.state.size)}, () => this.listarVisitas(undefined, true));
											   }}/>
										   </>
									   }
							/>
						</div>
						<SafetyWebSocket path="/agendas" onMessage={this.handleWebsocketVisitas}/>
						<SafetyWebSocket path="/formularios" onMessage={this.handleWebsocketFormularios}/>
					</div>
					<div className="ui-g-12 ui-g-nopad"><div className="ui-tzm-floatbutton-margin" /></div>
				</div>
				{this.state.portaria != null ? <PainelAcesso onUpdate={this.handleUpdateAcessos} mute={this.state.painelMute} style={{width: `${this.state.leftPanelWidth / 3 + 21}px`}} portaria={this.state.portaria} onToggle={this.togglePainelAcesso} onToggleMute={this.togglePainelAcessoMute} expanded={this.state.painelExpanded} /> : null}
				<Shortcut
					onCtrlS={() => window.location.pathname === "/portaria" && this.state.agenda.pessoas.length > 0 && this.confirmarVisita()}
					onEscape={() => window.location.pathname === "/portaria" && this.cancelarVisita()}
				/>
				<ModalRealm/>
			</div>
		);
	}

	printPages = () => {
		const pages = [];
		let lastPage = Math.floor(this.state.totalAgendas / this.state.size);
		let firstPage = Math.max(this.state.page - 2, 0);
		while (firstPage && firstPage + 4 > lastPage)
			--firstPage;
		for (let i = firstPage; i <= Math.min(firstPage + 4, lastPage); ++i) {
			pages.push(
				<span onClick={() => {
					this.setState({page: i}, () => this.listarVisitas(null, true));
				}} key={i} className={`page-counter ${this.state.page === i ? "disabled" : ""}`}>{i + 1}</span>
			);
		}
		return pages;
	}

	handleUpdateAcessos = (logAcesso) => {
		if (logAcesso.pessoa) {
			let {agendas, lockAgendas} = this.state;
			atualizarPosicao(agendas, logAcesso);
			atualizarPosicao(lockAgendas, logAcesso);
			if (!logAcesso.destino || logAcesso.destino.tipo === "EXTERNO") {
				agendas = filtrarAgendasEncerradas(agendas, logAcesso);
				lockAgendas = filtrarAgendasEncerradas(lockAgendas, logAcesso);
			}
			this.setState({agendas, lockAgendas});
		}
	}

	lockVisitas = () => {
		if (this.lockTimeout) {
			clearTimeout(this.lockTimeout);
		}
		this.setState({lock: true});
	}

	unlockVisitas = () => {
		this.lockTimeout = setTimeout(() => {
			if (this.state.lockAgendas.length) {
				let {agendas, lockAgendas} = this.state;
				agendas.unshift(...lockAgendas);
				lockAgendas.length = 0;
				agendas.length = Math.min(agendas.length, 50);
				agendas = agendas.sort((a, b) => b.id - a.id);
				this.setState({agendas, lockAgendas, lock: false});
			} else {
				this.setState({lock: false});
			}
		}, 500);
	}

	handleWebsocketVisitas = (agenda) => {
		if ((!this.state.status || this.state.status === agenda.status) && this.state.portaria && !this.state.portaria.etiquetas.every(e => agenda.etiquetas.some(t => t.id === e.id))) {
			return;
		}
		if (this.state.lock) {
			let {lockAgendas} = this.state;
			lockAgendas = lockAgendas.filter(a => a.id !== agenda.id);
			lockAgendas.unshift(agenda);
			this.setState({lockAgendas});
		} else {
			let {agendas} = this.state;
			agendas = agendas.filter(a => a.id !== agenda.id);
			agendas.unshift(agenda);
			agendas.length = Math.min(agendas.length, 50);
			agendas = agendas.sort((a, b) => b.id - a.id);
			this.setState({agendas});
			this.buscarCredencial(agenda);
		}
	}

	handleKeysPortaria = (event, pessoa) => {
		switch (event.keyCode) {
			case 9:
				pessoa = pessoa || {id: 0};
				let startIndex = this.state.agenda.pessoas.findIndex(p => p.id === pessoa.id);
				if (startIndex === 0 && event.shiftKey) {
					this.superInput.input.focus();
					event.preventDefault();
					return false;
				}
				startIndex = event.shiftKey ? (startIndex > 0 ? startIndex - 1 : this.state.agenda.pessoas.length - 1) : startIndex + 1;
				while (startIndex < this.state.agenda.pessoas.length) {
					let item = this.state.agenda.pessoas[startIndex];
					if (item) {
						if (item.credencial && item.credencial.id) {
							startIndex += event.shiftKey ? -1 : 1;
						} else {
							const el = document.getElementById(`kVisCrd${item.id}`);
							if (el && el.firstElementChild) {
								el.firstElementChild.focus();
								event.preventDefault();
								return false;
							}
						}
					} else {
						this.superInput.input.focus();
						event.preventDefault();
						return false;
					}
				}
				if (this.superInput && this.superInput.input) {
					this.superInput.input.focus();
					event.preventDefault();
					return false;
				}
				break;
			default:
		}
	}

}

export class VCard extends React.Component {

	render() {
		if (this.props.active) {
			// let fotos = this.props.pessoa.fotos ? this.props.pessoa.fotos.filter(isFinalidadeIdentificacao).sort(byRegistroDesc) : [];
			let foto = toSrcUltimaFoto(this.props.pessoa.ultimaFoto_, true);
			return (
				<div className="ui-vcard-container">
					<table>
						<tbody>
						<tr>
							<td style={{width: "116px"}}>
								{foto ? <img className="logs-acesso-v2-foto vcard" src={foto} alt=""/> : <span className="logs-acesso-v2-foto vcard sem-foto"/>}
							</td>
							<td style={{width: "*"}}>
								<div className="ui-vcard-nome">{this.props.pessoa.nome}</div>
								<div className="ui-vcard-dados">
									<div>
										{
											this.props.pessoa.cpf ? `CPF ${this.props.pessoa.cpf.formatCpf()}`
												: this.props.pessoa.rg ? `RG ${this.props.pessoa.rg}`
												: this.props.pessoa.nis ? `NIS ${this.props.pessoa.nis}`
													: `Documento ${this.props.pessoa.passaporte}`
										}
									</div>
									<div>
										{this.props.pessoa.telefone ? <span><i className="fa fa-phone" /> {this.props.pessoa.telefone}</span> : null}
										{this.props.pessoa.telefone && this.props.pessoa.celular ? " • " : ""}
										{this.props.pessoa.celular ? <span><i className="fa fa-phone" /> {this.props.pessoa.celular}</span> : null}
									</div>
									<div>{isDentroOuGaragem(this.props.pessoa) ? `Em ${this.props.pessoa.lugar.descricao} desde ${moment(this.props.pessoa.ultimaPassagem).format("DD/MM/YY HH:mm")}` : `Fora${this.props.pessoa.ultimaPassagem ? ` desde ${moment(this.props.pessoa.ultimaPassagem).format("DD/MM/YYYY HH:mm")}` : ""}`}</div>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			);
		} else return null;
	}

}

function atualizarPosicao(agendas, logAcesso) {
	for (const agenda of agendas) {
		for (const pessoa of agenda.pessoas) {
			if (pessoa.id === logAcesso.pessoa.id) {
				pessoa.lugar = logAcesso.destino;
			}
		}
	}
}

function filtrarAgendasEncerradas(agendas, logAcesso) {
	return agendas.filter(agenda => {
		if (!agenda.flags.includes("COLETOR_LIVRE") && agenda.pessoas.some(ap => ap.id === logAcesso.pessoa.id)) {
			return agenda.pessoas.some(ap => ap.lugar?.tipo !== "EXTERNO");
		}
		return true;
	});
}
