import React from "react";
import {ModalRealm} from "../components/commons/ModalRealm";
import {EditarEtiqueta} from "../components/Etiqueta/EditarEtiqueta";
import {EtiquetaService} from "../components/servicos/EtiquetaService";
import {MEtiqueta} from "../utils/models/MEtiqueta";
import {UserData} from "../utils/UserData";
import {Select} from "./Select";

export class SelectEtiqueta extends React.Component {

	service = new EtiquetaService();

	security = UserData.security("ETQ");

	onSelect = (event) => {
		if (event.value != null && event.value.id === -1) {
			ModalRealm.showDialog(<EditarEtiqueta key={Math.random()} etiqueta={{...MEtiqueta.Modelo(), descricao: event.value.descricao.replace(`Criar etiqueta `, "")}} onModalClose={(entidade) => {
				this.props.onChange({name: this.props.name, value: entidade});
				if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: entidade, index: this.props.index});
			}} />);
		} else {
			this.props.onChange({name: this.props.name, value: event.value});
			if (this.props.onSelect) this.props.onSelect({name: this.props.name, value: event.value, index: this.props.index});
		}
	}

	render() {
		return <Select entidade="etiqueta" insertable={this.security.create && this.props.insertable} service={this.service} field="descricao" query="status:ATIVADO;descricao" onSelect={this.onSelect} {...this.props} />;
	}

}
