import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MCredencial {

	static Modelo() {
		return {
			descricao: "",
			forma: "MANUAL",
			codigo: 0,
			_wiegand: 0,
			itens: [],
			flags: [],
			automatico: false,
			origem: null
		};
	}

	static cleanupBeforeSave(credencial) {
		if (credencial.itens != null) credencial.itens = credencial.itens.filter(i => Validator.isEntidadeDependente(i));
		if (!Validator.isEntidadeDependente(credencial.pessoa)) delete credencial.pessoa;
		return MEntidade.cleanupBeforeSave(credencial);
	}

	static validarParaSalvar(credencial) {
		let messages = [];
		if (Validator.isEmpty(credencial.descricao)) messages.push("A descrição é obrigatória");
		if (!credencial.codigo) messages.push("O código é obrigatório");
		return messages;
	}

	static FormasIntransferiveis = [
		"MANUAL", "DIGITAL", "FACIAL", "SENHA", "LPR", "HANDKEY", "ID", "WIEGAND_26"
	];

	static Formas = [
		{label: "Manual", value: "MANUAL"},
		{label: "Senha", value: "SENHA"},
		{label: "Proximidade", value: "PROXIMIDADE"},
		{label: "Digital", value: "DIGITAL"},
		{label: "Controle", value: "CONTROLE"},
		{label: "Tag", value: "TAG"},
		{label: "Barras", value: "BARRAS"},
		{label: "Facial", value: "FACIAL"},
		{label: "Handkey", value: "HANDKEY"},
		{label: "ID", value: "ID"},
		{label: "LPR", value: "LPR"},
		{label: "Automática", value: "AUTOMATICA"},
		{label: "Wiegand 26", value: "WIEGAND_26"},
		{label: "QR Code", value: "QRCODE"},
	].sort((a, b) => a.label.localeCompare(b.label));

	static MapFormas = {
		MANUAL: "Manual",
		SENHA: "Senha",
		PROXIMIDADE: "Proximidade",
		DIGITAL: "Digital",
		CONTROLE: "Controle",
		TAG: "Tag",
		BARRAS: "Barras",
		FACIAL: "Facial",
		HANDKEY: "Handkey",
		ID: "ID",
		LPR: "LPR",
		AUTOMATICA: "Automática",
		WIEGAND_26: "Wiegand 26",
		QRCODE: "QR Code"
	};

	static Flags = [
		{label: "Todas", value: null},
		{label: "Temporária", value: "TEMPORARIA"},
		{label: "Master", value: "MASTER"},
		{label: "Documento", value: "DOCUMENTO"}
	];

	static Item = {

		Flags: [
			{label: "Emergência", value: "EMERGENCIA"}
		],

		MapFormatos: {
			BITMAP: "Bitmap",
			NEOKOROS: "Neokoros",
			CONTROLID: "ControlID",
			ANVIZ: "Anviz",
		}

	}

}

export const optionsTecnologias = [
	{label: "MiFare", value: "MIFARE"},
	{label: "ASK 125kHz", value: "ASK_125"}
];

export const labelTecnologias = {
	MIFARE: "MiFare",
	ASK_125: "ASK 125kHz"
};
