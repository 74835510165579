import saveAs from "file-saver";
import moment from "moment";
import {Column} from "primereact/components/column/Column";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Panel} from "primereact/components/panel/Panel";
import {TabPanel, TabView} from "primereact/components/tabview/TabView";
import React from "react";
import ReactJson from "react-json-view";
import iconFalha from "../../media/icones/eliminar.png";
import iconSucesso from "../../media/icones/ok.png";
import {MultiSelectEtiqueta} from "../../select/MultiSelectEtiqueta";
import {SelectCredencial} from "../../select/SelectCredencial";
import {SelectEspaco} from "../../select/SelectEspaco";
import {SelectLugar} from "../../select/SelectLugar";
import {SelectPessoaFisica} from "../../select/SelectPessoaFisica";
import {SelectPessoaJuridica} from "../../select/SelectPessoaJuridica";
import {LocalDateTime} from "../../utils/DateUtils";
import {Fetch} from "../../utils/Fetch";
import {
	MLogAcesso,
	optionsAcessosAtivos,
	optionsDiasSemanaExt,
	optionsModoFiltroDataHora
} from "../../utils/models/MLogAcesso";
import {MOcorrencia} from "../../utils/models/MOcorrencia";
import {Action} from "../commons/Action";
import {Combobox} from "../commons/Combobox";
import {DatePicker} from "../commons/DatePicker";
import {ModalRealm} from "../commons/ModalRealm";
import {MultiCombobox} from "../commons/MultiCombobox";
import {PanelContent} from "../commons/PanelContent";
import {TabPanelFooter} from "../commons/PanelFooter";
import {EquipamentoService} from "../servicos/EquipamentoService";
import {EtiquetaService} from "../servicos/EtiquetaService";
import {LugarService} from "../servicos/LugarService";
import {PessoaFisicaService} from "../servicos/PessoaFisicaService";
import {PessoaJuridicaService} from "../servicos/PessoaJuridicaService";
import {labelRelatorio, relatoriosCacique, RelatorioService, relatoriosGeral} from "../servicos/RelatorioService";
import {UsuarioService} from "../servicos/UsuarioService";
import {CheckSquare} from "../commons/CheckSquare";
import {Label} from "../commons/Label";
import {httpGet} from "../../utils/Request";
import {UserData} from "../../utils/UserData";
import {whatBrowser} from "../../utils/BrowserUtils";
import {TextField} from "../commons/TextField";
import {MultiSelectEquipamento} from "../../select/MultiSelectEquipamento";
import {RelatorioProgramadoService} from "../servicos/RelatorioProgramadoService";
import {EditarRelatorioProgramado} from "./EditarRelatorioProgramado";
import iconeEditar from "../../media/icones/editar.png";
import iconeExcluir from "../../media/icones/excluir.png";
import cronstrue from "cronstrue/i18n";
import {SelectButton} from "../commons/SelectButton";

const basePath = process.env.REACT_APP_API_URL;
const reportPath = process.env.REACT_APP_REPORT_URL;
const customFlags = process.env.REACT_APP_CUSTOM_FLAGS || "";

export class Relatorio extends React.Component {

	state = {
		atividades: ["PASSAGEM"],
		respostas: [],
		etiquetas: [],
		inicio: LocalDateTime.atStartOfDayISO(),
		fim: LocalDateTime.atEndOfDayISO(),
		sort: ["data desc"],
		periodos: [
			"CEIA",
			"DESJEJUM",
			"ALMOCO",
			"JANTAR"
		],
		activeIndex: 0,
		modoEtiqueta: "LOG_ACESSO",
		lock: 0,
		usuario: "",
		relatorio: UserData.hasRole("XXAR") ? {index: 104, path: "/logs-acesso/acessos", nome: "Detalhamento de Acesso"} : null,
		formato: "PDF",
		consolidarPeriodos: true,
		pessoa: null,
		gestor: null,
		lockGestor: false,
		modosEtiqueta: [
			{label: "Em Pessoa", value: "PESSOA"},
			{label: "Em Registro de Acesso", value: "LOG_ACESSO"},
			{label: "Em Equipamento", value: "EQUIPAMENTO"}
		],
		turno: "",
		agendamentos: [],
		diasSemana: ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"],
		modoHorario: "PERIODO",
		acessoAtivo: null
	};

	relatorioService = new RelatorioService();
	pessoaFisicaService = new PessoaFisicaService();
	pessoaJuridicaService = new PessoaJuridicaService();
	etiquetaService = new EtiquetaService();
	lugarService = new LugarService();
	equipamentoService = new EquipamentoService();
	usuarioService = new UsuarioService();
	relatorioProgramadoService = new RelatorioProgramadoService();

	relatoriosRefeicao = [1151, 1152, 1153, 1154, 1155, 1156];

	securityRelatorioProgramado = UserData.security("RPR");

	componentDidMount() {
		this.usuarioService.me().then((usuario) => this.setState({usuario: usuario.login}));
		this.relatorioService.registrosEmissao().then((registros) => this.setState({registros}));
		const gestor = UserData.operador?.pessoa;
		if (gestor?.id) {
			httpGet(`/pessoas-fisicas?gestor=id:${gestor.id}&page=0&size=1`).then(subordinados => {
				if (subordinados?.length) {
					this.setState({gestor, lockGestor: true});
				}
			});
		}
	}

	listarPessoas = (event) => {
		this.pessoaFisicaService.listar(`search=nome,rg,cpf,passaporte~${event.query}`, {page: 0, size: 10, sort: "nome"}).then((pessoas) => this.setState({pessoas}));
	}

	listarEtiquetas = (event) => {
		this.etiquetaService.listar(`search=descricao~${event.query}`, {page: 0, size: 10, sort: "descricao"}).then((setiquetas) => this.setState({setiquetas}));
	}

	handleChangeModosEtiqueta = (relatorio) => {
		let modosEtiqueta = [];
		if (relatorio.index !== 103) modosEtiqueta.push({label: "Em Pessoa", value: "PESSOA"});
		if (relatorio.index !== 102) modosEtiqueta.push({label: "Em Registro de Acesso", value: "LOG_ACESSO"});
		if (relatorio.index === 104) modosEtiqueta.push({label: "Em Equipamento", value: "EQUIPAMENTO"});
		let modoEtiqueta;
		switch (relatorio.index) {
			case 103:
				modoEtiqueta = 'LOG_ACESSO'
				break;
			default:
				modoEtiqueta = 'PESSOA'
				break;
		}
		this.setState({modosEtiqueta, modoEtiqueta});
	}

	handleChange = (event) => {
		if (event.name === "relatorio") {
			this.handleChangeModosEtiqueta(event.value);
		}
		this.setState({[event.name]: event.value});
	}

	handleFilterChange = (event) => {
		let sort = this.state.sort;
		if (event.value.includes("data") && event.value.includes("data desc")) {
			if (sort.includes("data")) {
				event.value = event.value.filter(v => v !== "data");
			} else {
				event.value = event.value.filter(v => v !== "data desc");
			}
		}
		sort = event.value;
		this.setState({sort});
	}

	postReport = () => {
		const {isFirefox} = whatBrowser();
		this.setState({lock: true});
		const {path, nome} = this.state.relatorio;
		const params = Object.assign({}, this.state);
		switch (path) {
			case "/reservas":
				params.sort = ["espaco.descricao", ...params.sort, "periodo.inicio"];
				break;
			default:
				break;
		}
		if (this.relatoriosRefeicao.includes(params.relatorio.index)) {
			params.formato = "CSV";
		}
		Object.keys(params).forEach(k => params[k] === "" && delete params[k]);
		Fetch.Post(`${reportPath}${path}`, params).then((response) => response.blob().then((blob) => {
			this.setState({lock: false});
			const file = new Blob([blob], {type: `application/${this.switchFormato()}`});
			if (!file.size) {
				ModalRealm.showInformacao("warn", ["Nenhum registro encontrado"]);
			} else {
				const data = window.URL.createObjectURL(file);
				saveAs(data, `${nome} - ${moment().format("YYYYDDMMHHmmss")}.${this.switchFormato()}`);
				if (isFirefox || this.relatoriosRefeicao.includes(this.state.relatorio?.index) || this.state.formato === "CSV")
					return;
				window.open(data); 
			}
		})).catch(() => {
			this.setState({lock: false});
		});
	}

	switchFormato = () => {
		if (this.relatoriosRefeicao.includes(this.state.relatorio?.index)) {
			return "csv";
		}
		switch (this.state.formato) {
			case "CSV":
				return "csv";
			default:
				return "pdf";
		}
	}

	periodos = [
		{label: "Ceia", value: "CEIA"},
		{label: "Desjejum", value: "DESJEJUM"},
		{label: "Almoço", value: "ALMOCO"},
		{label: "Jantar", value: "JANTAR"}
	];

	ordenacoes = [
		{label: "Data (Mais Antigo)", value: "data"},
		{label: "Data (Mais Recente)", value: "data desc"},
		{label: "Pessoa", value: "pessoa.nome"},
		{label: "Lugar", value: "destino.descricao"},
		{label: "Equipamento", value: "equipamento.descricao"},
		{label: "Empresa", value: "empresa.nome"}
	];

	buscarAgendamentosRelatorio = () => {
		this.relatorioProgramadoService.listar("", {page: 0, size: 100}).then(agendamentos => this.setState({agendamentos}));
	}

	tabChange = (event) => {
		this.setState({activeIndex: event.index}, () => {
			switch (event.index) {
				case 2:
					this.buscarAgendamentosRelatorio();
					break;
				default:
					break;
			}
		});
	}

	hasRequired = () => {
		if (this.relatoriosRefeicao.includes(this.state.relatorio?.index)) {
			return this.state.inicio?.length && this.state.fim?.length && this.state.lugar?.id;
		}
		return true;
	}

	criarAgendamento = () => {
		ModalRealm.showDialog(<EditarRelatorioProgramado criterio={{...this.state, relatorio: this.state.relatorio?.index}} onModalClose={this.buscarAgendamentosRelatorio}/>);
	}

	editarAgendamento = async agendamento => {
		ModalRealm.showDialog(<EditarRelatorioProgramado agendamento={await this.relatorioProgramadoService.buscar(agendamento)} onModalClose={this.buscarAgendamentosRelatorio}/>);
	}

	excluirAgendamento = agendamento => {
		ModalRealm.showConfirmacao("Tem certeza de que deseja excluir este agendamento?", () => {
			this.relatorioProgramadoService.excluir(agendamento).then(this.buscarAgendamentosRelatorio);
		});
	}

	render() {
		let relatorios = [...relatoriosGeral];
		if (customFlags.split(",").includes("CACIQUE")) {
			relatorios = relatorios.concat(relatoriosCacique);
		}
		relatorios = relatorios.filter(r => UserData.hasRole(r.role));
		return (
			<div>
				<Panel header="Relatórios">
					<TabView onTabChange={this.tabChange} activeIndex={this.state.activeIndex}>
						<TabPanel header="Filtros de Impressão">
							<PanelContent className="report-filters">
								<Combobox grid={11} label={<label style={{fontSize: "12px"}}>Relatório<span style={{fontSize: "12px", float: "right"}}>Nota: campos acinzentados representam filtros que não se aplicam ao relatório selecionado</span></label>} options={relatorios} onChange={this.handleChange} name="relatorio" value={this.state.relatorio} />
								<Combobox disabled={this.relatoriosRefeicao.includes(this.state.relatorio?.index)} onChange={this.handleChange} options={this.formatos} grid={1} name="formato" value={this.state.formato} label="Formato"/>
								<DatePicker required={this.relatoriosRefeicao.includes(this.state.relatorio?.index)} disabled={[101, 102, 103, 109, 110, 1157].includes(this.state.relatorio?.index)} label="Período" grid={2} showTime showSeconds value={this.state.inicio} name="inicio" onChange={this.handleChange} />
								<DatePicker required={this.relatoriosRefeicao.includes(this.state.relatorio?.index)} disabled={[101, 102, 103, 109, 1157].includes(this.state.relatorio?.index)} label="&nbsp;" grid={2} showTime showSeconds value={this.state.fim} name="fim" onChange={this.handleChange} />
								<Combobox options={optionsModoFiltroDataHora} grid={2} label="&nbsp;" value={this.state.modoHorario} disabled={[101, 102, 103, 109, 110, 1157].includes(this.state.relatorio?.index)} name="modoHorario" onChange={this.handleChange}/>
								<SelectButton multiple grid={4} name="diasSemana" options={optionsDiasSemanaExt} label="Dias da Semana" value={this.state.diasSemana} onChange={this.handleChange} disabled={[101, 102, 103, 109, 110, 1157].includes(this.state.relatorio?.index)}/>
								<Combobox disabled={this.state.relatorio?.index !== 110} options={optionsAcessosAtivos} grid={2} label="Status do Acesso" name="acessoAtivo" value={this.state.acessoAtivo} onChange={this.handleChange}/>
								<SelectPessoaFisica disabled={[101, ...this.relatoriosRefeicao, 109, 1157].includes(this.state.relatorio?.index)} grid={7} label="Pessoa" value={this.state.pessoa} placeholder="Pesquise por nome, RG, CPF ou outro documento" onChange={this.handleChange} name="pessoa" />
								<SelectPessoaJuridica disabled={[101, ...this.relatoriosRefeicao, 109].includes(this.state.relatorio?.index)} grid={5} label="Empresa" value={this.state.empresa} placeholder="Pesquise por razão, CNPJ ou fantasia" onChange={this.handleChange} name="empresa" />
								<MultiSelectEtiqueta rightLabel="Todas (ex.: etiquetas de unidade)" disabled={[101, ...this.relatoriosRefeicao, 109, 110, 105,  106, 107, 108].includes(this.state.relatorio?.index)} grid={5} label="Etiquetas" value={this.state.etiquetasE} onSelect={this.handleChange} name="etiquetasE" />
								<MultiSelectEtiqueta rightLabel="Qualquer uma (ex.: etiquetas de categoria [funcionários, terceiros, visitantes])" disabled={[101, ...this.relatoriosRefeicao, 109, 110, 105].includes(this.state.relatorio?.index)} grid={5} label="&nbsp;" value={this.state.etiquetasOU} onSelect={this.handleChange} name="etiquetasOU" />
								<Combobox disabled={[101, 106, 107, ...this.relatoriosRefeicao, 109, 110].includes(this.state.relatorio?.index)} label="&nbsp;" grid={2} name="modoEtiqueta" value={this.state.modoEtiqueta} onChange={this.handleChange} options={this.state.modosEtiqueta} />
								<SelectCredencial disabled={[107, 108, ...this.relatoriosRefeicao, 109, 110, 1157].includes(this.state.relatorio?.index)} placeholder="Pesquise pela descrição" grid={3} label="Credencial" value={this.state.credencial} onChange={this.handleChange} name="credencial" />
								<SelectEspaco disabled={[101, 102, 103, 104, 105, 106, 108, ...this.relatoriosRefeicao, 109, 110, 1157].includes(this.state.relatorio?.index)} label="Espaço" grid={2} placeholder="Pesquise pela descrição" value={this.state.espaco} onChange={this.handleChange} name="espaco" />
								<SelectLugar required={this.relatoriosRefeicao.includes(this.state.relatorio?.index)} disabled={[101, 107, 108, 109, 110].includes(this.state.relatorio?.index)} grid={3} label="Lugar" placeholder="Pesquise pela descrição" value={this.state.lugar} onChange={this.handleChange} name="lugar" />
								<MultiCombobox disabled={this.state.relatorio?.index !== 1154} label="Refeições" grid={3} defaultLabel="Todas" name="periodos" value={this.state.periodos} options={this.periodos} onChange={this.handleChange} />
								<div className="ui-g-1">
									<Label>&nbsp;</Label>
									<CheckSquare disabled={this.state.relatorio?.index !== 1154} checked={this.state.consolidarPeriodos} name="consolidarPeriodos" onChange={this.handleChange} label="Consolidar"/>
								</div>
								<MultiSelectEquipamento disabled={[101, 107, ...this.relatoriosRefeicao, 110, 1157].includes(this.state.relatorio?.index)} label="Equipamentos" grid={4} placeholder="Pesquise pela descrição ou IP" value={this.state.equipamentos} onSelect={this.handleChange} name="equipamentos" />
								<MultiCombobox disabled={[101, 106, 107, 108, ...this.relatoriosRefeicao, 109, 110, 1157].includes(this.state.relatorio?.index)} label="Atividades" grid={4} defaultLabel="Todas" name="atividades" value={this.state.atividades} options={MLogAcesso.Atividades} onChange={this.handleChange} />
								<MultiCombobox disabled={[101, 106, 107, 108, ...this.relatoriosRefeicao, 109, 110, 1157].includes(this.state.relatorio?.index)} label="Respostas" grid={4} defaultLabel="Todas" name="respostas" value={this.state.respostas} options={MLogAcesso.Respostas} onChange={this.handleChange} />
								<MultiCombobox disabled={[101, 107, 108, ...this.relatoriosRefeicao, 109, 110, 1157].includes(this.state.relatorio?.index)} grid={4} label="Eventos" defaultLabel="Todos" name="eventos" value={this.state.eventos} options={MOcorrencia.Eventos} onChange={this.handleChange} />
								<SelectPessoaFisica disabled={this.state.lockGestor || [101, ...this.relatoriosRefeicao, 109, 110, 1157].includes(this.state.relatorio?.index)} grid={3} label="Gestor" value={this.state.gestor} placeholder="Pesquise por nome, RG, CPF ou outro documento" onChange={this.handleChange} name="gestor" />
								<TextField disabled={this.state.relatorio?.index !== 1157} grid={2} label="Turno" name="turno" value={this.state.turno} onChange={this.handleChange}/>
								<MultiCombobox disabled={[102, 108, ...this.relatoriosRefeicao, 109, 110, 1157].includes(this.state.relatorio?.index)} grid={3} label="Ordenação" defaultLabel="Nenhuma" name="sort" options={this.ordenacoes} value={this.state.sort} onChange={this.handleFilterChange} />
							</PanelContent>
							<TabPanelFooter className="report-filters">
								<div style={{float: "left"}}>
									{this.securityRelatorioProgramado.create ? <Action mode="secondary" disabled={!this.hasRequired()} icon="fa-clock" onClick={this.criarAgendamento} label="Agendar Relatório"/> : null}
								</div>
								<Action disabled={!this.state.relatorio || this.state.lock || !this.hasRequired()} icon={this.state.lock ? "fa-spinner fa-spin" : "fa-print"} className="ui-button-primary ui-tzm-button" onClick={this.postReport} label={this.state.lock ? "Carregando..." : "Imprimir"}/>
							</TabPanelFooter>
						</TabPanel>
						<TabPanel header="Registros de Emissão">
							<PanelContent>
								<div className="ui-g-12">
									<DataTable rows={20} paginator expandedRows={this.state.expanded} onRowToggle={this.expandRows} rowExpansionTemplate={this.expansionTemplate} emptyMessage="Nenhum registro encontrado" value={this.state.registros} children={this.childrenRegistros} />
								</div>
							</PanelContent>
						</TabPanel>
						<TabPanel header="Agendamento de Relatórios">
							<PanelContent>
								<div className="ui-g-12">
									<DataTable rows={20} paginator emptyMessage="Nenhum registro encontrado" value={this.state.agendamentos}>
										<Column style={{width: "5em", textAlign: "right"}} header="ID" field="id"/>
										<Column header="Relatório" field="relatorio" body={rp => labelRelatorio[rp.relatorio]}/>
										<Column style={{textAlign: "center"}} header="Frequência" field="cron" body={rp => cronstrue.toString(rp.cron, {throwExceptionOnParseError: false, verbose: true, locale: "pt_BR", use24HourTimeFormat: true})}/>
										<Column style={{width: "24em"}} header="Destinatários" field="destinatarios" body={rp => Array.isArray(rp.destinatarios) ? rp.destinatarios.join(", ") : rp.destinatarios}/>
										<Column style={{width: "6em"}} header="Ações" field="acoes" body={rp => (
											<div style={{textAlign: "center"}}>
												{this.securityRelatorioProgramado.edit ? <img alt="" className="ui-action-icon" src={iconeEditar} title="Editar Agendamento" onClick={() => this.editarAgendamento(rp)}/> : null}
												{this.securityRelatorioProgramado.remove ? <img alt="" className="ui-action-icon" src={iconeExcluir} title="Excluir Agendamento" onClick={() => this.excluirAgendamento(rp)}/> : null}
											</div>
										)}/>
									</DataTable>
								</div>
							</PanelContent>
						</TabPanel>
					</TabView>
				</Panel>
				<ModalRealm/>
			</div>
		);
	}

	formatos = [
		{label: "PDF", value: "PDF"},
		{label: "CSV", value: "CSV"}
	]

	expandRows = (event) => this.setState({expanded: event.data});

	childrenRegistros = [
		<Column key={-1} style={{width: "2.25em"}} header="&nbsp;" expander />,
		<Column key={0} style={{width: "4em"}} header="Status" body={(registro) => <div style={{textAlign: "center"}}>{this.MapStatus[registro.status]}</div>} />,
		<Column key={0} style={{width: "12em"}} header="Usuário" field="usuario" />,
		<Column key={1} style={{width: "*"}} header="Relatório" body={(registro) => this.MapRelatorios[registro.relatorio]} />,
		<Column key={0} style={{width: "3em"}} header={<i className="fa fa-download"/>} body={(registro) => {
			const fileType = registro.arquivo && registro.arquivo.fileName.endsWith("csv") ? "csv" : "pdf";
			return registro.arquivo ? <div style={{textAlign: "center"}}><i className={`fa fa-file-${fileType} icon-${fileType}`} onClick={() => this.downloadReport(registro)} /></div> : null;
		}} />,
		<Column key={2} style={{width: "10em"}} header="Início" body={(registro) => moment(registro.inicio).format("DD/MM/YY HH:mm:ss")} />,
		<Column key={3} style={{width: "10em"}} header="Fim" body={(registro) => moment(registro.fim).format("DD/MM/YY HH:mm:ss")} />,
		<Column key={4} style={{width: "7em"}} header="Δt" body={(registro) => {
			if (registro.status === "FALHA") return null;
			const interval = moment.duration(moment(registro.fim).diff(moment(registro.inicio))).asSeconds();
			const seconds = Math.round(interval) % 60;
			return <div style={{textAlign: "right"}}>{`${(Math.floor(interval / 60))}:${seconds.toString().length === 1 ? "0": ""}${interval.toFixed(3)}`}</div>;
		}} />,
	];

	downloadReport = (registro) => {
		Fetch.Get(`${basePath}/arquivos/download/${registro.arquivo.fileName}`).then((response) => response.blob().then((blob) => {
			const file = new Blob([blob], {type: `application/${registro.arquivo.fileName.endsWith("csv") ? "csv" : "pdf"}`});
      		const data = window.URL.createObjectURL(file);
      		saveAs(data, registro.arquivo.fileName);
		}));
	}

	MapStatus = {
		"FALHA": <img style={{verticalAlign: "middle"}} src={iconFalha} alt="" />,
		"SUCESSO": <img src={iconSucesso} style={{verticalAlign: "middle"}} alt="" />
	};

	expansionTemplate = (registro) => <ReactJson src={JSON.parse(registro.parametros)} />;

	MapRelatorios = {
		"LogAcessoService.listarRelatorioMovimentacao(..)": "Movimentação por Pessoa",
		"LogAcessoService.listarRelatorioPresenca(..)": "Presença por Lugar",
		"LogAcessoService.listarRelatorioAcesso(..)": "Detalhamento de Acesso",
		"AgendaService.listarPessoasPorUnidade(..)": "Relação de Ativos por Unidade",
		"OcorrenciaService.listar(..)": "Relatório de Ocorrências",
		"ReservaService.listar(..)": "Relatório de Reservas",
		"TarifaSaldoService.listar(..)": "Extrato de Movimentação de Conta de Crédito",
		"EquipamentoService.listarPermissoes(..)": "Relatório de Permissões por Equipamento",
		"CredencialService.listarTemporariasComTitularEmAgendaExpirada(..)": "Relatório de Documentos a Expirar",
		"CaciqueService.listarRefeicoesPeriodo(..)": "Relatório de Refeições por Período",
		"CaciqueService.listarRefeicoesTerceiroEmpresa(..)": "Relatório de Refeições de Terceiros por Empresa",
		"CaciqueService.listarRefeicoesVisitanteCentroCusto(..)": "Relatório de Refeições de Visitantes por Centro de Custo",
		"CaciqueService.listarRefeicoesVisitanteCentroCustoDetalhado(..)": "Relatório de Detalhamento de Refeições de Visitantes por Centro de Custo",
		"VwRefeicaoService.printCsvRefeicoesTerceirosAgrupadoPeriodo(..)": "Relatório de Refeições de Terceiros por Período",
		"VwRefeicaoService.printCsvRefeicoesTerceirosDetalhado(..)": "Relatório de Refeições de Terceiros",
		"CaciqueService.listarRelatorioPlanoEvacuacao(..)": "Relatório de Plano de Evacuação"
	};

}
