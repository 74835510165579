import {Validator} from "../Validator";
import {MEntidade} from "./MEntidade";

export class MUnidade {

	static Modelo() {
		return {
			descricao: "",
			bloco: "",
			expediente: []
		};
	}

	static Flags = [
		{label: "Mudança Liberada", value: "MUDANCA"},
		{label: "Portal do Condômino", value: "CLOUD"}
	];

	static FlagMap = {
		"MUDANCA": "MUDANÇA"
	};

	static cleanupBeforeSave(unidade) {
		if (!Validator.isEntidade(unidade.contato)) unidade.contato = null;
		return MEntidade.cleanupBeforeSave(unidade);
	}

	static validarParaSalvar(unidade) {
		let messages = [];
		if (Validator.isEmpty(unidade.descricao)) messages.push("A descrição é obrigatória");
		return messages;
	}

	static Nomenclaturas = [
		{label: "Alfabético", value: "ALFABETICO"},
		{label: "Numérico", value: "NUMERICO"}
	];

}

export class MVaga {

	static Modelo() {
		return {
			descricao: ""
		};
	}

}
