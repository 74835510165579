import React, {Component} from "react";
import {Dialog} from "primereact/components/dialog/Dialog";
import {DialogContent} from "../commons/DialogContent";
import {DialogFooter} from "../commons/DialogFooter";
import {Action} from "../commons/Action";
import {SelectModeloAcesso} from "../../select/SelectModeloAcesso";
import {TextField} from "../commons/TextField";
import {SelectPessoa} from "../../select/SelectPessoa";
import {ModalRealm} from "../commons/ModalRealm";
import {UnidadePortalCondominoService} from "../servicos/UnidadePortalCondominoService";
import { EtiquetaChip } from "../Etiqueta/EtiquetaChip";

export class WizardAtivacaoCloud extends Component {

	state = {
		loading: false,
		visible: true,
		unidadePortalCondomino: {
			unidade: this.props.unidade,
			padraoVisitante: this.props.unidade.modeloAcesso || this.props.setup.padraoVisitante,
			padraoPermanente: this.props.setup.padraoPermanente,
			nome: this.props.unidade.contato?.nome,
			email: this.props.unidade.contato?.email,
			telefone: this.props.unidade.contato?.telefone,
			empresa: this.props.unidade.contato?.empresa,
			etiquetaPortaria: this.props.setup.etiquetaPortaria
		}
	};

	unidadePortalCondominoService = new UnidadePortalCondominoService();

	handleClose = () => {
		if (this.props.onModalClose) {
			this.props.onModalClose();
		}
		this.setState({visible: false});
	}

	handleSave = () => {
		const messages = [];
		if (!this.state.unidadePortalCondomino.nome?.length)
			messages.push("O nome do responsável é obrigatório");
		if (!this.state.unidadePortalCondomino.email?.length)
			messages.push("O e-mail do responsável é obrigatório");
		if (!this.state.unidadePortalCondomino.empresa?.id)
			messages.push("A empresa da unidade é obrigatória");
		if (!this.state.unidadePortalCondomino.padraoVisitante?.id)
			messages.push("O modelo de acesso padrão de visitantes é obrigatório");
		if (!this.state.unidadePortalCondomino.padraoPermanente?.id)
			messages.push("O modelo de acesso padrão de permanentes é obrigatório");
		if (messages.length) {
			ModalRealm.showInformacao("warn", messages);
			return;
		}
		const mensagem = "Tem certeza de que deseja ativar esta unidade no Portal do Condômino?";
		ModalRealm.showConfirmacao(mensagem, async () => {
			this.setState({loading: true}, async () => {
				await this.unidadePortalCondominoService.ativarUnidade(this.state.unidadePortalCondomino);
				this.handleClose();
			});
		});
	}

	handleChange = e => {
		this.setState({unidadePortalCondomino: {...this.state.unidadePortalCondomino, [e.name]: e.value}});
	}

	render() {		
		return (
			<Dialog
				onHide={this.handleClose}
				visible={this.state.visible}
				style={{width: "750px"}}
				header="Assistente de Ativação de Unidade no Portal do Condômino"
				modal
			>
				<DialogContent>
					<div className="ui-g-12">
						<EtiquetaChip 
							etiqueta={this.state.unidadePortalCondomino.unidade.etiqueta}
						/>
					</div>	

					<TextField
						disabled={this.state.loading}
						label="Nome"
						grid={7}
						name="nome"
						value={this.state.unidadePortalCondomino.nome}
						onChange={this.handleChange}
					/>
					<TextField
						disabled={this.state.loading}
						label="E-mail"
						grid={5}
						name="email"
						value={this.state.unidadePortalCondomino.email}
						onChange={this.handleChange}
					/>
					<SelectModeloAcesso
						disabled={this.state.loading}
						grid={6}
						label="Modelo de Acesso Padrão de Visitantes"
						name="padraoVisitante"
						value={this.state.unidadePortalCondomino.padraoVisitante}
						onChange={this.handleChange}
					/>
					<SelectModeloAcesso
						disabled={this.state.loading}
						grid={6}
						label="Modelo de Acesso Padrão de Permanentes"
						name="padraoPermanente"
						value={this.state.unidadePortalCondomino.padraoPermanente}
						onChange={this.handleChange}
					/>
					<SelectPessoa
						disabled={this.state.loading}
						grid={12}
						name="empresa"
						value={this.state.unidadePortalCondomino.empresa}
						onChange={this.handleChange}
						label="Empresa"
					/>
				</DialogContent>
				<DialogFooter>
					<Action
						disabled={this.state.loading}
						icon="fa fa-save"
						mode="post"
						label="Confirmar"
						onClick={this.handleSave}
					/>
					<Action icon="fa fa-times" mode="secondary" label="Fechar" onClick={this.handleClose}/>
				</DialogFooter>
			</Dialog>
		);
	}

}
